import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TableCellInstance } from '@proman/table/components/table-cell.component';

@Component({
  selector: 'root-table-cell-select',
  template: `
    <root-select [value]="value" [options]="options" [config]="config" (onChange)="handleChange($event)"></root-select>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableCellSelectComponent extends TableCellInstance implements OnInit {
  value: any;
  options: any[];
  @Input() config: any;
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.config.config.options) this.options = this.config.config.options;
    if (this.config && this.config.getValue) {
      this.value = this.config.value;
    }
  }

  handleChange = (change: any) => {
    this.value = change;
    this.callback.emit(change);
  }
}
