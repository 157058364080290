import { EntityInterface } from '@proman/services/entity.service';
import {Employee, Salary} from '@proman/interfaces/entity-interfaces';
import { Money } from '@proman/interfaces/common.interface';

export const employee = {
    name: 'employee',
    params: {
        entityConfig: {
            name: 'employee',
            get: [
                'getIdleProductionEmployees',
                'getTableEmployees',
                'unusedVacationDaysNow',
                'getVacationCompensations',
                'getEmployeePayout',
                'getEmployeePayoutAll',
                'getTrialEnd',
                'getRoleTypes',
                'getSalaryParts',
            ],
            post: [
                'updatePaymentStatus',
                'toggleSpecialisations',
                'discharge',
                'documentRead',
                'calculateMonthlySalary',
                'getVariablePart',
                'removePermissionsOverride',
                'calculateMonthlySalaryForAll',
                'calculateMonthlySalaryTax',
                'regeneratePassword',
                'createSalaryPayment',
                'createSalaryPayout',
                'createEmployeeWorkshifts',
                'recreateEmployeeWorkshifts',
                'createMonthSalary',
                'addCustomers',
                'bruttoFromNetto',
                'nettoFromBrutto',
                'removePermissions',
            ],
            uris: {
                importEmployeesFromFileUri: 'import_employees_from_file',
                importUri: 'import'
            }
        },
        get: {
            select: [
                'addr',
                'agreeMarketing',
                'agreeTos',
                'cardLoginEnabled',
                'dateOfDischarge',
                'email',
                'firstName',
                'id',
                'includeToTable',
                'isCardLoginEnabled',
                'language',
                'languages',
                'lastName',
                'loginEnabled',
                'menuLimitations',
                'menuSettings',
                'name',
                'settings',
                'tableSettings',
                'sessionTtl',
                'twoFactorLoginEnabled',
                'websocketNotifications',
            ],
            join: [
                'specialisations',
                'specialisations.role',
                'photo',
                'cards',
                'files',
                'orderConsumers',
                'role',
                'mainSpecialization',
                'photo',
            ]

        },
        searchFields: [
            'name',
            'specialisations.name',
        ],
    }
};

export interface EmployeeEntityInterface extends EntityInterface<Employee> {
    getTableEmployees: (data: { date: string }) => Promise<Employee[]>;
    calculateMonthlySalary: (data: { employeeId: number; month: string|number; year: string|number; addVacationCompensation: boolean }) => Promise<Employee[]>;
    getVariablePart: (data: { employeeId: number; period: string }) => Promise<any>;
    removePermissionsOverride: (data: { employeeId: number }) => Promise<any>;
    calculateMonthlySalaryForAll: (data: { month: string|number; year: string|number, additives: boolean }) => Promise<Employee[]>;
    calculateMonthlySalaryTax: (data: { month: string|number; year: string|number, taxTypes?: number[] }) => Promise<Employee[]>;
    documentRead: (data: { employeeId: number; employeeDocumentId: number; data?: string }) => Promise<any>;
    createMonthSalary: (data: { employeeId: number; period: string }) => Promise<Salary>;
    bruttoFromNetto: (data: { employeeId: number; sum: number, key: string, period?: string }) => Promise<number>;
    nettoFromBrutto: (data: { employeeId: number; sum: number, key: string, period?: string }) => Promise<number>;
    discharge: (data: { employeeId: number }) => Promise<any>;
    createEmployeeWorkshifts: (data: { employeeId: number }) => Promise<any>;
    recreateEmployeeWorkshifts: (data: { employeeId: number }) => Promise<any>;
    addCustomers: (data: { id: number; customers: number[] }) => Promise<any>;
    getTrialEnd: (data: { employeeId: number }) => Promise<any>;
    getIdleProductionEmployees: (data: {  workplaceId: number; start: string; end: string }) => Promise<Employee[]>;
    unusedVacationDaysNow: (data: { id: number; end: string }) => Promise<number>;
    getVacationCompensations: (data: { id: number; end?: string }) => Promise<number>;
    getEmployeePayout: (data: { id: number; end?: string }) => Promise<number>;
    createSalaryPayout: (data: { id: number; payoutSum?: number; date?: string }) => Promise<any>;
    getEmployeePayoutAll: (data?: { date?: string }) => Promise<any[]>;
    getRoleTypes: () => Promise<string[]>;
    getSalaryParts: () => Promise<any[]>;

    updatePaymentStatus: (
        data: {
            id: number;
            salary: number;
            paid: Money|string;
            paidAt: string;
            bankTransactionId?: number;
            categoryDbitId?: number;
            categoryCrdtId?: number;
            typeCrdt?: string;
            typeDbit?: string;
        }) => Promise<void>;
    createSalaryPayment: (data: { template: number; salary: number[]; send_to_bank: boolean }) => Promise<{ data: number }>;
    removePermissions: (data: any) => Promise<unknown>;
    importEmployeesFromFileUri: 'import_employees_from_file';
}
