import { Component, Input } from '@angular/core';

@Component({
    selector: 'root-update-spinner',
    template: `
        <div class="update">
            <div class="ring-container">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <div *ngIf="!hideUpdate" style="height: 36px;" class="has-text-centered">
<!--                <span class="title has-text-grey-lighter">{{ 'system_update' | translate }}</span>-->
            </div>
        </div>
    `,
    styles: [`
        :host {
            opacity: 0.75;
        }
        .update {
            position: fixed;
            height: 100%;
            height: 100vh;
            width: 100%;
            top: 0;
            left: 0;
            background: #4a4a4a;
            z-index: 99;
        }
        .ring-container {
            width: 128px;
            height: 128px;
            position: relative;
            margin: 0 auto;
            top: 50%;
            margin-top: -50px;
        }
        .lds-ring {
            display: inline-block;
            position: relative;
            width: 128px;
            height: 128px;
        }
        .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 102px;
            height: 102px;
            margin: 12px;
            border: 12px solid #cef;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #cef transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes lds-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }


    `],
})

export class UpdateSpinnerComponent {
    @Input() hideUpdate: boolean = false;

    constructor() {}

}
