import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { findByProperty } from '@proman/utils';

@Component({
    selector: 'root-select',
    template: `
        <div class="fl-column" *ngIf="options" [attr.data-name]="config.label">
            <label class="label" *ngIf="config.label && !config.hideLabel" style="padding: 0 8px 4px 2px; margin: 0!important;"> {{ config.label | translate }}{{ config.required &&  '*' }}</label>
            <div class="select">
                <select [value]="getValue(value)" (change)="handleChange($event.target.value)">
                    <option *ngFor="let option of options" [ngValue]="option" [selected]="isSelected(option)">{{ option.name || option }}</option>
                </select>
            </div>
        </div>
    `,
    styles: [`
        select {
            min-width: 100px;
            width: 100%;
        }
    `]
})

export class SelectComponent implements OnInit, OnChanges {
    @Input() value: any;
    @Input() options: any[] = [];
    @Input() config: any = {};
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    constructor(

    ) {

    }

    ngOnInit() {
        if (this.config.options) {
            this.options = this.config.options;
        }
    }

    ngOnChanges() {
        this.ngOnInit();
    }

    getValue(item: any) {
        if (item && typeof item === 'object') {
            return item[(this.config.displayKey || 'name')];

        } else if (item && this.config.key) {
            const key = this.config.key;

            let value = findByProperty(this.options, key, this.value);

            if (value) item = value[this.config.displayKey];

        }

        return item;
    }

    isSelected(option: any) {
        if (typeof option === 'object') {
            if (option.id) {
                return this.value && (option.id === this.value.id || option.id === this.value);
            }
        }

        return (option === this.value || option === +this.value);
    }

    handleChange(value: any) {
        const displayKey = this.config.displayKey || 'name';
        const key = this.config.key;

        if (typeof value === 'object' || this.config.isObject) {
            if (this.config.displayKey) {
                this.onChange.emit(findByProperty(this.options, displayKey, value)[key]);

            } else if (this.config.key) {
                this.onChange.emit(findByProperty(this.options, displayKey, value)[key]);

            } else if (this.options[0].id) {
                this.onChange.emit(findByProperty(this.options, displayKey, value));

            } else {
                this.onChange.emit(value);
            }
        } else {
            this.onChange.emit(value);
        }

    }

}
