import { Component, Inject } from "@angular/core";
import { Entity } from "@proman/services/entity.service";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { fiscal, FiscalEntityInterface } from "@proman/resources/fiscal";
import { FilterService } from "@proman/services/filter.service";
import { isArray } from "@proman/utils";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastService } from "@proman/services/toast.service";

declare interface PaymentType {
  name: string;
  isCash: boolean;
  type: number;
}

declare interface Vat {
  category: string;
  percent: number;
  discountApply: boolean;
}

@Component({
  selector: 'root-fiscal-edit-dialog',
  template: `
    <pro-dialog-title [title]="fiscal.name || 'new_fiscal_block'" [disableClose]="publicMode"></pro-dialog-title>
    <section mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <mat-tab-group>
          <mat-tab label="Specification">
            <div class="tabContainer">
              <pro-select [value]="registerModel"
                          [config]="{ label: 'cash_register_model', displayKey: 'modelFullName', required: true }"
                          [disabled]="data.fiscal"
                          [options]="registerModels"
                          (onChange)="setRegisterModel($event)"></pro-select>
              <pro-select [value]="selectedHardwareVersion"
                          [options]="hardwareVersions"
                          [disabled]="data.fiscal"
                          [config]="{ label: 'cash_register_hardware_version', displayKey: 'description', required: true }"
                          (onChange)="setVersion('Hardware', $event)"></pro-select>
              <pro-select [value]="selectedSoftwareVersion"
                          [options]="softwareVersions"
                          [disabled]="data.fiscal"
                          [config]="{ label: 'cash_register_software_version', displayKey: 'versionName', required: true }"
                          (onChange)="setVersion('Software', $event)"></pro-select>
              <pro-select [value]="assignedEmployee"
                          [options]="certifiedEmployees"
                          [disabled]="data.fiscal"
                          [config]="{ label: 'certified_employee', displayKey: 'fullName', required: true }"
                          (onChange)="setAssignedEmployee($event)"></pro-select>
              <pro-select [value]="ownerType"
                          [config]="{ label: 'cash_register_owner_type', displayKey: 'displayKey', required: true }"
                          [disabled]="data.fiscal || publicMode"
                          [options]="ownerTypes"
                          (onChange)="set('cashRegisterOwnerType', $event)"></pro-select>
              <pro-select [value]="fiscal.cashRegisterPurpose"
                          [config]="{ label: 'cash_register_purpose', key: 'key', displayKey: 'displayKey', required: true }"
                          [disabled]="data.fiscal"
                          [options]="purposes"
                          (onChange)="set('cashRegisterPurpose', $event)"></pro-select>
              <pro-select [value]="fiscal.cashRegisterPlaceType"
                          [config]="{ label: 'cash_register_place_type', key: 'key', displayKey: 'displayKey', required: true }"
                          [disabled]="data.fiscal"
                          [options]="placeTypes"
                          (onChange)="set('cashRegisterPlaceType', $event)"></pro-select>
              @if (fiscal.cashRegisterOwnerType) {
                <pro-text-simple [value]="ownerCode"
                                 [config]="{ label: 'owner_code', required: true }"
                                 [disabled]="data.fiscal || publicMode"
                                 (onChange)="setOwnerData('Code', $event)"></pro-text-simple>
                @if (fiscal.cashRegisterOwnerType === 'individual') {
                  <pro-text-simple [value]="ownerName"
                                   [config]="{ label: 'owner_name' }"
                                   [disabled]="data.fiscal"
                                   (onChange)="setOwnerData('Name', $event)"></pro-text-simple>
                  <pro-text-simple [value]="ownerSurname"
                                   [config]="{ label: 'owner_surname' }"
                                   [disabled]="data.fiscal"
                                   (onChange)="setOwnerData('Surname', $event)"></pro-text-simple>
                }
                <pro-select [value]="fiscalAddress"
                            [config]="{ label: 'cash_register_address', displayKey: 'address' }"
                            [disabled]="data.fiscal"
                            [options]="addressOptions"
                            (onChange)="setAddress($event)"></pro-select>
              }
            </div>
          </mat-tab>
          <mat-tab label="Information">
            <div class="tabContainer" fxLayout="row">
              <div fxFlex="50">
                <pro-text-simple [value]="fiscal.name"
                                 [config]="{ label: 'name' }"
                                 (onChange)="setUncontrolled('name', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.codeName1"
                                 [config]="{ label: 'code_name_1' }"
                                 (onChange)="setUncontrolled('codeName1', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.code1"
                                 [config]="{ label: 'code_1' }"
                                 (onChange)="setUncontrolled('code1', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.codeName2"
                                 [config]="{ label: 'code_name_2' }"
                                 (onChange)="setUncontrolled('codeName2', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.code2"
                                 [config]="{ label: 'code_2' }"
                                 (onChange)="setUncontrolled('code2', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.address1"
                                 [config]="{ label: 'address_1' }"
                                 (onChange)="setUncontrolled('address1', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.address2"
                                 [config]="{ label: 'address_2' }"
                                 (onChange)="setUncontrolled('address2', $event)"></pro-text-simple>
              </div>
              <div fxFlex="50">
                <pro-text-simple [value]="fiscal.text1"
                                 [config]="{ label: 'text_1' }"
                                 (onChange)="setUncontrolled('text1', $event)"></pro-text-simple>
                <pro-text-simple [value]="fiscal.text2"
                                 [config]="{ label: 'text_2' }"
                                 (onChange)="setUncontrolled('text2', $event)"></pro-text-simple>
                @if (!publicMode) {
                  <pro-checkbox [value]="fiscal.useMqtt"
                                [disabled]="data.fiscal"
                                [config]="{ label: 'use_mqtt' }"
                                (onChange)="setUncontrolled('useMqtt', $event)"></pro-checkbox>
                  @if (fiscal.useMqtt) {
                    <pro-text-simple [value]="fiscal.mqttTopic"
                                     [disabled]="data.fiscal"
                                     [config]="{ label: 'mqtt_topic' }"
                                     (onChange)="setUncontrolled('mqttTopic', $event)"></pro-text-simple>
                  }
                  <pro-text-simple [value]="fiscal.posPrinterIp"
                                   [config]="{ label: 'pos_printer_ip' }"
                                   (onChange)="setUncontrolled('posPrinterIp', $event)"></pro-text-simple>

                  <pro-text-simple [value]="fiscal.posPrinterPort"
                                   [config]="{ label: 'pos_printer_port' }"
                                   (onChange)="setUncontrolled('posPrinterPort', $event)"></pro-text-simple>
                  <pro-text-simple [value]="fiscal.serverIp"
                                   [config]="{ label: 'server_ip' }"
                                   (onChange)="setUncontrolled('serverIp', $event)"></pro-text-simple>
                  <pro-text-simple [value]="fiscal.serverPort"
                                   [config]="{ label: 'server_port' }"
                                   (onChange)="setUncontrolled('serverPort', $event)"></pro-text-simple>
                  <pro-text-simple [value]="fiscal.timezone || 'Europe/Vilnius'"
                                   [config]="{ label: 'time_zone' }"
                                   (onChange)="setUncontrolled('timezone', $event)"></pro-text-simple>
                  <pro-checkbox [value]="fiscal.isRoundingCash"
                                [config]="{ label: 'round_cash_back' }"
                                (onChange)="setUncontrolled('isRoundingCash', $event)"></pro-checkbox>
                }
              </div>
            </div>
          </mat-tab>
          <mat-tab label="VAT">
            <div class="tabContainer">
              <div fxLayout="row">
                <pro-btn icon="plus" theme="accent" (onClick)="addVat()"></pro-btn>
                <h6>{{ 'vats' | translate }}</h6>
              </div>
              @for (vat of vats; track $index) {
                <div fxLayout="row" fxLayoutAlign="center center">
                  <pro-text-simple [value]="vat.category"
                                   [config]="{ label: 'category' }"
                                   [disabled]="true"></pro-text-simple>
                  <pro-text-simple [value]="vat.percent"
                                   [config]="{ label: 'percent', parseNumber: true }"
                                   (onChange)="setTypeVat(vat, 'percent', $event)"></pro-text-simple>
                  <pro-checkbox [value]="vat.discountApply"
                                [config]="{ label: 'discount_apply' }"
                                (onChange)="setTypeVat(vat, 'discountApply', $event)"></pro-checkbox>
                  @if (!data.fiscal) {
                    <pro-btn icon="times" theme="warn"
                             (onClick)="removeTypeVat(vat, 'vat')"></pro-btn>
                  }
                </div>
              }
            </div>
          </mat-tab>
          <mat-tab label="Payment types">
            <div class="tabContainer">
              <div fxLayout="row">
                <pro-btn icon="plus" theme="accent" (onClick)="addPaymentType()"></pro-btn>
                <h6>{{ 'payment_types' | translate }}</h6>
              </div>
              @for (type of paymentTypes; track $index) {
                <div fxLayout="row" fxLayoutAlign="center center">
                  <pro-text-simple [value]="type.name"
                                   [config]="{ label: 'name', required: true }"
                                   (onChange)="setTypeVat(type, 'name', $event)"></pro-text-simple>
                  <pro-checkbox [value]="type.isCash"
                                [config]="{ label: 'is_cash' }"
                                (onChange)="setTypeVat(type, 'isCash', $event)"></pro-checkbox>
                  <pro-select [value]="type.type"
                              [options]="typeOptions"
                              [config]="{ label: 'type', key: 'key', displayKey: 'name' }"
                              (onChange)="setTypeVat(type, 'type', $event)"></pro-select>
                  @if (!data.fiscal) {
                    <pro-btn icon="times" theme="warn"
                             (onClick)="removeTypeVat(type, 'type')"></pro-btn>
                  }
                </div>
              }
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </section>
    <pro-dialog-actions (callback)="confirm()"
                        [isCallbackDisabled]="(!data.fiscal && !form.valid) || (!vatsValid() || !typesValid())"
                        [disableClose]="publicMode"
                        [hideClose]="publicMode"
                        [variant]="data.fiscal ? 'update' : 'create'"></pro-dialog-actions>
  `,
  styles: [`
      section div.tabContainer {
          width: 800px;
          height: 800px;
          padding: 6px;
      }
  `]
})

export class FiscalDialogComponent {
  fiscal: any; //Partial<Fiscal>
  fiscalEntity: FiscalEntityInterface;
  ownerTypes: { key: string, displayKey: string }[];
  ownerType: any;
  ownerCode: string;
  ownerName: string;
  ownerSurname: string;
  registerModels: any[];
  registerModel: any;
  fiscalAddress: any;
  purposes: any[];
  placeTypes: any[];
  assignedEmployee: any;
  selectedHardwareVersion: any;
  selectedSoftwareVersion: any;
  certifiedEmployees: any[];
  softwareVersions: any[];
  hardwareVersions: any[];
  addressOptions: any[];
  form: UntypedFormGroup;
  controls: any;
  paymentTypes: PaymentType[] = [];
  vats: Vat[] = [];
  typeOptions: { key: number, name: string }[];
  publicMode: boolean;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private dialogRef: MatLegacyDialogRef<FiscalDialogComponent>,
    private Filter: FilterService,
    private Entity: Entity,
    private Toast: ToastService,
  ) {
    this.typeOptions = fiscal.params.entityConfig.paymentTypes.map((type, index) => {
      return { key: index + 1, name: this.Filter.translate(type) };
    })
    this.fiscalEntity = this.Entity.get('fiscal');
    this.fiscal = data.fiscal || { timezone: 'Europe/Vilnius' };
    console.log(this.data.cashRegisterModels);

    this.controls = {
      cashRegisterModelNo: new UntypedFormControl(this.fiscal.cashRegisterModelNo, Validators.required),
      cashRegisterHardwareVersionId: new UntypedFormControl(this.fiscal.cashRegisterHardwareVersionID, Validators.required),
      cashRegisterSoftwareVersionId: new UntypedFormControl(this.fiscal.cashRegisterSoftwareVersionID, Validators.required),
      certifiedEmployeeId: new UntypedFormControl(this.fiscal.certifiedEmployeeID, Validators.required),
      cashRegisterOwnerType: new UntypedFormControl(this.fiscal.cashRegisterOwnerType, Validators.required),
      cashRegisterOwnerCode: new UntypedFormControl(this.fiscal.cashRegisterOwnerCode, Validators.required),
      cashRegisterOwnerName: new UntypedFormControl(this.fiscal.cashRegisterOwnerName, Validators.required),
      cashRegisterPurpose: new UntypedFormControl(this.fiscal.cashRegisterPurpose, Validators.required),
      cashRegisterPlaceType: new UntypedFormControl(this.fiscal.cashRegisterPlaceType, Validators.required),
      cashRegisterAddressId: new UntypedFormControl(this.fiscal.cashRegisterAddressId, Validators.required),
    };
    this.form = new UntypedFormGroup(this.controls);
    this.registerModels = this.data.cashRegisterModels;
    this.certifiedEmployees = this.data.certifiedEmployees;
    this.ownerTypes = fiscal.params.entityConfig.ownerTypes.map((value) => {
      return { key: value, displayKey: this.Filter.translate(value) };
    });
    this.purposes = fiscal.params.entityConfig.purposes.map((value) => {
      return { key: value, displayKey: this.Filter.translate(value) };
    });
    this.placeTypes = fiscal.params.entityConfig.placeTypes.map((value) => {
      return { key: value, displayKey: this.Filter.translate(value) };
    });

    this.publicMode = this.data.publicMode;
    if (this.publicMode) {
      console.log('dialogData: ', this.data);
      this.set('cashRegisterOwnerType', this.ownerTypes[1]);
      this.setOwnerData('Code', this.data.ja_kodas);
    }

    if (data.fiscal) {
      this.registerModel = this.registerModels.find((model) => model.cashRegisterModel.modelNo === +this.fiscal.cashRegisterModelNo);
      this.vats = data.fiscal.vats;
      this.paymentTypes = data.fiscal.paymentTypes;

      if (this.registerModel) {
        this.hardwareVersions = this.registerModel.cashRegisterModel.cashRegisterHardwareVersions.version;
        this.softwareVersions = [this.registerModel.cashRegisterModel.cashRegisterSoftwareVersions.version];
        this.hardwareVersions = [].concat(this.registerModel.cashRegisterModel.cashRegisterHardwareVersions.version || []);
        this.selectedHardwareVersion = this.hardwareVersions.find((hVersion) => hVersion.id === +this.fiscal.cashRegisterHardwareVersionID);
          this.softwareVersions = Array.isArray(this.registerModel.cashRegisterModel.cashRegisterSoftwareVersions.version)
              ? this.registerModel.cashRegisterModel.cashRegisterSoftwareVersions.version
              : [this.registerModel.cashRegisterModel.cashRegisterSoftwareVersions.version];

          this.selectedSoftwareVersion = this.softwareVersions.find((sVersion) => sVersion.id === +this.fiscal.cashRegisterSoftwareVersionID);
      }

      this.assignedEmployee = this.certifiedEmployees.find((employee) => employee.id === +this.fiscal.certifiedEmployeeID);
      this.ownerType = this.ownerTypes.find((type) => type.key === this.fiscal.cashRegisterOwnerType)
      this.ownerCode = this.fiscal.cashRegisterOwnerCode;
      this.fiscal.codeName1 = this.data.fiscal.code_name1;
      this.fiscal.codeName2 = this.data.fiscal.code_name2;
      if (this.fiscal.cashRegisterOwnerName) [this.ownerName, this.ownerSurname] = this.fiscal.cashRegisterOwnerName.split(' ');
    } else {
      this.fiscal.codeName1 = 'Įmonės kodas';
      this.fiscal.codeName2 = 'PVM kodas';
      this.fiscal.serverIp = '88.119.221.237';
      this.fiscal.serverPort = '22';

      this.vats = [
        {
          category: 'A',
          discountApply: true,
          percent: 21,
        },
        {
          category: 'B',
          discountApply: true,
          percent: 21,
        },
        {
          category: 'C',
          discountApply: true,
          percent: 0,
        }
      ];

      this.paymentTypes = [
        {
          name: 'Grynais',
          isCash: true,
          type: 1,
        },
        {
          name: 'Kortele',
          isCash: false,
          type: 2,
        },
        {
          name: 'Kreditas',
          isCash: false,
          type: 9,
        }
      ];
    }
    console.log(this.form);
  }

  confirm() {
    delete this.fiscal.code_name1;
    delete this.fiscal.code_name2;
    console.log(this.fiscal);
    if (this.data.fiscal) {
      delete this.fiscal.cashRegisterSeriesNo;
      delete this.fiscal.cashRegisterHardwareVersionID;
      delete this.fiscal.cashRegisterSoftwareVersionID;
      delete this.fiscal.cashRegisterModelNo;
      delete this.fiscal.cashRegisterPlaceType;
      delete this.fiscal.cashRegisterPurpose;
      delete this.fiscal.cashRegisterRegistrationID;
      delete this.fiscal.cashRegisterRegistrationNo;
      delete this.fiscal.cashRegisterSeriesNo;
      delete this.fiscal.cashRegisterAddressId;
      delete this.fiscal.cashRegisterOwnerType;
      delete this.fiscal.cashRegisterOwnerName;
      delete this.fiscal.cashRegisterOwnerCode;
      delete this.fiscal.certificateId;
      delete this.fiscal.certifiedEmployeeID;
      delete this.fiscal.createdAt;
      delete this.fiscal.updatedAt;
      delete this.fiscal.technicalPassportNo;
      delete this.fiscal.technicalPassportSerialNo;
      delete this.fiscal.vats;
      delete this.fiscal.paymentTypes;
      console.log(this.fiscal);
      console.log(this.vats);
      console.log(this.paymentTypes);
      Promise.all([
        this.fiscalEntity.updateSystemOption(this.fiscal),
        this.fiscalEntity.updatePaymentType({ id: this.fiscal.id, paymentTypes: this.paymentTypes }),
        this.fiscalEntity.updateVat({ id: this.fiscal.id, vats: this.vats })
      ]).then(() => this.dialogRef.close(1));
    } else {
      if (this.publicMode) {
        //TODO fiscal draft creation logic
      } else {
        this.fiscalEntity.create({ paymentTypes: this.paymentTypes, vats: this.vats, ...this.fiscal }).then(() => this.dialogRef.close(1))
      }
    }
  }

  setAssignedEmployee = (value: any) => {
    this.assignedEmployee = value;
    this.fiscal.certifiedEmployeeId = value.id;
    this.form.controls.certifiedEmployeeId.setValue(value.id);
  };

  setRegisterModel = (value: any) => {
    this.registerModel = value;
    this.fiscal.cashRegisterModelNo = value.cashRegisterModel.modelNo;
    this.form.controls.cashRegisterModelNo.setValue(value.cashRegisterModel.modelNo);
    this.hardwareVersions = [value.cashRegisterModel.cashRegisterHardwareVersions.version];
    if (value.cashRegisterModel && Array.isArray(value.cashRegisterModel.cashRegisterSoftwareVersions.version)) {
      this.softwareVersions = value.cashRegisterModel.cashRegisterSoftwareVersions.version;
    } else if (value.cashRegisterModel && value.cashRegisterModel.cashRegisterSoftwareVersions) {
      this.softwareVersions = [value.cashRegisterModel.cashRegisterSoftwareVersions.version];
    }
  };
  setVersion = (type: string, value: any) => {
    this[`${type}Version`] = value;
    this.fiscal[`cashRegister${type}VersionId`] = value.id;
    this.form.controls[`cashRegister${type}VersionId`].setValue(value.id);
  };

  setOwnerData = (property: string, value: string) => {
    this[`owner${property}`] = value;
    let params;

    if (property === 'Code') {
      this.fiscal.cashRegisterOwnerCode = value;
      this.form.controls.cashRegisterOwnerCode.setValue(value);
    } else {
      const fullName = `${this.ownerName} ${this.ownerSurname}`;
      this.fiscal.cashRegisterOwnerName = fullName;
      this.form.controls.cashRegisterOwnerName.setValue(fullName);
    }

    if (this.fiscal.cashRegisterOwnerType === 'individual') {
      if (this.ownerCode && this.ownerName && this.ownerSurname) {
        params = { personCode: this.ownerCode, name: this.ownerName, surname: this.ownerSurname };
        this.getBusinessAddress(params);
      }
    } else {
      if (this.ownerCode) {
        params = { personCode: this.ownerCode };
        this.getBusinessAddress(params);
      }
    }
  }

  getBusinessAddress(values: any) {
    let fiscalInterface;
    if (this.publicMode) {
      fiscalInterface = this.Entity.get({ name: 'public/fiscal', get: ['getBusinessAddress' ]});
    } else {
      fiscalInterface = this.fiscalEntity;
    }
    fiscalInterface.getBusinessAddress(values).then((response) => {
      if (response.status === 'ERROR') {
        const description = response.error.descriptions.description.find((descr) => descr.locale === 'LT');
        this.Toast.pop('error', description.errorMessage);
      } else {
        if (isArray(response.addresses.address)) {
          this.addressOptions = response.addresses.address;
        } else {
          this.addressOptions = null;
          this.setAddress(response.addresses.address);
        }
        this.Toast.pop('success', 'Duomenys rasti.');
      }
    })
  }

  setAddress = (value: any) => {
    this.fiscalAddress = value;
    this.fiscal.cashRegisterAddressId = value.addressId;
    this.form.controls.cashRegisterAddressId.setValue(value.addressId);
  }

  set = (property: string, value: any) => {
    if (property === 'cashRegisterOwnerType') {
      if (value.key === 'individual') {
        if (!this.form.contains('cashRegisterOwnerName')) {
          this.form.addControl('cashRegisterOwnerName', new UntypedFormControl(this.fiscal.cashRegisterOwnerName, Validators.required));
        }
      } else {
        if (this.form.contains('cashRegisterOwnerName')) {
          this.form.removeControl('cashRegisterOwnerName');
        }
      }
      this.fiscal[property] = value.key;
      this.form.controls[property].setValue(value.key);
      this.ownerType = value;
    } else {
      this.fiscal[property] = value;
      this.form.controls[property].setValue(value);
    }
  };

  setUncontrolled = (property: string, value: any) => {
    this.fiscal[property] = value;
  }

  isArray = (value: unknown) => isArray(value);

  addPaymentType() {
    this.paymentTypes.push({ name: '', isCash: false, type: 1 });
  }

  addVat() {
    if (this.vats.length <= 15) this.vats.push({ category: String.fromCharCode(this.vats.length + 65), discountApply: false, percent: 0 });
  }

  setTypeVat = (object: any, property: string, value: any) => object[property] = value;

  removeTypeVat = (object: any, type: string) => {
    if (type === 'type') {
      const index = this.paymentTypes.indexOf(object);
      this.paymentTypes.splice(index, 1);
    } else {
      const index = this.vats.indexOf(object);
      this.vats.splice(index, 1);
    }
  }

  typesValid = () => this.paymentTypes.length > 0 && this.paymentTypes.every((type) =>  type.type > 0);

  vatsValid = () => this.vats.length > 0;
}
