import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TextComponent } from './inputs/text.component';
import { ButtonComponent } from './inputs/button.component';
import { SelectComponent } from './inputs/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RegistractionComponent } from './pages/registraction.component';
import { ServersComponent } from './pages/servers.component';
import { HeaderComponent } from './components/header.component';
import { ModalComponent } from './components/modal.component';
import { HttpResponseInterceptor } from '@proman/interceptors/http-response-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpdateSpinnerComponent } from './components/update-spinner.component';
import { LanguagesComponent } from './pages/languages.component';
import { CheckboxComponent } from './inputs/checkbox.component';
import { PaginationComponent } from './inputs/pagination.component';
import { PersonsComponent } from './pages/persons.component';
import { DefaultRouteComponent } from './pages/default-route.component';
import { ServerStatsChartComponent } from './components/server-stats-chart.component';
import { NotificationsComponent } from './pages/notifications.component';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ContainersComponent } from './pages/containers.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { UserResolver } from './app-resolvers';
import { FiscalsComponent } from './pages/fiscals.component';
import { FiscalsStatsComponent } from './pages/fiscals-stats.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@proman/shared/language/custom-missing-traslation-handler';
import { InputsModule } from './inputs/inputs.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { MasterPromanTableModule } from './master-table-module/master-proman-table.module';
import { PromanModule } from '@proman/proman.module';
import { PromanStoreModule } from '@proman/store/proman-store.module';
import { HttpRequestInterceptor } from '@proman/interceptors/http-request-interceptor';
import { PublicContainerCreateComponent } from './components/public/public-container-create.component';
import { PublicContainerCreateProchefComponent } from './components/public/public-container-create-prochef.component';
import { PublicContainerCreatePromanComponent } from './components/public/public-container-create-proman.component';
import { PublicContainerCreateSmartonComponent } from './components/public/public-container-create-smarton.component';
import { PublicContainerCreateSmartonProductionComponent } from './components/public/public-container-create-smarton-production.component';
import { PublicContainerCreateSmartonTrProductionComponent } from './components/public/public-container-create-smarton-tr-production.component';
import { PublicContainerCreateSmartonTrComponent } from './components/public/public-container-create-smarton-tr.component';
import { PublicContainerCreateSmartonUaComponent } from './components/public/public-container-create-smarton-ua.component';
import { PublicContainerCreateSmartonUaProdComponent } from './components/public/public-container-create-smarton-ua-prod.component';
import { TableCellSelectComponent } from '@master/components/root-table-cell-select.component';
import { GitBuildsComponent } from "@master/pages/builds-component";
import { MasterTagsComponent } from "@master/pages/tags.component";
import { SentryModule } from "@proman/sentry";
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { PromanTalkRoomModule } from '@proman/talk-room/talk-room.module';
import { MasterTalkRoomComponent } from '@master/pages/master-talk-room.component';
import { AppInitService } from '@proman/services/app-init.service';
import { of as observableOf } from 'rxjs';
import { PublicContainerCreateSmartonLtComponent } from '@master/components/public/public-container-create-smarton-lt.component';
import { PublicContainerCreateSmartonLtProductionComponent } from '@master/components/public/public-container-create-smarton-lt-production.component';
import { PublicFiscalFormComponent } from '@master/components/public/public-fiscal-form.component';

export class CustomTranslateLoader implements TranslateLoader {

    constructor(private AppInit: AppInitService) {
    }

    getTranslation(lang: string): any {
        return observableOf(this.AppInit.translations[this.AppInit.currentLang]);
    }

}

export function getTranslations(InitService: AppInitService ) {
    return function() {
        return InitService.initialize();
    };
}

const COMPONENTS = [
    AppComponent,

    HeaderComponent,
    ModalComponent,
    UpdateSpinnerComponent,
    ServerStatsChartComponent,
    NotificationsComponent,

    LoginComponent,
    RegistractionComponent,
    DefaultRouteComponent,
    ServersComponent,
    ContainersComponent,
    LanguagesComponent,
    PersonsComponent,
    PublicContainerCreateComponent,
    PublicContainerCreateProchefComponent,
    PublicContainerCreatePromanComponent,
    PublicContainerCreateSmartonComponent,
    PublicContainerCreateSmartonProductionComponent,
    PublicContainerCreateSmartonTrProductionComponent,
    PublicContainerCreateSmartonTrComponent,
    PublicContainerCreateSmartonUaComponent,
    PublicContainerCreateSmartonUaProdComponent,
    PublicContainerCreateSmartonLtComponent,
    PublicContainerCreateSmartonLtProductionComponent,
    PublicFiscalFormComponent,

    FiscalsComponent,
    FiscalsStatsComponent,
    TableCellSelectComponent,
    GitBuildsComponent,
    MasterTagsComponent,
    MasterTalkRoomComponent,
]

@NgModule({
    imports: [
        SentryModule.forRoot({ project: 'master'}),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
            useDefaultLang: false
        }),
        ToastrModule.forRoot({positionClass: 'toast-bottom-right', preventDuplicates: true}),
        MatLegacyMenuModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
        PromanModule,
        PromanStoreModule,
        MasterPromanTableModule,
        PipesModule,
        DialogsModule,
        InputsModule,
        PromanTextSimpleComponent,
        PromanButtonComponent,
        PromanTalkRoomModule,
    ],
    declarations: [
        ...COMPONENTS
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: getTranslations, deps: [AppInitService], multi: true },
        { provide: TranslateLoader, useClass: CustomTranslateLoader, deps: [AppInitService] },
        UserResolver,
    ],
    exports: [
        ButtonComponent,
        SelectComponent,
        PaginationComponent,
        CheckboxComponent,
        TextComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
