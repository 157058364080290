import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setUiPrefs } from '@proman/store/curr-user/curr-user.actions';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { distinctUntilChanged } from '@proman/rxjs-common';

const $localStorage = window.localStorage;
const PREFIX = 'uiPrefs';

export const UI_PROJECTS_IMAGE_KEY = 'hide_photo_in_projects';
export const UI_EXTENDED_INFORMATION = 'article_advanced';
export const UI_TABLE_PAGINATION_TOP = 'show_pagination_above_table';
export const UI_CLOSE_BUTTON = 'show_close_button';
export const UI_CLIPBOARD_PASTE_TARGET = 'paste_to_photo';
export const UI_HIDE_WARNINGS = 'hide_warnings';
export const UI_SHOW_PRODUCT_FILES = 'show_product_files';
export const UI_BOARD_CELL_WIDTH = 'board_cell_width';
export const UI_BOARD_COMPACT = 'board_compact';
export const UI_BOARD_WEEK = 'board_week';
export const UI_BOARD_ID = 'board_id';
export const UI_BOARD2_DAY = 'board2_day';
export const UI_BOARD2_VISIBILITY = 'board2_visibility';
export const UI_TABLE_SAVED_FILTERS = 'tableFilters';
export const UI_RESET_PROPOSAL = 'reset_proposal_on_create';
export const UI_PRINT_CONTAINER_STICKER = 'print_container_sticker_on_create';
export const UI_CLOSE_AFTER_BARCODE_PRINT = 'close_after_barcode_print';
export const UI_CALENDAR_AUTO_UPDATE = 'calendar_auto_update';
export const UI_CALENDAR_AUTO_PLANNING = 'calendar_auto_planning';
export const UI_CALENDAR_REAL_TIME = 'calendar_real_time';
export const UI_SCALES_WIZARD_HISTORY = 'scales_wizard_history';
export const UI_SCALES_WIZARD_INPUT = 'scales_wizard_manual_input';
export const UI_JOBS_HIDE_COMPLETED = 'jobs_hide_completed';
export const UI_JOBS_WORKPLACES = 'jobsWorkplaces';
export const UI_JOBS_EMPLOYEE = 'jobsEmployee';
export const UI_COMPACT_MENU = 'compact_menu';
export const UI_PRODUCT_LOGS_TYPE = 'product_logs_type';
export const UI_PRODUCT_LOGS_GROUP = 'product_logs_group';
export const UI_ORDERS_CHART_TYPE = 'orders_chart_type';
export const UI_JOBS_AUTOUPDATE = 'jobs_autoupdate';
export const UI_DARK_MODE = 'darkmode';
export const UI_REPORT_ARTICLES_DATE_FROM = 'report_articles_date_from';
export const UI_REPORT_ARTICLES_DATE_TO = 'report_articles_date_to';
export const UI_REPORT_PRODUCTION_TYPES_DATE_FROM = 'report_production_types_date_from';
export const UI_REPORT_PRODUCTION_TYPES_DATE_TO = 'report_production_types_articles_date_to';
export const UI_CONSUMER_MAP_STEP = 'consumer_map_step';
export const UI_ARTICLE_TEST_TEMPLATE_SAVING = 'article_test_template_saving';
export const UI_CUSTOMER_CLAIM_TEMPLATE_SAVING = 'customer_claim_template_saving';
export const UI_SIDENAV_LOCK = 'sidenav_lock';
export const UI_PRODUCTION_LIST_LAST_TEMPLATE = 'production_list_last_template';
export const UI_PREVIEW_NEW_WINDOW = 'file_preview_new_window';
export const UI_PROPOSAL_PRODUCT_DIALOG = 'proposal_product_dialog';

export const UI_FIXED_PRICE = 'fixed_price';
export const UI_CACHED_TABEL_DATE = 'tabel_date';
export const UI_CACHED_ENTRANCE_DATE = 'entrance_date';
export const UI_INVENTORIZATION_LAST_TEMPLATE = 'inventorization_last_template';


@Injectable({ providedIn: 'root'})
export class UiPreferencesService {
    currUser: CurrUser;

    constructor(
        @Inject(Store) private store: Store
    ) {
        this.store.select(getCurrUser)
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.currUser = value;

                this.get(UI_COMPACT_MENU);
                this.get(UI_DARK_MODE);
                this.get(UI_EXTENDED_INFORMATION);
                this.get(UI_SIDENAV_LOCK);
            });
    }

    getPrefixedProperty = (property: string) => {
        const currUser = this.currUser;
        const username = currUser?.username || '_username'; // fail safe

        return `${PREFIX}.${username}.${property}`
    };

    get = (property: string) => {
        const formattedProperty = this.getPrefixedProperty(property);
        let value;

        try {
            value = $localStorage.getItem(formattedProperty);

        } catch (e) {
            value = null;

        }

        try {
            value = JSON.parse(value);

        } catch (e) {
            value = value;
        }

        this.store.dispatch(setUiPrefs({ payload: { [property]: value } }));

        return value;

    };

    set = (property: string, value: any): void => {
        const formattedProperty = this.getPrefixedProperty(property);

        try {
            $localStorage.setItem(formattedProperty, typeof value === 'string' ? value : JSON.stringify(value));
            this.store.dispatch(setUiPrefs({ payload: { [property]: value } }));

        } catch (e) {

        }

    };

}
