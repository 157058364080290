import { Component, Inject, OnInit } from '@angular/core';
import { findByProperty, prepareRequest } from '@proman/utils';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'root-action-create-dialog',
    template: `
        <pro-dialog-title [title]="'New listener - ' + params.entity"></pro-dialog-title>
        <section mat-dialog-content class="columns is-marginless fl-column">
            <root-text [value]="action.name"
                       [config]="{ label: 'name', required: true, validators: { unique: { resource: params.entity } } }"
                       (onChange)="set('name', $event)"></root-text>
            <root-select [value]="action.action"
                         [config]="{ label: 'action', disableSearch: true, required: true, key: 'id', displayKey: 'name' }"
                         [options]="actions"
                         (onChange)="set('action', $event)"></root-select>
            <root-checkbox (onChange)="set('enabled', $event)"
                           [config]="{ label: 'enabled' }"
                           [value]="action.enabled"></root-checkbox>

            <ng-container *ngIf="params.entity === 'table_listener'">
                <root-select [value]="table"
                             [config]="{ label: 'table', required: true, key: 'table_name' }"
                             [options]="tables"
                             (onChange)="set('table', $event)"></root-select>

                <root-checkbox *ngIf="isSubscribeHandle"
                               [value]="action.useSubscriberHandler"
                               [config]="{ label: 'use_subscriber_handler' }"
                               (onChange)="set('useSubscriberHandler', $event)"></root-checkbox>

                <root-text *ngIf="action.table"
                           [value]="action.conditionExpression"
                           [config]="{ label: 'condition_expression' }"
                           (onChange)="set('conditionExpression', $event)"></root-text>

                <root-checkbox (onChange)="set('public', $event)"
                               [config]="{ label: 'public' }"
                               [value]="action.public"></root-checkbox>

                <root-checkbox [value]="action.isOnCreate"
                               [config]="{ label: 'on_create' }"
                               (onChange)="set('isOnCreate', $event)"></root-checkbox>
                <root-checkbox [value]="action.isOnUpdate"
                               [config]="{ label: 'on_update' }"
                               (onChange)="set('isOnUpdate', $event)"></root-checkbox>
                <root-checkbox [value]="action.isOnDelete"
                               [config]="{ label: 'on_delete' }"
                               (onChange)="set('isOnDelete', $event)"></root-checkbox>
            </ng-container>

            <ng-container *ngIf="params.entity === 'email_listener'">
                <root-text [value]="action.filterKey"
                           [config]="{ label: 'filter_key' }"
                           (onChange)="set('filterKey', $event)"></root-text>
            </ng-container>

        </section>
        <pro-dialog-actions (callback)="confirm()"
                             [isCallbackDisabled]="!(action.name && action.action)"
                             [variant]="'confirm'"></pro-dialog-actions>
    `
})

export class ActionListenerCreateDialogComponent implements OnInit {
    action: any = { enabled: true, useSubscriberHandler: false };
    actions: any[];
    itemEntity: any;

    isSubscribeHandle: boolean;
    actionEntity: any;
    table: any;
    tables: any;
    expressionEdit: boolean;
    params: { entity: EntityNameType, refreshCallback: (data: any) => void };

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<ActionListenerCreateDialogComponent>,
        private Entity: Entity,

    ) {
        this.params = this.data;

        this.itemEntity = this.Entity.get({ name: this.params.entity });
        this.actionEntity = this.Entity.get({ name: 'action', get: ['tables'] });

        this.actionEntity
            .search()
            .then((response: any) => {
                this.actions = response.map((action: any) => {
                    const splits = action.class.split('\\');

                    action.name = splits[splits.length - 1];

                    return action;
                });
            });

    }

    async ngOnInit() {
        this.tables = await this.actionEntity.tables()
            .then((response: any) => {
                response.forEach((item: any) => {
                    item.name = item.show_name;
                });

                return response;
            });
    }

    set(property: string, value: any) {
        if (property === 'table') {
            value = findByProperty(this.tables, 'table_name', value);
            this.table = value;
            this.action.table = value.table_name;
            this.isSubscribeHandle = value.has_subscriber_handler;
            this.action.useSubscriberHandler = value.has_subscriber_handler;

        } else {
            this.action[property] = value;

        }

    }

    confirm() {
        this.itemEntity
            .create(prepareRequest(this.action))
            .then((response: any) => {
                this.close();
                this.params.refreshCallback(response);
            });
    }

    close() {
        this.dialogRef.close();
    }

}
