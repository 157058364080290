import { Injectable } from '@angular/core';
import { FilePreviewDialogComponent } from '../shared-dialogs/dialogs/file-preview-dialog.component';
import { ImagePathService } from './image-path.service';
import { PromanFile } from '../interfaces/entity-interfaces';
import { getScreenWidthPercent, isMobile } from '../utils';
import { CONFIG } from '../config';
import { VideoPreviewDialogComponent } from '../shared-dialogs/dialogs/video-preview-dialog.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { UI_PREVIEW_NEW_WINDOW, UiPreferencesService } from "./ui-preferences.service";

const VIDEO_EXTENSIONS = ['mov', 'mp4'];

@Injectable({ providedIn: 'root' })
export class FilePreviewService {

    constructor(
        private MatLegacyDialog: MatLegacyDialog,
        private ImagePath: ImagePathService,
        private UiPrefs: UiPreferencesService,
    ) {

    }

    getExt(file: PromanFile) {
        if (file && file.extension) {
            return file.extension
        } else if (file && file.filename) {
            const lastPoint: number = file.filename.lastIndexOf('.');

            if (lastPoint !== -1) return file.filename.substr(lastPoint + 1).toLowerCase();

        }

        return null;
    }

    getAction(file: PromanFile) {

        switch (this.getExt(file)) {
            case 'gif':
            case 'png':
            case 'jpeg':
            case 'jpg':
                return 'lightbox';

            case 'pdf':
            default:
                return 'blank';
        }
    }

    show = (file: PromanFile) => {
        if (!file || !file.newId) return;
        const newWindow = this.UiPrefs.get(UI_PREVIEW_NEW_WINDOW);

        if (VIDEO_EXTENSIONS.includes(file.extension)) {
            return this.MatLegacyDialog.open(VideoPreviewDialogComponent, {
              data: { src: this.ImagePath.getFile(file, 'main') }
            });
        }

      if (newWindow) {
        window.open(this.ImagePath.getFile(file, 'pdf'), '_blank');
        return;
      } else {
        return this.MatLegacyDialog.open(FilePreviewDialogComponent, {
          data: { iframe: true, url: this.ImagePath.getFile(file, 'pdf') },
          ...{ disableAutoFocus: true,
              width: `${getScreenWidthPercent(isMobile() ? 96 : 80)}px`
          }
        });
      }
    };

    getIconSrc = (file: PromanFile) => {
        let output;

        switch (this.getExt(file)) {
            case 'gif':
            case 'png':
            case 'jpeg':
            case 'jpg':
                output = this.ImagePath.getFile(file, 'png');
                break;

            case 'pdf':
                if (file.altFilename) {
                    output = this.ImagePath.getFile(file, 'pdf');
                } else {
                    output = 'css/images/pdf.png';
                }
                break;

            default:
                output = 'css/images/file.png';
        }

        return output;
    };

    download(file: PromanFile) {
        window.open(`${CONFIG.api}file/download?id=${file.id || file.newId}`);
    }

    downloadPdf(file: PromanFile) {
        window.open(`${CONFIG.api}file/download_pdf?id=${file.id || file.newId}`);
    }

}
