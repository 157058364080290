import { Component, OnInit } from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { ButtonListener, PeriodicListener, TableListener } from '../interfaces';
import { MasterModalService } from '../services/master-modal.service';
import {ActionConfirmDialogComponent} from '../dialogs/action-confirm-dialog.component';
import {Dialog} from '../services/dialog.service';
import {ActionListenerCreateDialogComponent} from '../dialogs/action-listener-create-dialog.component';

@Component({
    selector: 'root-notifications',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container fl fl-column Width100" >


                <div class="fl-row">
                    <root-button [label]="'Create'"
                                 (onClick)="createListener()"></root-button>

                    <div style="width: 32px">&nbsp;</div>

                    <root-tabs [tabs]="['Object', 'Button', 'Periodic']" [(activeTab)]="activeTab"></root-tabs>

                </div>

                <ng-container *ngIf="activeTab == 0">
                    <table class="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Table</th>
                            <th>Action</th>
                            <th>On Create</th>
                            <th>On Update</th>
                            <th>On Delete</th>
                            <th>Enabled</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let listener of tableListeners"
                            class="Clickable">

                            <td>{{ listener.name }}</td>
                            <td>{{ listener.table }}</td>
                            <td>{{ getActionValue(listener) }}</td>
                            <td>{{ listener.isOnCreate }}</td>
                            <td>{{ listener.isOnUpdate }}</td>
                            <td>{{ listener.isOnDelete }}</td>
                            <td>
                                <root-checkbox [value]="listener.enabled"
                                               [config]="{ label: '' }"
                                               (onChange)="setEnabled(listener, $event)"></root-checkbox>
                            </td>
                            <td class="has-text-right">
                                <root-button icon="edit" (onClick)="editListener(listener)" [notFullwidth]="true"></root-button>
                                <root-button icon="trash" (onClick)="deleteListener(listener)" [notFullwidth]="true"></root-button>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </ng-container>

                <ng-container *ngIf="activeTab == 1">
                    <table class="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Action</th>
                            <th>Enabled</th>

                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let listener of buttonListeners"
                            class="Clickable">

                            <td>{{ listener.name }}</td>
                            <td>{{ getActionValue(listener) }}</td>
                            <td>
                                <root-checkbox [value]="listener.enabled"
                                               [config]="{ label: '' }"
                                               (onChange)="setEnabled(listener, $event)"></root-checkbox>
                            </td>

                            <td class="has-text-right">
                                <root-button icon="edit" (onClick)="editListener(listener)" [notFullwidth]="true"></root-button>
                                <root-button icon="trash" (onClick)="deleteListener(listener)" [notFullwidth]="true"></root-button>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </ng-container>

                <ng-container *ngIf="activeTab == 2">
                    <table class="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Action</th>
                            <th>Minutes</th>
                            <th>Hours</th>
                            <th>Days</th>
                            <th>Days of week</th>
                            <th>Months</th>
                            <th>Enabled</th>

                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let listener of periodicListeners"
                            class="Clickable">

                            <td>{{ listener.name }}</td>
                            <td>{{ getActionValue(listener) }}</td>
                            <td>{{ listener.minutes }}</td>
                            <td>{{ listener.hours }}</td>
                            <td>{{ listener.days }}</td>
                            <td>{{ listener.daysOfWeek }}</td>
                            <td>{{ listener.months }}</td>
                            <td>
                                <root-checkbox [value]="listener.enabled"
                                               [config]="{ label: '' }"
                                               (onChange)="setEnabled(listener, $event)"></root-checkbox>
                            </td>

                            <td class="has-text-right">
                                <root-button icon="edit" (onClick)="editListener(listener)" [notFullwidth]="true"></root-button>
                                <root-button icon="trash" (onClick)="deleteListener(listener)" [notFullwidth]="true"></root-button>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </ng-container>

            </div>
        </div>
    `,
    styles: [`
        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class NotificationsComponent implements OnInit {
    activeTab: number = 0;

    tableListeners: TableListener[];
    buttonListeners: ButtonListener[];
    periodicListeners: PeriodicListener[];

    actionEntity: any;
    actions: any[];

    tables: any[];

    constructor(
        private Entity: Entity,
        private Modal: MasterModalService,
        private Dialog: Dialog,
    ) {

    }

    ngOnInit() {

        this.actionEntity = this.Entity.get({ name: 'action', get: ['tables'] });

        this.actionEntity
            .search()
            .then((response: any) => {
                this.actions = response.map((action: any) => {
                    const splits = action.class.split('\\');

                    action.name = splits[splits.length - 1];

                    return action;
                });
            });

        this.tables = this.actionEntity.tables()
            .then((response: any[]) => {
                response.forEach((item) => {
                    item.name = item.show_name;
                });

                return response;
            });

        this.loadListeners();
    }

    loadListeners = () => {
        this.Entity.get('table_listener')
            .search(this.getExtraParams('table_listener'))
            .then((response: TableListener[]) => {
               this.tableListeners = response;
            });

        this.Entity.get('button_listener')
            .search(this.getExtraParams('button_listener'))
            .then((response: ButtonListener[]) => {
               this.buttonListeners = response;
            });

        this.Entity.get('periodic_listener')
            .search(this.getExtraParams('periodic_listener'))
            .then((response: PeriodicListener[]) => {
               this.periodicListeners = response;
            });

    };

    getExtraParams = (entity: string) => {
        if (entity === 'listener_log') {
            return { join: ['action', 'listener'], sort: { startedAt: 'desc' } };
        } else {
            return { join: ['action', 'listenerLog'] };
        }

    };

    createListener() {
        this.Dialog.open(ActionListenerCreateDialogComponent,
            {
                entity: this.getEntity(),
                refreshCallback: (response) => {
                    this.loadListeners();
                    this.editListener(response);
                }
            });
    }

    editListener = (listener: any) => {
        this.Dialog.open('root-action-edit-modal',
            {
                entity: this.getEntity(),
                item: listener,
                refreshCallback: () => this.loadListeners()
            });
    };

    deleteListener = (listener: any) => {
        const text = `Remove ${listener.name}?`;
        const callback = () => {
            this.Entity.get(this.getEntity())
                .remove({ id: listener.id })
                .then(this.loadListeners);
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });

    };

    getEntity = (): EntityNameType => {
        return this.activeTab === 0 ? 'table_listener' :
            this.activeTab === 1 ? 'button_listener' :
                this.activeTab === 2 ? 'periodic_listener' :
                null;
    };

    getActionValue = (row: any) => {
        if (!row.action) return '';

        const splits = row.action.class.split('\\');

        return row.action.name = splits[splits.length - 1];
    };

    setEnabled(listener: any, value: boolean) {
        listener.enabled = value;

        this.Entity.get(this.getEntity())
            .update({ id: listener.id, enabled: value });

    }

}
