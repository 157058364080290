import { isNumber } from '@proman/utils';
import { EntityInterface } from '../services/entity.service';
import { Material, MaterialFormat, MaterialQuantFormat } from '../interfaces/entity-interfaces';

export enum MATERIAL_STOCK {
    MATERIAL    = 'materials',
    INVENTORY   = 'inventory',
}

export type MaterialStockTypes = 'materials'|'inventory';

export const material = {
    name: 'material',
    params: {
        entityConfig: {
            name: 'material',
            get: [
                'getQrCode',
                'lessMinQuantity',
                'defaultQuant',
                'checkQuantity',
                'getSuppliersWithMaterials',
            ],
            post: [
                'copy',
                'addMultipleCategories',
                'removeMultipleCategories',
                'setMaterialFormat',
                'updateDefaultFormat',
                'unsetDefaultQuant',
                'addSupplier',
                'lastMonthConsumption',
            ],
            uris: {
                importMaterialsFromFileUri: 'import_materials_from_file',
                syncMaterialsFromFileUri: 'sync_materials_from_file'
            },
            attrs: {
                getFormat: (items: Array<MaterialQuantFormat|MaterialFormat>) => items && items
                    .map((item) => item && item.value !== null ? (isNumber(item.value) ? parseFloat(item.value).toFixed(0) : item.value) : 0)
                    .join('x'),
                roundQuantity: (quantity: number|string) => (+quantity || 0).toFixed(0),
                TEST_SUCCESS: 'success',
                TEST_FAIL: 'fail'
            }
        },
        get: {
            join: [
                'files',
                'tags',
                'suppliers',
                'suppliers.supplierMaterialFormats',
                'suppliers.supplierMaterialFormats.materialFormat',
                'suppliers.supplier',
                'suppliers.supplier.logo',
                'defaultSupplier',
                'defaultQuant',
                'materialType',
                'materialFormats',
                'materialFormats.materialTypeFormat',
                'materialFormats.materialTypeFormat.parameter',
                'namespaces',
                'vat',
                'warehouseLocation',
                'warehouseLocation.warehouse',
                'safetySigns',
                'safetySigns.file',
                'lotFunction',
                'barCodeFunction',
                'weightFunction',
                'codeFunction'
            ]
        },
        writeOffStrategies: [
            'fifo', 'lifo', 'fefo'
        ],
        searchFields: [
            'name',
            'alias',
            'materialCategories.name',
            'code1',
            'code2',
            'quants.barcode.value'
        ]
    },
};

export interface MaterialEntityInterface extends EntityInterface<Material> {
    TEST_SUCCESS: 'success';
    TEST_FAIL: 'fail';
    barcode: (data: any) => Promise<any>;
    addMultipleCategories: (data: { ids: number[]; categories: number[] }) => Promise<any>;
    removeMultipleCategories: (data: { ids: number[]; categories: number[] }) => Promise<any>;
    setMaterialFormat: (data: any) => Promise<any>;
    updateDefaultFormat: (data: { materialId: number; quantId: number }) => Promise<any>;
    unsetDefaultQuant: (data: { materialId: number; quantId: number }) => Promise<any>;
    roundQuantity: (quantity: number|string) => string;
    getFormat: (formats: Array<MaterialQuantFormat|MaterialFormat>) => string;
    lessMinQuantity: (stock: any) => Promise<any>;
    importMaterialsFromFileUri: string;
    syncMaterialsFromFileUri: string;
    getSuppliersWithMaterials: () => Promise<any>;
    copy: (data: { id: number }) => Promise<any>;
    defaultQuant: (data: { id: number }) => Promise<any>;
    checkQuantity: (data: { material: number[]; quantity: number[]|string[] }) => Promise<any>;
    addSupplier: (data: { materialIds: number[]; supplierId: number }) => Promise<any>;
    lastMonthConsumption: (data: { id: number[] }) => Promise<{ [key: string]: string }>;
}
