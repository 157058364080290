import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanButtonComponent } from '@proman/button/proman-button.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { TalkRoomComponent } from '@proman/talk-room/components/talk-room.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    PipesModule,
    PromanButtonComponent,
    PromanTextSimpleComponent
  ],
  declarations: [
    TalkRoomComponent
  ],
  exports: [
    TalkRoomComponent
  ]
})

export class PromanTalkRoomModule {}
