import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RequestService } from '@proman/services/request.service';
import { CookiesService } from '@proman/services/cookies.service';
import { MasterAuthService } from './master-auth.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { setCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class MasterGuardService implements CanActivate {


    constructor(
        private Auth: MasterAuthService,
        private Router: Router,
        private Request: RequestService,
        private Cookies: CookiesService,
        private WS: WebsocketService,
        private store: Store,
    ) {

    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
      this.Request.setAuthHeader(this.Cookies.get('jwt'));

      let result = !!this.Request.token;
      await this.Request.loadUser()
        .then((response: any) => {
          this.store.dispatch(setCurrUser({ payload: response }));
          this.WS.startSession();

          result = true;
        })
        .catch((e) => {
          console.log('catch', e);
          this.Router.navigate(['login']);

          result = false;
        });

      if (!result) {
        this.Router.navigate(['login']);
      }
        return Promise.resolve(result);
    }


}
