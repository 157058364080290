import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { RequestService } from '@proman/services/request.service';
import { ObservablesService } from '@proman/services/observables.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { setCurrUser } from '@proman/store/curr-user';
import { CONFIG } from '@proman/config';

@Injectable({ providedIn: 'root' })
export class MasterAuthService {

    constructor(
        private Router: Router,
        private Cookies: CookiesService,
        private Request: RequestService,
        private Observables: ObservablesService,
        private store: Store,
        private WS: WebsocketService,
    ) {

    }

    async login(username: string, password: string) {
        this.Observables.isUpdateVisible$.next(true);

        await this.Request
            .post(`${CONFIG.api}login_check`, { username, password })
            .then((response: any) => {
                const token = response.token;
                this.Cookies.set('jwt', token, 1 / 6);
                this.Cookies.set(COOKIE_AUTHORIZATION, token, 1 / 6);
                this.Request.setAuthHeader(token);
                this.store.dispatch(setCurrUser({ payload: response }));
                this.Router.navigate(['/servers']);
            })
            .catch((error: any) => console.warn(error));

        this.Observables.isUpdateVisible$.next(false);
    }

    logout() {
        this.Request
            .post('user/logout')
            .then(() => {
                this.Request.setAuthHeader('');
                this.Cookies.remove('jwt');
                this.Router.navigate(['login']);
            });
    }

}
