import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '@proman/services/request.service';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { Language, Translation } from '../interfaces';
import { findByProperty } from '@proman/utils';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'root-translation-edit',
    template: `
        <pro-dialog-title [title]="'Edit' | translate"></pro-dialog-title>
        <section mat-dialog-content class="columns is-marginless">
            <div class="fl fl-column fl-center">
                <div class="fl fl-row fl-center">
                    <root-text [value]="translation.trKey"
                               [config]="{ label: 'Key', required: true }"
                               (onChange)="setKey($event)"></root-text>
                    <root-select [value]="language"
                                 [options]="languageOptions"
                                 [config]="{ label: 'Language' }"
                                 (onChange)="setLang($event)"></root-select>
                </div>
                <root-text [value]="translation[language?.code]"
                           [config]="{ label: 'Value' }"
                           (onChange)="setValue($event)"></root-text>
            </div>
        </section>
        <pro-dialog-actions (callback)="confirmInput()" [variant]="'confirm'"></pro-dialog-actions>
    `,
})

export class TranslationEditDialogComponent implements OnInit, OnDestroy {
    translation: Translation;
    language: Language;
    languageOptions: Language[];
    params: { refreshCallback: () => void, translation: Translation };

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<TranslationEditDialogComponent>,
        private router: Router,
        private Request: RequestService,
        private Entity: Entity,
    ) {

    }

    ngOnInit() {
        (this.Entity.get('language') as unknown as EntityInterface<Language>)
          .search({}).then((response: Language[]) => {
            this.languageOptions = response;
            if (response.length > 0) this.language = response[0];
        });
        this.params = this.data;
        this.translation = this.data.translation;
    }

    ngOnDestroy() {

    }

    setKey(value: string) {
        this.translation.trKey = value;
    }

    setLang(value: Language) {
        value = findByProperty(this.languageOptions, 'name', value);
        this.language = value;
    }

    setValue(value: string) {
        this.translation[this.language.code] = value;
    }

    close() {
        this.dialogRef.close();
    }

    confirmInput() {
        this.Entity.get('translation')
            .update({
                id: this.translation.id,
                trKey: this.translation.trKey,
                en: this.translation.en,
                lt: this.translation.lt,
                bg: this.translation.bg,
                zh: this.translation.zh,
                de: this.translation.de,
                es: this.translation.es,
                fr: this.translation.fr,
                pl: this.translation.pl,
                ru: this.translation.ru,
                tr: this.translation.tr,
                ua: this.translation.ua,
                vn: this.translation.vn
            })
            .then(() => this.dialogRef.close(1));
    }

}
