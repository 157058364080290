import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import {TextComponent} from './text.component';
import {ButtonComponent} from './button.component';
import {SelectComponent} from './select.component';
import {CheckboxComponent} from './checkbox.component';
import {InputComponent} from './input.component';
import {TabsComponent} from './tabs.component';
import {TimeComponent} from './time.component';
import {PaginationComponent} from './pagination.component';
import {PipesModule } from '@proman/shared/pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';
import { PromanTextSimpleComponent } from '@proman/text-simple/proman-text-simple.component';
import { PromanColorComponent } from "@proman/color/proman-color.component";
import {PromanSelectComponent} from "@proman/select";

const COMPONENTS = [
    TextComponent,
    ButtonComponent,
    SelectComponent,
    CheckboxComponent,
    InputComponent,
    TabsComponent,
    TimeComponent,
    PaginationComponent,
]
@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatLegacyProgressBarModule,
        ReactiveFormsModule,
        MatLegacyMenuModule,
        FlexLayoutModule,
        PipesModule,
        PromanTextSimpleComponent,
        PromanColorComponent,
        PromanSelectComponent,
    ],
    providers: [
        COMPONENTS
    ],
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ]
})

export class InputsModule {

}
