import { Component, OnInit } from '@angular/core';
import { ACL } from '@proman/services/acl.service';
import { TableConfig } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'root-fiscals-stats',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [loadWhen]="tableConfig"></pro-table>
            </div>
        </div>
    `,
    styles: [`
        td {
            vertical-align: middle;
        }

        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class FiscalsStatsComponent implements OnInit {
    tableConfig: TableConfig;

    constructor(
        public ACL: ACL,
    ) {}

    ngOnInit() {
    }

    async setTableConfig() {
        this.tableConfig = {
            entity: 'fiscal',
            aclRoot: 'fiscal',
            tableId: 'fiscal',
            addButton: {
                acl: 'fiscal.create',
            },
            fields: [
                {
                    name: 'Fiscal ID',
                    key: 'id',
                },
            ],
            extraParameters: {},
            preventFixedHeader: true,
            rowButtons: []
        }
    }

}
