import {
    Component,
    Input,
    Output,
    OnChanges,
    EventEmitter,
    SimpleChanges, HostListener
} from '@angular/core';
import { TooltipPosition } from '../tooltip/tooltip.directive';
import { BtnThemePalette } from '../interfaces/btn-theme-pallete';
import { CommonModule } from '@angular/common';
import { Fa6Module } from '../fa/fa6.module';
import { GlobalOverlayModule } from '../overlay/global-overlay.module';
import { TooltipDirectiveModule } from '../tooltip/tooltip-directive.module';
import { TranslateService } from '@ngx-translate/core';
import { TranslatePipe } from '../shared/pipes/pipes/translate.pipe';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'pro-btn',
    standalone: true,
    imports: [
        CommonModule,
        MatLegacyButtonModule,
        Fa6Module,
        GlobalOverlayModule,
        TooltipDirectiveModule,
        TranslatePipe,
    ],
    template: `
        @switch (!!icon) {
            @case (true) {
            <button [disabled]="disabled || pending"
                    [tabIndex]="tabIndex"
                    [attr.data-button]="icon"
                    [type]="type || 'button'"
                    [proOverlay]="{ type: 'button', data: tooltip | translate, status: isCheckbox ? (theme === 'accent'): undefined }"
                    [ngClass]="{ 'stretched': stretch }"
                    class="BtnIcon {{theme}} has-tooltip-{{ (tooltipPosition || 'top') }}">
                <fa [name]="icon" [size]="size" [faClass]="faClass || 'far'"></fa>
            </button>
            }
            @case (false) {
                <button mat-button
                        [color]="theme"
                        [type]="type || 'button'"
                        [disabled]="disabled || pending"
                        [attr.data-button]="label"
                        [ngClass]="{ 'has-background': !!background,
                            'accent-background' : !!background && theme === 'accent',
                            'stretched': stretch
                         }"
                        class="has-tooltip-{{ (tooltipPosition || 'top') }}"
                        [ngStyle]="{ background: background }"
                        [proTooltip]="tooltip | translate"
                >

                    {{ label | translate }}
                    @if (pending) {
                        <div class="spinner"></div>
                    }
                    <ng-content></ng-content>
                </button>
            }

        }

    `,
    styles: [`
        .ButtonDisabledTooltip {
            position: absolute;
            height: 100%;
            width: 100%;
            top:0;
            left: 0;
            background: transparent;
        }
        :host {
            position: relative;
        }
        button:hover {
            cursor: pointer;
        }
        .has-background {
            margin: 0 4px;
        }
        .accent-background {
            border: 1px solid cornflowerblue;
        }
        .stretched {
            width: 100%;
        }
    `],
    providers: [
        TranslateService,
    ]
})

export class PromanButtonComponent implements OnChanges {
    @Input() label: string;
    @Input() theme: BtnThemePalette;
    @Input() type: string;
    @Input() icon: string;
    @Input() pattern: string;
    @Input() faClass: string;
    @Input() size: string = '2x';
    @Input() disabled: boolean;
    @Input() tooltip: string;
    @Input() tooltipPosition: TooltipPosition;
    @Input() disabledTooltip: string;
    @Input() pending: any;
    @Input() table: boolean;
    @Input() tabIndex: number;
    @Input() isCheckbox: boolean;
    @Input() background: string;
    @Input() stretch: boolean;
    @Input() delayMultiClick: boolean = true;
    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    delayDisabled: boolean = false;

    @HostListener('click', ['$event']) handleClick($event: MouseEvent) {
        if (!this.disabled && !this.delayDisabled) {
            this.onClick.emit($event);
        }

        if (this.delayMultiClick) {
            this.delayDisabled = true;
            setTimeout(() => this.delayDisabled = false, 2000);
        }
    }

    private patterns: any = {
        confirm: {
            label: 'confirm',
            theme: 'accent'
        },
        copy: {
            label: 'copy',
            theme: 'accent'
        },
        create: {
            label: 'create',
            theme: 'accent'
        },
        add: {
            label: 'add',
            theme: 'accent'
        },
        cancel: {
            label: 'cancel',
            theme: 'primary'
        },
        close: {
            label: 'close',
            theme: 'primary'
        },
        remove: {
            label: 'remove',
            theme: 'warn'
        },
        update: {
            label: 'update',
            theme: 'accent'
        }
    };

    constructor(
        private Translate: TranslateService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.theme || changes.label || changes.icon || changes.pattern || changes.disabled) {

            if (this.pattern) {
                this.label = this.Translate.instant(this.getPatternValue('label'));
                this.theme = this.getPatternValue('theme');
                console.log('is pattern', this.label, this.theme);
            }
        }
    }

    getPatternValue(property: string) {
        return this.patterns[this.pattern] ? this.patterns[this.pattern][property] : null;
    }

}
