import { Component } from '@angular/core';
import { MasterRegistryService } from '@proman/services/master-registry.service';
import { ToastService } from '@proman/services/toast.service';
import { Dialog } from '../../services/dialog.service';
import { FiscalDialogComponent } from '../../dialogs/fiscal-dialog.component';

const errorText = 'Jei kasos aparatas yra statoma naujam objekte kuriame prieštai tos įmonės vardu nebuvo kasos aparato, įmonės savininkas pirmiau turi nurodyti savo veiklos adresą užpildant Mano VMI formą „Juridinių asmenų struktūrinių padalinių duomenų pakeitimas“ (FR0791A).';

@Component({
  selector: 'root-public-fiscal-form',
  template: `
    <div class="container">
      @if (!codeAccepted) {
        <pro-text-simple [value]="registrationCode" [config]="{ label: 'registration_code' }" (onChange)="setRegistrationCode($event)"></pro-text-simple>
        <pro-btn theme="accent" label="submit" [disabled]="!registrationCode" (onClick)="submitCode()"></pro-btn>
      }
    </div>
  `
})

export class PublicFiscalFormComponent {
  registrationCode: string;
  codeAccepted: boolean;

  constructor(
    private MasterRegistry: MasterRegistryService,
    private Dialog: Dialog,
    private Toast: ToastService,
  ) {
  }

  setRegistrationCode = (value: string) => this.registrationCode = value;

  submitCode() {
    this.MasterRegistry.checkCompanyCode(this.registrationCode).then((response) => {
      console.log(response);
      this.codeAccepted = true;
      this.Toast.pop('success', 'company_found');
      this.Dialog.open(FiscalDialogComponent, { ...response, publicMode: true }, { disableClose: true });
    }).catch((err) => {
      this.Toast.pop('error', 'company_not_found');
      alert(errorText);
      console.log('error: ', err);
    });
  }
}
