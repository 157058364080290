import { Component } from '@angular/core';
import { RequestService } from '@proman/services/request.service';

@Component({
    selector: 'root-login',
    template: `
        <div class="hero">
            <div class="hero-body is-centered fl-row fl-center">
                <div class="RegistrationForm fl-column fl-center">
                    <root-text [value]="email"
                               [config]="{ label: 'email' }"
                               (onChange)="set('email', $event)"></root-text>
                    <root-text [value]="password"
                               [config]="{ label: 'password', type: 'password' }"
                               (onChange)="set('password', $event)"></root-text>
                    <root-text [value]="passwordRepeat"
                               [config]="{ label: 'repeat_password', type: 'password' }"
                               (onChange)="set('passwordRepeat', $event)"></root-text>

                    <root-button label="register"
                                 (onClick)="register()"></root-button>
                </div>
            </div>
        </div>

    `,
    styles: [`
        .RegistrationForm {
            width: 400px;
        }
    `]
})

export class RegistractionComponent {
    email: string;
    password: string;
    passwordRepeat: string;

    constructor(
        private Request: RequestService,
    ) {

    }

    set(property: string, value: string) {
        this[property] = value;
    }

    register() {
        console.log('handleLogin()');
        this.Request
            .post('register', { email: this.email, password: this.password, password_confirmation: this.passwordRepeat })
            .then((response: any) => {
                console.log('response', response);
            });
    }

}
