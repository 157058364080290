import { Component, OnInit } from '@angular/core';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { ToastrService } from 'ngx-toastr';
import { ACL } from '@proman/services/acl.service';
import { Dialog } from '../services/dialog.service';
import { EntityCreateDialogComponent } from '../dialogs/entity-create-dialog.component';
import { EntityEditDialogComponent } from '../dialogs/entity-edit-dialog.component';
import { ActionConfirmDialogComponent } from '../dialogs/action-confirm-dialog.component';
import { PermissionsEditDialogComponent } from '../dialogs/permissions-edit-dialog.component';
import { MasterPerson } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'root-persons',
    template: `
        <div class="hero">
            <root-header></root-header>

            <div class="container fl fl-column Width100 PersonsContainer" >

                <div class="fl-row">
                    <root-button *ngIf="canCreatePerson" [label]="'Create'"
                                 (onClick)="createPerson()"
                    ></root-button>
                </div>

                <table class="table is-fullwidth">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let person of persons"
                        class="Clickable">

                        <td>{{ person.name }}</td>
                        <td class="has-text-right">
                            <root-button icon="edit" (onClick)="editPerson(person)" [notFullwidth]="true"></root-button>
                            <root-button icon="check-double" (onClick)="editPermissions(person)" [notFullwidth]="true"></root-button>
                            <root-button icon="user-plus" (onClick)="createUser(person)" [notFullwidth]="true" *ngIf="!person.users.length"></root-button>
                            <root-button icon="user-edit" (onClick)="editUser(person)" [notFullwidth]="true" *ngIf="person.users.length"></root-button>
                            <root-button icon="user-slash" (onClick)="removePerson(person)" [notFullwidth]="true"></root-button>
                        </td>

                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
    `,
    styles: [`

        .PersonsContainer {
            padding-top: 5em;
        }

    `]
})

export class PersonsComponent implements OnInit {
    persons: MasterPerson[];
    canCreatePerson: boolean;

    constructor(
        private Entity: Entity,
        private Dialog: Dialog,
        private Toastr: ToastrService,
        private ACL: ACL,
    ) {
        this.canCreatePerson = this.ACL.check('employee.create');
        console.log(this.canCreatePerson);
    }

    ngOnInit() {
        this.getPersons();
    }

    getPersons() {
      (this.Entity.get('employee') as unknown as EntityInterface<MasterPerson>)
            .search({ join: ['users'] })
            .then((response: MasterPerson[]) => this.persons = response);
    }

    editPerson(person: MasterPerson) {
        this.Dialog.open(EntityEditDialogComponent, {
            item: person,
            mainField: {
                key: 'name',
                config: { label: 'name' },
                type: 'text'
            },
            parameters: [
                {
                    key: 'email',
                    config: { label: 'Email' },
                    validator: (value: string) => !!value && /\S+@\S+\.\S+/.test(value),
                    type: 'text'
                },
            ],
            entity: 'employee',
        }).then(() => {
            this.getPersons();
        })
    }

    createPerson() {
        this.Dialog.open(EntityCreateDialogComponent, {
            mainField: {
                key: 'firstName',
                config: { label: 'name', required: true, debounce: 0 },
                type: 'text',
            },
            parameters: [
                {
                    key: 'lastName',
                    config: { label: 'Last name', required: true, debounce: 0 },
                    type: 'text',
                },
                {
                    key: 'email',
                    config: { label: 'Email', required: true, debounce: 0 },
                    type: 'text',
                    validator: (value: string) => !!value && /\S+@\S+\.\S+/.test(value)
                },
            ],
            entity: 'employee',
        }).then(() => {
            this.getPersons();
        });
    }

    editPermissions(person: MasterPerson) {
        this.Dialog.open(PermissionsEditDialogComponent, { person });
    }

    createUser(person: MasterPerson) {
        this.Dialog.open(EntityCreateDialogComponent, {
            mainField: {
                key: 'username',
                type: 'text',
                config: { label: 'username' },
            },
            entity: 'user',
            parameters: [
                {
                    key: 'password',
                    type: 'text',
                    config: { type: 'password', label: 'password'},
                },
            ],
            params: { person: person.id },
        }).then(() => this.getPersons());
    }

    editUser(person: MasterPerson) {
        this.Dialog.open(EntityEditDialogComponent, {
            item: person.users[0],
            mainField: {
                key: 'username',
                type: 'text',
                config: { label: 'username' },
            },
            entity: 'user',
            parameters: [
                {
                    key: 'password',
                    type: 'text',
                    config: { type: 'password', label: 'password'},
                },
            ],
            params: { person: person.id },
        }).then(() => this.getPersons())
    }

    removePerson(person: MasterPerson) {
        const text = `Remove ${person.name}?`;
        const callback = () => {
            this.Entity.get('person')
                .remove({id: person.id})
                .then(() => {
                    this.Entity.get('user').get({id: person.id}).catch((err) => {

                    }).then((response) => {
                        if (response) {
                            this.Entity.get('user').remove({id: person.id}).then(() => {
                                this.getPersons();
                            });
                        } else {
                            this.getPersons();
                        }
                    }).catch((err) => {});
                });
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });
    }
}
