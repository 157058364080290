import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ACL, PermissionType } from '@proman/services/acl.service';
import { routeMap } from '@proman/resources/root-route-map';

@Component({
    selector: 'root-default',
    template: `
        <div class="hero">
            <root-header></root-header>
        </div>
    `,
    styles: [`

    `]
})

export class DefaultRouteComponent implements OnInit {
    routeMap: any[];

    constructor(
       private Router: Router,
       private ACL: ACL,
    ) {
        this.routeMap = routeMap;
    }

    ngOnInit() {
        this.redirectToAvailableRoute();
    }

    redirectToAvailableRoute() {


        for (let acl of Object.keys(this.routeMap)) {

            if (acl === 'Servers') acl = 'server.view';
            if (acl === 'ServerContainers') acl = 'container.view';

            if (this.ACL.check(acl as PermissionType)) {
                this.Router.navigate([this.routeMap[acl]]);
                break;

            }

        }

        this.Router.navigate(['/servers']);
    }

}
