import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'root-checkbox',
    template: `
        <ng-container *ngIf="!config.iconOnly">
            <div class="Checkbox-container fl-row fl-align-center" (click)="$event.stopPropagation();">
                <label class="checkbox"
                       [attr.data-name]="label || config.label"
                       [attr.data-value]="!!value"
                       [attr.data-tooltip]="tooltip"
                       [ngClass]="{ 'is-disabled': disabled }">
                    <span class="is-size-6">{{ (label || config.label) }}{{ config.required && '*' }} &nbsp;</span>
                    <input type="checkbox" name="checkbox" [checked]="value" (change)="handleChange($event.target.checked)"
                           [disabled]="disabled">
                    <span class="checkmark"></span>
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="config.iconOnly">
            <a [ngClass]="{ 'button': true, 'accent': value, 'grey': !value }" (click)="handleChangeIconOnly()">
                <fa [name]="config.icon"></fa>
            </a>
        </ng-container>
    `,
    styles: [`
        .button {
            border: 0;
        }
    `]
})

export class CheckboxComponent implements OnInit {
    @Input() value: boolean;
    @Input() label: string;
    @Input() config: any = {};
    @Input() disabled: boolean;
    @Input() tooltip: string;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(

    ) {

    }

    ngOnInit() {

    }

    handleChange(value: boolean) {
        if (!this.disabled) this.onChange.emit(value);
    }

    handleChangeIconOnly() {
        if (!this.disabled) {
            this.value = !this.value;
            this.onChange.emit(this.value);
        }
    }
}
