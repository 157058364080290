import { Component, OnInit } from '@angular/core';
import { RequestService } from '@proman/services/request.service';
import { ACL } from '@proman/services/acl.service';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { filter, first } from '@proman/rxjs-common';
import { AuthService } from '@proman/services/auth.service';

@Component({
    selector: 'root-header',
    template: `
        <header class="hero-head">
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" [routerLink]="['']">
                        <img src="../../assets/img/smarton_logo_ball_master.png" width="45" height="45" style="max-height: initial!important;">
                    </a>

                    <a role="button" class="navbar-burger burger" [ngClass]="{ 'is-active': isNavbarActive }" (click)="isNavbarActive = !isNavbarActive" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBasicExample" class="navbar-menu" [ngClass]="{ 'is-active': isNavbarActive }">
                    <div class="navbar-start">
                        <a class="navbar-item"  [routerLink]="['/']">
                            {{ user?.username || '' }}
                        </a>
                        <a class="navbar-item"  [routerLink]="['/servers']" *ngIf="ACL.check('server.view')">
                            Servers
                        </a>
                        <a class="navbar-item"  [routerLink]="['/servers-stats']" *ngIf="ACL.check('server.view')">
                            Servers stats
                        </a>
                        <a class="navbar-item"  [routerLink]="['/containers']" *ngIf="ACL.check('container.view')">
                            Containers
                        </a>
                        <a class="navbar-item"  [routerLink]="['/languages']" *ngIf="ACL.check('translation.view')">
                            Languages
                        </a>
                        <a class="navbar-item"  [routerLink]="['/persons']" *ngIf="ACL.check('permission.edit')">
                            Persons
                        </a>
                        <a class="navbar-item"  [routerLink]="['/notifications']" *ngIf="ACL.check('notification.edit')">
                            Notifications
                        </a>
                        <a class="navbar-item"  [routerLink]="['/fiscals']" *ngIf="ACL.check('fiscal.create')">
                            Fiscal
                        </a>
                        <a class="navbar-item"  [routerLink]="['/builds']" *ngIf="ACL.check('container.view')">
                            Git Builds
                        </a>
                        <a class="navbar-item"  [routerLink]="['/tags']" *ngIf="ACL.check('container.view')">
                            Tags
                        </a>
                        <a class="navbar-item" [routerLink]="['/talk-room']">
                            Talk Room
                        </a>
                    </div>

                    <div class="navbar-end">
                        <a class="navbar-item" (click)="logout()">
                            Logout
                        </a>
                    </div>
                </div>

            </nav>
        </header>
    `,
    styles: [`
    header {
        box-shadow: 0 0 5px 5px #E4E4E5;
        position: fixed;
        width: 100%;
        z-index: 10;
        background: white;
    }

    `]
})

export class HeaderComponent implements OnInit {
    user: any;
    isNavbarActive: boolean;

    constructor(
        private Request: RequestService,
        private Auth: AuthService,
        public ACL: ACL,
        private store: Store,
    ) {
      this.store.select(getCurrUser)
        .pipe(filter((value) => !!value), first())
        .subscribe((value) => {
          this.user = value;
        })
    }

    ngOnInit(): void {

    }

    logout() {
        this.Auth.logout();
    }

}
