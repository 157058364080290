import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'root-action-confirm',
    template: `
        <div>
            <pro-dialog-title [title]="'action_confirmation' | translate"></pro-dialog-title>
            <section mat-dialog-content>

                <h4 class="title is-4">{{ (text || 'action_confirmation') | translate }}</h4>

            </section>
            <pro-dialog-actions (callback)="confirm()" [variant]="'confirm'"></pro-dialog-actions>
        </div>
    `
})

export class ActionConfirmDialogComponent implements OnInit {
    text: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        public dialogRef: MatLegacyDialogRef<ActionConfirmDialogComponent>,
    ) {

    }

    ngOnInit() {
        this.text = this.data.text;
    }

    confirm() {
        this.dialogRef.close(1);
    }

    close() {
        this.dialogRef.close(0);
    }

}
