import { NgModule } from '@angular/core';
import {
  PromanTableModule,
  TABLE_STATE_PARSER,
} from '@proman/table';
import { MasterTableStateParserService } from './master-table-state-parser.service';
import { MASTER_TABLECACHE_KEY } from '../const';
import { TableCellSelectComponent } from '@master/components/root-table-cell-select.component';

@NgModule({
  imports: [
    PromanTableModule.forRoot({
      directives: {
        'root-table-cell-select': TableCellSelectComponent,
      },
      configuration: {
        lazyLoadIncrements: 50,
        storagePrefix: MASTER_TABLECACHE_KEY
      }
    })
  ],
  providers: [
    { provide: TABLE_STATE_PARSER, useClass: MasterTableStateParserService },
  ],
  exports: [
    PromanTableModule
  ]
})

export class MasterPromanTableModule {

}
