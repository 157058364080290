import { Component } from '@angular/core';
import { PublicContainerStatusEntityInterface } from '@proman/resources/public_container_status';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'root-public-create',
    template: `
        <div class="public-create">
            <div class="hero is-fullwidth fl-center fl-align-center">

                <div class="hero-body fl-column fl-center fl-align-center">

                    <img src="../../assets/img/prochef_logo_blue.png" width="115" height="115"
                         style="max-height: initial!important;"><br>

                    <pro-text-simple [value]="name"
                                     [config]="{ label: 'Name', debounce: 400 }"
                                     (onChange)="setName($event)"></pro-text-simple>
                    <div *ngIf="inValidName" style="color: red;">{{ validNameMessage }}</div>

                    <pro-text-simple [value]="email"
                                     [config]="{ label: 'Email', debounce: 1100 }"
                                     (onChange)="setEmail($event)"></pro-text-simple>
                    <div *ngIf="InValidEmail && email" style="color: red;">{{ validEmailMessage }}</div>

                    <div *ngIf="!created" class="fl-row fl-space-between" style="margin-top: 8px;">
                        <pro-btn [label]="'Create'"
                                 [disabled]="InValidEmail || inValidName"
                                 (onClick)="createContainer()"></pro-btn>
                    </div>
                    <div *ngIf="created" style="color: green; text-align:center;">
                        {{ createdString }} <br>
                        {{ emailString }}
                    </div>

                </div>

            </div>
        </div>
    `
})

export class PublicContainerCreateComponent {
    name: string = 'domain.prochef.app';
    email: string;
    InValidEmail: boolean = true;
    inValidName: boolean = true;
    created: boolean = false;
    createdString: string;
    emailString: string;
    validNameMessage: string;
    validEmailMessage: string;

    constructor(
        private Entity: Entity,
    ) {

    }

    setName(host: string) {
        host = host.toLowerCase();
        // (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface).checkHostName({host})
        //     .then((response) => {
        //         this.inValidName = !response.valid;
        //         this.validNameMessage = response.message;
        //         this.name = host;
        // });
        // console.log( this.inValidName + ' this.inValidName')
    }


    setEmail(email: string) {
        // email = email.toLowerCase();
        // (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface).checkEmail({email})
        //     .then((response) => {
        //         this.InValidEmail = !response.valid;
        //         this.validEmailMessage = response.message;
        //         this.email = email;
        //     });
        // console.log(this.InValidEmail + ' this.InValidEmail')
    }

    createContainer() {
        // (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface)
        //     .createContainer({ name: this.name, email: this.email })
        //     .then(() => {
        //         this.created = true;
        //         this.createdString = 'Successfully created: ' + this.name;
        //         this.emailString =  'Information sent to email: ' + this.email;
        //     });
    }

}
