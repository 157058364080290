import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ObservablesService } from '@proman/services/observables.service';
import { EntityNameType } from '@proman/services/entity.service';
import { isDefined } from '@proman/utils';
import { ActionConfirmDialogComponent } from '../dialogs/action-confirm-dialog.component';
import { EntityCreateDialogComponent } from '../dialogs/entity-create-dialog.component';
import { EntityEditDialogComponent } from '../dialogs/entity-edit-dialog.component';
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';

export interface DialogOptions {
    disableAutoFocus?: boolean;
    width?: string;
    refreshOnClose?: boolean;
    disableClose?: boolean;
}

const defaultOptions: Partial<DialogOptions> = {
  disableAutoFocus: true
};

export interface DialogData {
    entity?: EntityNameType;
    mainField?: Partial<EntityItemFieldConfig>;
    parameters?: any[];
    header?: string;
    [key: string]: unknown;
}

@Injectable({ providedIn: 'root' })
export class Dialog {

    constructor(
        private MatLegacyDialog: MatLegacyDialog,
        private Observables: ObservablesService,
    ) {

    }

    open2(Component: any, data: DialogData = {} as DialogData, options?: DialogOptions) {

        return this.MatLegacyDialog.open(Component,
            {
                data,
                autoFocus: !(options && options.disableAutoFocus),
                width: (options && options.width) || '',
                disableClose: (options && options.disableClose)
            }
        );
    }

    open(Component: any, data?: DialogData, options?: DialogOptions) {
        return new Promise((resolve, reject) => {
            this.open2(Component, data, options)
                .afterClosed()
                .subscribe((result: unknown) => {
                    // @ts-ignore
                    return (isDefined(result) || options && options.refreshOnClose) && resolve(result);
                });
        })
    }

    confirm($event: any, question: any, callback: any) {
        let dialogRef = this
            .open2(ActionConfirmDialogComponent, { text: question });

        dialogRef
            .afterClosed()
            .subscribe((response: any) => {
                if (response) callback();
            });
    };

    // confirmRemove(item: any, entity: any, callback?: any) {
    //     let dialogRef = this
    //         .open2(EntityRemoveDialogComponent, { item, entity });
    //
    //     dialogRef
    //         .afterClosed()
    //         .subscribe((response: any) => {
    //             if (response) callback();
    //
    //         });
    // };

    entityCreate(entity: any, params?: any, config?: any, options?: any) {
        let data: any = { entity };

        if (params) {
            data.params = params;

        }

        if (config) {
            data = Object.assign(config, data);

        }

        return this
            .open2(EntityCreateDialogComponent, data, options)
            .afterClosed()
            .toPromise()
            .then((response: any) => {
                return new Promise((resolve) => {
                    if (response) {
                        resolve(response)
                    }
                })
            });
    };

    entityEdit(entity: any, item: any, config: any = {}) {
        let data: any = { entity, item };

        if (config) data = Object.assign(data, config);

        return this.open2(EntityEditDialogComponent, data)
    };

}
