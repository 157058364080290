import { Component } from '@angular/core';
import { TableConfig } from '@proman/interfaces/object-interfaces';

@Component({
  selector: 'root-talk-room',
  template: `
      <div class="hero">
          <root-header></root-header>
          <div class="container">
              <pro-table *ngIf="!inCall" [config]="tableConfig" [timeStamp]="timestamp"></pro-table>
              <pro-talk-room *ngIf="inCall" [selectedRoom]="selectedRoom" (onLeave)="leaveCall()"></pro-talk-room>
          </div>
      </div>
  `,
  styles: [`
      .container {
          margin-top: 5em;
          margin-bottom: 1em;
      }
  `]
})

export class MasterTalkRoomComponent {
  tableConfig: TableConfig;
  timestamp: number;
  inCall: boolean;
  selectedRoom: any;

  constructor(

  ) {
    this.setTableConfig();
  }

  setTableConfig() {
    this.tableConfig = {
      entity: 'talk_room',
      aclRoot: null,
      fields: [
        {
          name: 'name',
          key: 'name',
        },
        {
          name: 'person_id',
          key: 'peerId',
        },
        {
          name: 'namespace',
          key: 'peerNamespace',
        }
      ],
      rowButtons: [
        {
          icon: 'phone',
          theme: 'accent',
          acl: null,
          callback: this.acceptCall,
        }
      ],
      extraParameters: {
        status: 'pending',
      }
    }
  }

  refresh = () => this.timestamp = new Date().getTime();

  acceptCall = (row: any) => {
    this.selectedRoom = row;
    this.inCall = true;
  }

  leaveCall() {
    this.selectedRoom = null;
    this.inCall = false;
    this.refresh();
  }
}
