import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterModalService } from '../services/master-modal.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'root-modal',
    template: `
    <div class="modal"
         [ngClass]="{ 'is-active' : !!component }"
         [ngSwitch]="component">
        <div                            *ngSwitchDefault>
            <div class="modal-background"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Error</p>
                    <button class="delete" aria-label="close" (click)="closeOverlay()"></button>
                </header>
                <section class="modal-card-body">
                    UNKNOWN COMPONENT INPUT
                </section>
                <footer class="modal-card-foot">
                    <button class="button" (click)="closeOverlay()">Close</button>
                </footer>
            </div>
        </div>
    </div>
    `
})

export class ModalComponent implements OnInit, OnDestroy {
    component: string;
    params: any;
    canCloseModal: boolean = false;
    _sub: Subscription;
    _subClose: Subscription;

    constructor(
        private Modal: MasterModalService
    ) {
        this.component = this.Modal.componentAlias;
        this._sub = Modal.componentChange.subscribe((value) => {
            this.component = value;

            setTimeout(() => this.canCloseModal = true);
        });

        this._subClose = this.Modal.modalClose.subscribe((value) => {
            if (value) this.closeOverlay();
        });
    }

    ngOnInit() {
        document.addEventListener('keydown', this.handleEscape);
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
        this._subClose.unsubscribe();

        document.removeEventListener('keydown', this.handleEscape);
    }

    closeOverlay() {
        if (!this.canCloseModal) return;

        this.component = null;
        this.canCloseModal = false;

    }

    handleEscape = (e: KeyboardEvent) => {

        if (this.component && (e.code === 'Escape' || e.key === 'Escape')) {
            this.Modal.close();
        }

    };

}
