import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { FilterService } from '@proman/services/filter.service';
import { EntityInterface } from '@proman/services/entity.service';
import { prepareRequest } from '@proman/utils';
import { Entity } from '@proman/services/entity.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { EntityItemFieldConfig } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'root-entity-create-dialog',
    template: `
        <form #createForm="ngForm"
              [formGroup]="form">
            <pro-dialog-title [title]="data.header || 'new'"></pro-dialog-title>
            <div mat-dialog-content>
                <root-input *ngFor="let parameter of parameters"
                              [type]="parameter.type"
                              [value]="parameter.value"
                              [config]="parameter.config"
                              (onChange)="set(parameter.key, $event)">
                </root-input>
            </div>
            <pro-dialog-actions variant="create" (callback)="create(createForm)" [isCallbackDisabled]="disableSubmitCallback"></pro-dialog-actions>
        </form>
    `
})

export class EntityCreateDialogComponent {
    parameters: EntityItemFieldConfig[];
    form: FormGroup;
    controls: any = {};
    entityInstance: EntityInterface;
    disableSubmitCallback: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        // private UniqueValidatorService: UniqueValidatorService,
        private Toast: ToastrService,
        private Filter: FilterService,
        public dialogRef: MatLegacyDialogRef<EntityCreateDialogComponent>
    ) {
        let mainField: EntityItemFieldConfig = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: Object.assign({ required: true }, this.data.mainField && this.data.mainField.config || {})
        };

        if (!mainField.notUnique) {
            mainField.config.validators = mainField.config.validators || {};
            mainField.config.validators.unique = { resource: data.entity, field: mainField.key };
        }

        this.entityInstance = Entity.get({ name: this.data.entity });

        this[this.data.entity] = {};

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) this.parameters = [].concat(this.parameters, this.data.parameters);

        for (let parameter of this.parameters) {

            if (parameter.config.control) this.controls[parameter.key] = parameter.config.control;

            // no form control for files
            // if (parameter.config.required && !this.controls[parameter.key] && !(parameter.type === 'files')) {
            //     let control = new FormControl(parameter.value || '', Validators.required);
            //     this.controls[parameter.key] = control;
            //     parameter.config.control = control;
            // }

            if (parameter.value) this.set(parameter.key, parameter.value);

            if (parameter.type === 'string') parameter.config = Object.assign({ debounce: 0 }, parameter.config);

        }

        this.form = new FormGroup(this.controls);
    }

    set(property: string, value: any) {
        this[this.data.entity][property] = value;
    }

    create(createForm: any) {
        console.log(createForm);
        if (createForm.valid && this.isRequiredFilled()) {

            let data = prepareRequest(this[this.data.entity]);

            if (this.data.params) data = Object.assign(data, this.data.params);

            this.disableSubmitCallback = true;

            if (this.data.postSuffix) {
                return this.entityInstance.postRequest(this.data.postSuffix, data)
                    .then((response: any) => this.functionA(response)).catch(() => this.disableSubmitCallback = false);

            }

            return this.entityInstance.create(data)
                .then((response: any) => this.functionA(response))
                .catch(() => {
                        this.disableSubmitCallback = false;
                    }
                );

        }

    }

    functionA(response: any) {
        const result = response.data || response;
        let requests: any = [];

        return Promise.all(requests)
            .then(() => this.dialogRef.close(result))
            .catch(() => this.dialogRef.close(result));
    }

    isRequiredFilled() {
        for (let item of this.parameters) {
            if (item.config && item.config.required && !this[this.data.entity][item.key]) {
                let text = this.Filter.translate('missing_parameter');
                let paramName = this.Filter.translate(item.name);
                this.Toast.error(`${text}: ${paramName}`);
                return false;
            }
        }

        return true;
    }
}
