import { Component } from '@angular/core';
import { RequestService } from '@proman/services/request.service';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { ACL } from '@proman/services/acl.service';
import { TableConfig } from '@proman/interfaces/object-interfaces';
import { safeMap } from "@proman/utils";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import {EntityEditDialogComponent} from "@master/dialogs/entity-edit-dialog.component";

@Component({
  selector: 'root-git-builds',
  template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [loadWhen]="tableConfig" [timeStamp]="tableTimestamp"></pro-table>
            </div>
        </div>
    `,
  styles: [`
        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class GitBuildsComponent {
  query: string;
  url: string;
  tableConfig: TableConfig;
  tableTimestamp: number;
  buildEntity: EntityInterface;
  tags: any[] = [];

  constructor(
    private Request: RequestService,
    private Entity: Entity,
    public ACL: ACL,
    private Dialog: MatLegacyDialog,
  ) {
    this.buildEntity = this.Entity.get('git_build');
    this.Entity.get('tag').search().then((response) => {
      this.tags = response;
      this.setTableConfig();
    })
  }

  setTableConfig() {
    this.tableConfig = {
      entity: 'git_build',
      aclRoot: null,
      tableId: 'git_builds',
      fields: [
        {
          name: 'build_id',
          key: 'tag',
        },
        {
          name: 'comment',
          key: 'comment',
        },
        {
          name: 'tags',
          key: 'tags',
          getValue: (row: any) => this.concatTags(row),
        },
      ],
      extraParameters: {
        stored: true,
        join: ['tags'],
        sort: { 'tag': 'desc' }
      },
      rowButtons: [
        {
          icon: 'tags',
          action: 'edit',
          acl: null,
          tooltip: 'manage_tags',
          callback: (row: any) => this.manageTags(row),
        },
        {
          icon: 'edit',
          action: 'edit',
          acl: null,
          tooltip: 'edit',
          callback: (row: any) => this.editBuildMetadata(row),
        },
      ]
    }
  }

  refresh = () => this.tableTimestamp = new Date().getTime();

  manageTags(row: any) {
    this.Dialog.open(EntityEditDialogComponent, {
      data: {
        entity: 'git_build',
        item: row,
        mainField: {
          key: 'tags',
          name: 'tags',
          type: 'select',
          config: { label: 'tags', multiple: true, options: this.tags, associations: true }
        }
      },
    }).afterClosed().subscribe(() => this.refresh());
  }

  concatTags = (row: any) => {
    return safeMap(row.tags, 'name').join(', ');
  }

  editBuildMetadata = (build: any) => {
    this.Dialog.open(EntityEditDialogComponent, {
      data: {
        entity: 'git_build',
        item: build,
        mainField: {
          key: 'comment',
          name: 'comment',
          type: 'text',
          config: { label: 'comment' },
        }
      }
    }).afterClosed().subscribe(() => this.refresh());
  }
}
