import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'root-tabs',
    template: `
        <div class="tabs">
            <ul>
                <li *ngFor="let tab of tabs; let $index = index;"
                    [ngClass]="{ 'is-active': activeTab == $index }"
                    (click)="handleClick($index)">
                    <a>{{ tab | translate }}</a>
                </li>
            </ul>
        </div>
    `,
    styles: [`
        .tabs ul, .hero .tabs ul {
            border-bottom-color: #dbdbdb;
            border-bottom-style: solid;
            border-bottom-width: 1px;
        }
    `]
})

export class TabsComponent implements OnInit {
    @Input() tabs: string [] = [];
    @Input() activeTab: number = 0;
    @Output() activeTabChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(

    ) {

    }

    ngOnInit() {

    }

    handleClick(index: number) {
        this.activeTab = index;
        this.activeTabChange.emit(this.activeTab);
    }

}
