import { EntityInterface } from '@proman/services/entity.service';
import { Money } from '@proman/interfaces/common.interface';
import { Invoice } from '@proman/interfaces/entity-interfaces';

export const invoice = {
    name: 'invoice',
    params: {
        entityConfig: {
            name: 'invoice',
                get: ['freeNumbers'],
                post: [
                    'updatePaymentStatus',
                    'regenerate',
                    'isPaid',
                    'proformaToInvoice',
                    'updateGeneralLedgerRecords',
                    'proformaPaid',
                    'copyAsCredit',
                    'updateInvoices',
                    'sendEmail',
                    'removeRelatedOrder',
                    'posCreate',
                    'sendDelayedInvoicesEmail',
                    'getCustomerDebt',
                    'setAllCustomersDebt',
                    'createPayout',
                    'createByDocument',
                ]
        },
        get: {
            join: [
                'records',
                'records.vat',
                'records.accountCategory',
                'ledgerRecords',
                'ledgerRecords.accountCategory',
                'carrier',
                'customer',
                'document',
                'document.template',
                'shipments',
],
            partialJoin: {
                'records.order': ['id'],
                'records.orderProduct': ['id', 'discountSum']
            },
            sort: { 'records.position': 'asc' }
        }
    }
};

export interface InvoiceEntityInterface extends EntityInterface<Invoice> {
    INVOICE: 'invoice';
    PROFORMA: 'proforma';

    sendEmail: (data: {
        id: number;
        document: number;
        template: number;
        emails: string[];
        subject: string;
    }) => Promise<{ data: { isSent: boolean }}>;

    updatePaymentStatus:
        (data: {
                  id: number;
                  paidAmountCrdt: Money|string;
                  paidAmountDbit: Money|string;
                  paidAt: string;
                  bankTransactionId?: number;
                  categoryDbitId?: number;
                  categoryCrdtId?: number;
                  typeCrdt?: string;
                  typeDbit?: string;
              }|{invoices: Array<{}>}) => Promise<void>;

    proformaToInvoice: (data: { id: number; template: number }) => Promise<void>;
    updateGeneralLedgerRecords: (data: { id: number }) => Promise<void>;
    isPaid: (data: { isPaid: boolean; id: number; paidAt: string}) => Promise<void>;
    updateInvoices: (data: { ids: number[] }) => Promise<void>;
    regenerate: (data: { template: number; id: number; order?: number }) => Promise<void>;
    copyAsCredit: (data: { template: number; id: number }) => Promise<void>;
    proformaPaid: (data: { id: number; date: string; trackingNumber: string }) => Promise<void>;
    freeNumbers: () => Promise<Array<{ number: string; date: string }>>;
    removeRelatedOrder: (data: { id: number; order: number }) => Promise<unknown>;
    sendDelayedInvoicesEmail: (data: { customerId: number; template: number }) => Promise<any>;
    getCustomerDebt: (data: { customerId: number }) => Promise<number>;
    setAllCustomersDebt: (data?: { date?: string }) => Promise<any[]>;
    createPayout: (data: {
        id: number;
        payoutSum?: number;
        date?: string;
        categoryDbitId?: number;
        categoryCrdtId?: number;
        typeCrdt?: string;
        typeDbit?: string;
    }) => Promise<any>;
    posCreate: (data: {
        id: any;
        customer: any;
        template: number,
        email: string,
        checkNumber: string,
        sendToAdministration: boolean,
    }) => Promise<unknown>;
    createByDocument: (data: { id: number, sum: Money, number: string, date: string, file: any }) => Promise<unknown>;
}
