import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Translation } from '../interfaces';
import { UploaderService } from '@proman/services/uploader.service';
import { TranslationEntityInterface } from '@proman/resources/translation';
import { ACL } from '@proman/services/acl.service';
import { ToastrService } from 'ngx-toastr';
import { Dialog } from '../services/dialog.service';
import { EntityCreateDialogComponent } from '../dialogs/entity-create-dialog.component';
import { ActionConfirmDialogComponent } from '../dialogs/action-confirm-dialog.component';
import { TranslationEditDialogComponent } from '../dialogs/translation-edit-dialog.component';

@Component({
    selector: 'root-languages',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container fl fl-column is-fullwidth">
                <pro-table [loadWhen]="tableConfigLanguages" [config]="tableConfigLanguages"
                            [timeStamp]="tableTimestamp"></pro-table>
                <pro-table [config]="tableConfigTranslations" [loadWhen]="tableConfigTranslations"
                            [timeStamp]="tableTimestamp"></pro-table>
            </div>
        </div>
    `,
    styles: [`
        .container {
            margin-top: 5em;
            margin-left: 1em;
            margin-right: 1em;
            max-width: fit-content;
        }

        .TranslateValueEditBtn {
            visibility: hidden;
        }

        td:hover > div > .TranslateValueEditBtn, th:hover > div > .TranslateValueEditBtn {
            visibility: visible;
        }

        .fl-row {
            white-space: normal;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LanguagesComponent implements OnInit {
    tableConfigLanguages: any;
    tableConfigTranslations: any;
    tableTimestamp: number;

    constructor(
        private Entity: Entity,
        private Uploader: UploaderService,
        private Toast: ToastrService,
        private cd: ChangeDetectorRef,
        private Dialog: Dialog,
        public ACL: ACL,
    ) {

    }

    ngOnInit() {
        this.loadLanguages();
        this.loadKeys();
    }

    refresh() {
        this.tableTimestamp = new Date().getTime();
        this.cd.markForCheck();
    }

    loadLanguages() {
        this.tableConfigLanguages = {
            entity: 'language',
            tableId: 'languages',
            aclRoot: 'translation',
            preventFixedHeader: true,
            addButton: null,
                // {
                // acl: 'translation.create',
                // callback: () => this.createLanguage(),
            // },
            fields: [
                {
                    name: 'language',
                    key: 'name',
                },
                {
                    name: 'code',
                    key: 'code',
                }
            ],
            rowButtons: [{
                icon: 'upload',
                action: 'edit',
                tooltip: 'Update all',
                callback: (row) => this.updateFromGit(row.code),
            }]
        };
    }

    loadKeys() {
        this.tableConfigTranslations = {
            entity: 'translation',
            tableId: 'translations',
            aclRoot: 'translation',
            preventFixedHeader: true,
            addButton: {
                acl: 'translation.create',
                callback: () => this.createKey(),
            },
            fields: [
                {
                    name: 'key',
                    key: 'trKey',
                },
                {
                    name: 'en',
                    key: 'en',
                },
                {
                    name: 'lt',
                    key: 'lt',
                },
                {
                    name: 'bg',
                    key: 'bg',
                },
                {
                    name: 'zh',
                    key: 'zh'
                },
                {
                    name: 'de',
                    key: 'de',
                },
                {
                    name: 'es',
                    key: 'es',
                },
                {
                    name: 'fr',
                    key: 'fr'
                },
                {
                    name: 'pl',
                    key: 'pl',
                },
                {
                    name: 'ru',
                    key: 'ru',
                },
                {
                    name: 'tr',
                    key: 'tr',
                },
                {
                    name: 'ua',
                    key: 'ua',
                },
                {
                    name: 'vn',
                    key: 'vn',
                }
            ],
            actionButtons: [
                {
                    label: 'remove_multiple',
                    acl: 'translation.remove',
                    action: 'remove',
                    theme: 'accent',
                    callback: (translations: Translation[]) => this.deleteMultipleTranslations(translations),
                }
            ],
            rowButtons: [
                {
                    icon: 'edit',
                    tooltip: 'edit_translation',
                    action: 'edit',
                    defaultRowAction: true,
                    callback: (row: Translation) => this.editTranslation(row),
                },
                {
                    icon: 'trash',
                    tooltip: 'delete_translation',
                    action: 'remove',
                    callback: (row: Translation) => this.deleteTranslation(row),
                }]
        };
    }

    createKey() {
        this.Dialog.open(EntityCreateDialogComponent, {
            mainField: {
                name: 'key',
                type: 'text',
                key: 'key',
                config: { label: 'key', required: true }
            },
            entity: 'translation',
        }).then(() => {
            this.refresh();
        })
    }

    updateFromGit = (value: any) => {
        (this.Entity.get('translation') as TranslationEntityInterface)
            .updateGit({ code: value }).then(() => {
                this.refresh();
                this.Toast.success('Done');
        });
    }

    importKey() {
        this.Uploader
            .init(() => {
            })
            .show({multiple: false, accept: '.json'})
            .then((files: any[]) => {

                const file = files[0];

                (this.Entity.get('translation') as TranslationEntityInterface)
                    .importKeys({file: file.id, auto_translate: true})
                    .then(() => {
                        this.refresh();
                    });

            });
    }

    editTranslation = (row: Translation) => {
        this.Dialog.open(TranslationEditDialogComponent, { translation: row })
            .then(() => this.refresh());
    };

    deleteTranslation(translation: Translation) {
        const text = `Remove key ${translation.trKey}?`;
        const callback = () => {
            this.Entity.get('translation')
                .remove({id: translation.id})
                .then(() => this.refresh());
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });
    }

    deleteMultipleTranslations(translations: Translation[]) {
        const text = `Remove ${translations.length} translations?`;
        const callback = () => {
            this.Entity.get('translation')
              .remove({ id: translations.map((t) => t.id) })
              .then(() => this.refresh());
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });
    }

}
