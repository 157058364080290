import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '@proman/services/request.service';
import { CONFIG } from '@proman/config';
import { MasterModalService } from '../services/master-modal.service';
import { Entity } from '@proman/services/entity.service';
import { ContainerEntityInterface } from '@proman/resources/container';
import { WebsocketService } from '@proman/services/websocket.service';
import { ACL } from '@proman/services/acl.service';
import { ActivatedRoute } from '@angular/router';
import { ServerEntityInterface } from '@proman/resources/server';
import { Dialog } from '../services/dialog.service';
import { ContainerUpdateDialogComponent } from '../dialogs/container-update-dialog.component';
import { ActionConfirmDialogComponent } from '../dialogs/action-confirm-dialog.component';
import { EntityCreateDialogComponent } from '../dialogs/entity-create-dialog.component';
import { EntityEditDialogComponent } from '../dialogs/entity-edit-dialog.component';
import { ContainerChangeDatabaseDialogComponent } from '../dialogs/container-change-database-dialog.component';
import { ContainerLogPreviewDialogComponent } from '../dialogs/container-log-preview-dialog.component';
import { TableConfig } from '@proman/interfaces/object-interfaces';
import { Container, Server } from "@proman/interfaces/entity-interfaces";

@Component({
    selector: 'root-containers',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [loadWhen]="tableConfig" [timeStamp]="tableTimestamp"></pro-table>
            </div>
        </div>
    `,
    styles: [`
        td {
            vertical-align: middle;
        }

        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class ContainersComponent implements OnInit, OnDestroy {
    query: string;
    url: string;
    serverId: number;
    servers: Server[];
    serverEntity: ServerEntityInterface;
    containerEntity: ContainerEntityInterface;
    tableConfig: TableConfig;
    tableTimestamp: number;
    containerStatusChannel: string = '/master/container/status';
    builds: any[];

    constructor(
        private Request: RequestService,
        private Modal: MasterModalService,
        private Entity: Entity,
        private WS: WebsocketService,
        public ACL: ACL,
        private Route: ActivatedRoute,
        private Dialog: Dialog,
    ) {
        this.url = CONFIG.api;

        this.serverEntity = this.Entity.get('serv');
        this.containerEntity = this.Entity.get('container');
        this.getBuilds().then(() => {
            this.setTableConfig();
        });
        this.loadServers();
    }

    ngOnInit() {
        this.subMqtt();
        this.serverId = this.Route.snapshot.queryParams.server;
    }

    ngOnDestroy() {
        this.unsubMqtt();
    }

    async setTableConfig() {
        this.tableConfig = {
            entity: 'container',
            aclRoot: 'container',
            tableId: 'containers',
            addButton: {
                acl: 'container.create',
                callback: () => this.addContainer(),
            },
            fields: [
                {
                    name: 'Container ID',
                    key: 'id',
                },
                {
                    name: 'Namespace',
                    key: 'namespace',
                },
                {
                    name: 'Domain',
                    key: 'domain',
                },
                {
                    name: 'Version',
                    key: 'version',
                },
                {
                    name: 'Status',
                    key: 'status',
                },
                {
                    name: 'Server name',
                    key: 'rrServer.name',
                },
                {
                    name: 'Update version',
                    key: 'updateVersion',
                    formatter: 'directive',
                    formatterConfig: 'root-table-cell-select',
                    _config: { options: this.builds },
                    getValue: (row: any) => {
                        return row.updateVersion;
                    },
                    callback: (value: any, value2: any) => {
                        this.containerEntity.update({ id: value.id, updateVersion: value2 });
                    },
                    hidden: true,
                },
                {
                    name: 'Email',
                    key: 'email',
                },
                {
                    name: 'Phone',
                    key: 'phoneNumber',
                },
                {
                    name: 'Created At',
                    key: 'createdAt',
                    formatter: 'dateTime',
                    formatterConfig: '_datetime_js',
                    filter: { type: 'date' },
                }
            ],
            extraParameters: {
                'rrServer.id': this.serverId,
                join: ['rrServer'],
            },
            preventFixedHeader: true,
            rowButtons: [
                {
                    icon: 'sync',
                    action: 'edit',
                    tooltip: 'Update container',
                    callback: (row: Container) => this.updateContainer(row),
                },
                {
                    icon: 'edit',
                    action: 'edit',
                    tooltip: 'Edit container',
                    callback: (row: Container) => this.editContainer(row),
                },
                {
                    icon: 'clipboard-list',
                    action: 'view',
                    tooltip: 'Container log',
                    callback: (row: Container) => this.containerLog(row),
                },
                {
                    icon: 'database',
                    action: 'edit',
                    tooltip: 'Change database',
                    show: (row: Container) => row.allowUpdateDb,
                    callback: (row: Container) => this.updateDatabase(row),
                },
                {
                    icon: 'chevron-right',
                    action: 'view',
                    tooltip: 'Go to',
                    // show: (row: Container) => false,
                    // defaultRowAction: true,
                    callback: (row: Container) => this.goTo(row),
                }
            ]
        }
    }

    refresh = () => this.tableTimestamp = new Date().getTime();

    isTestContainer = (container: Container) => container.domain.includes('test-');

    test() {
        this.Request.get(this.query)
            .then((response: any) => {
                console.log('response', response);
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    loadServers() {
        this.serverEntity.search({ join: ['containers'] })
            .then((response) => {
                this.servers = response;
            });
    }

    addContainer() {
        this.Dialog.open(EntityCreateDialogComponent, {
            header: 'New container',
            mainField: {
                key: 'domain',
                type: 'text',
                config: { required: true, label: 'Domain' }
            },
            parameters: [
                {
                    key: 'server',
                    type: 'select',
                    config: { label: 'Server', options: this.servers, required: true },
                },
                {
                    key: 'email',
                    type: 'text',
                    config: { label: 'Email', validators: { email: true } },
                },
                {
                    key: 'database',
                    type: 'text',
                    config: { label: 'database', required: true },
                },
                {
                    key: 'allowUpdateDb',
                    type: 'boolean',
                    config: { label: 'Allow database update' },
                },
                {
                    key: 'checkHealth',
                    type: 'boolean',
                    config: { label: 'Check health' },
                },
                {
                    key: 'isCorporate',
                    type: 'boolean',
                    config: { label: 'Corporate' },
                },
                {
                    key: 'mqttLocal',
                    type: 'boolean',
                    config: { label: 'Local MQTT' },
                },
                {
                    key: 'mysqlLocal',
                    type: 'boolean',
                    config: { label: 'Local MySQL'},
                },
                {
                    key: 'redisLocal',
                    type: 'boolean',
                    config: { label: 'Local Redis'},
                },
                {
                    key: 'useMultiNamespace',
                    type: 'boolean',
                    config: { label: 'Use multinamespace' },
                }
            ],
        }).then(() => this.loadServers());
    }

    editContainer(container: Container) {
        this.Dialog.open(EntityEditDialogComponent, {
            header: 'New container',
            item: container,
            mainField: {
                key: 'domain',
                type: 'text',
                config: { required: true, label: 'Domain' }
            },
            parameters: [
                {
                    key: 'rrServer',
                    type: 'select',
                    config: { label: 'Server', options: this.servers, required: true },
                },
                {
                    key: 'email',
                    type: 'text',
                    config: { label: 'Email', validators: { email: true } },
                },
                {
                    key: 'database',
                    type: 'text',
                    config: { label: 'database', required: true },
                },
                {
                    key: 'allowUpdateDb',
                    type: 'boolean',
                    config: { label: 'Allow database update' },
                },
                {
                    key: 'checkHealth',
                    type: 'boolean',
                    config: { label: 'Check health' },
                },
                {
                    key: 'isCorporate',
                    type: 'boolean',
                    config: { label: 'Corporate' },
                },
                {
                    key: 'mqttLocal',
                    type: 'boolean',
                    config: { label: 'Local MQTT' },
                },
                {
                    key: 'mysqlLocal',
                    type: 'boolean',
                    config: { label: 'Local MySQL'},
                },
                {
                    key: 'redisLocal',
                    type: 'boolean',
                    config: { label: 'Local Redis'},
                },
                {
                    key: 'useMultiNamespace',
                    type: 'boolean',
                    config: { label: 'Use multinamespace' },
                }
            ],
        }).then(() => this.loadServers());
    }

    updateContainer(container: Container) {
        this.Dialog.open(ContainerUpdateDialogComponent, { container }).then(() => this.refresh());
    }

    removeContainer(container: Container) {
        const text = `Remove ${container.domain}?`;
        const callback = () => {
            (this.Entity.get('container') as ContainerEntityInterface)
                .remove({ id: container.id })
                .then(() => this.loadServers());
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });

    }

    downloadDatabase(container: Container) {
        console.log('downloadDatabase', container);
    }

    uploadDatabase(container: Container) {
        console.log('uploadDatabase', container);
    }

    subMqtt() {
        this.WS.subscribeChannel('/master/container/status', (data: { container: string, status: string }) => {

            this.servers.forEach((server) => {
                server.containers.forEach((container) => {
                    if (container.domain === data.container) container.status = data.status;
                });
            });

        });

        this.WS.subscribeChannel('/master/container/version', (data: {
            container: string;
            version: string;
            status: string;
            date: string;
        }) => {
            this.servers.forEach((server) => {
                server.containers.forEach((container) => {
                    if (container.domain === data.container) {
                        container.version = data.version;
                        container.status = data.status;
                        container.updatedAt = data.date;
                    }
                });
            });
        });
    }

    unsubMqtt() {
        this.WS.unsubscribeChannel('/master/container/status');
    }

    updateDatabase(container: Container) {
        const title = `Change ${container.namespace} database?`;
        this.Dialog.open(ContainerChangeDatabaseDialogComponent, { container, title });
    }

    updateContainerData(container: Container, property: string, value: any) {
        this.Entity.get('container')
            .update({ id: container.id, [property]: value })
            .then(() => container[property] = value);
    }

    containerLog = (container: Container) => {
        this.containerEntity.containerLog({ id: container.id}).then((response: string) => {
            let parsedResponse = response.split(/\r?\n/);
            console.log(parsedResponse);
            this.Dialog.open(ContainerLogPreviewDialogComponent, { text: parsedResponse });
        });
    }

    goTo = (container: Container) => {
        let url = `https://${container.namespace}.${container.domain}`;
        window.location.href = url;
    }

    async getBuilds() {
        await this.Entity.get('git_build').search({ stored: true, sort: { 'tag': 'desc' }, limit: 100  }).then((res: any[]) => {
            res = res.map((val) => val.tag);
            res.unshift('latest');
            this.builds = res;
            return res;
        })
    }
}
