import { Component, OnInit } from '@angular/core';
import { ACL } from '@proman/services/acl.service';
import { TableConfig } from '@proman/interfaces/object-interfaces';
import { Entity } from "@proman/services/entity.service";
import { fiscal, FiscalEntityInterface } from '@proman/resources/fiscal';
import { Dialog } from '../services/dialog.service';
import { Fiscal } from '@proman/interfaces/entity-interfaces';
import { FiscalDialogComponent } from "@master/dialogs/fiscal-dialog.component";
import { ActionConfirmDialogComponent } from "@master/dialogs/action-confirm-dialog.component";
import { ToastService } from '@proman/services/toast.service';
import { isObject } from '@proman/utils';
import { FilterService } from '@proman/services/filter.service';

@Component({
    selector: 'root-fiscals',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [timeStamp]="tableTimestamp" [loadWhen]="tableConfig"></pro-table>
            </div>
        </div>
    `,
    styles: [`
        td {
            vertical-align: middle;
        }

        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class FiscalsComponent implements OnInit {
    query: string;
    fiscalEntity: FiscalEntityInterface;
    tableConfig: TableConfig;
    tableTimestamp: number;

    constructor(
      private Entity: Entity,
      private Dialog: Dialog,
      private Filter: FilterService,
      public ACL: ACL,
      private Toast: ToastService
    ) {
        this.fiscalEntity = this.Entity.get('fiscal');
    }

    ngOnInit() {
      this.setTableConfig()
    }

    refresh = () => this.tableTimestamp = new Date().getTime();

    setTableConfig() {
        this.tableConfig = {
            entity: 'fiscal',
            aclRoot: 'fiscal',
            tableId: 'fiscal',
            addButton: {
                acl: 'fiscal.create',
                callback: () => this.create(),
            },
            fields: [
                {
                    name: 'fiscal_id',
                    key: 'id',
                },
                {
                    name: 'name',
                    key: 'name',
                },
                {
                    name: 'registration_number',
                    key: 'cashRegisterRegistrationNo',
                },
                {
                    name: 'cash_register_owner_name',
                    key: 'cashRegisterOwnerName',
                },
                {
                    name: 'status',
                    key: 'status',
                    translate: true,
                    filter: {
                        type: 'dropdown_multi',
                        options: fiscal.params.entityConfig.statuses.map((str) => {
                            return { id: str, name: this.Filter.translate(str) };
                        }),
                    }
                },
                {
                    name: 'owner_type',
                    key: 'cashRegisterOwnerType',
                    translate: true,
                    filter: {
                        type: 'dropdown_multi',
                        options: fiscal.params.entityConfig.ownerTypes.map((str) => {
                            return { id: str, name: this.Filter.translate(str) };
                        }),
                    }
                },
                {
                    name: 'place_type',
                    key: 'cashRegisterPlaceType',
                    translate: true,
                    filter: {
                        type: 'dropdown_multi',
                        options: fiscal.params.entityConfig.placeTypes.map((str) => {
                            return { id: str, name: this.Filter.translate(str) };
                        }),
                    }
                },
                {
                    name: 'purpose',
                    key: 'cashRegisterPurpose',
                    translate: true,
                    filter: {
                        type: 'dropdown_multi',
                        options: fiscal.params.entityConfig.purposes.map((str) => {
                            return { id: str, name: this.Filter.translate(str) };
                        }),
                    }
                },
                {
                    name: 'timezone',
                    key: 'timezone',
                }
            ],
            extraParameters: {},
            preventFixedHeader: true,
            rowButtons: [
                {
                    icon: 'edit',
                    theme: 'accent',
                    acl: null,
                    tooltip: 'edit',
                    show: (row: Fiscal) => row.status === 'created' || row.status === 'registered_certificate' || row.status === 'registered_cr' || row.status === 'suspended',
                    callback: (row: Fiscal) => this.editFiscal(row),
                },
                {
                    icon: 'edit',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'edit',
                    show: (row: Fiscal) => row.status !== 'created' && row.status !== 'registered_certificate' && row.status !== 'registered_cr' && row.status !== 'suspended' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: 'arrow-right-to-bracket',
                    theme: 'accent',
                    acl: null,
                    tooltip: 'register_cr',
                    show: (row: Fiscal) => row.status === 'created',
                    callback: (row: Fiscal) => this.registerCr(row),
                },
                {
                    icon: 'arrow-right-to-bracket',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'register_cr',
                    show: (row: Fiscal) => row.status !== 'created' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: 'right-to-bracket',
                    theme: 'accent',
                    acl: null,
                    tooltip: 'register_cr_certificate',
                    show: (row: Fiscal) => row.status === 'registered_cr',
                    callback: (row: Fiscal) => this.registerCrCertificate(row),
                },
                {
                    icon: 'right-to-bracket',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'register_cr_certificate',
                    show: (row: Fiscal) => row.status !== 'registered_cr' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: 'square-plus',
                    theme: 'accent',
                    acl: null,
                    tooltip: 'create_container',
                    show: (row: Fiscal) => row.status === 'registered_certificate',
                    callback: (row: Fiscal) => this.createCrContainer(row),
                },
                {
                    icon: 'square-plus',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'create_container',
                    show: (row: Fiscal) => row.status !== 'registered_certificate' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: 'pause',
                    theme: 'accent',
                    acl: null,
                    tooltip: 'suspend',
                    show: (row: Fiscal) => row.status === 'active',
                    callback: (row: Fiscal) => this.suspend(row),
                },
                {
                    icon: 'pause',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'suspend',
                    show: (row: Fiscal) => row.status !== 'active' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: 'times',
                    theme: 'warn',
                    acl: null,
                    tooltip: 'deregister',
                    show: (row: Fiscal) => row.status === 'suspended',
                    callback: (row: Fiscal) => this.deregister(row),
                },
                {
                    icon: 'times',
                    theme: 'primary',
                    acl: null,
                    tooltip: 'deregister',
                    show: (row: Fiscal) => row.status !== 'suspended' && row.status !== 'draft',
                    callback: () => {
                    },
                },
                {
                    icon: '',
                    theme: 'primary',
                    acl: null,
                    defaultRowAction: true,
                    show: () => false,
                    callback: (row: Fiscal) => this.goTo(row),
                },
                {
                    theme: 'accent',
                    icon: 'arrows-rotate',
                    acl: null,
                    tooltip: 'update_certificate',
                    show: (row: Fiscal) => row.status === 'suspended',
                    callback: (row: Fiscal) => this.updateCertificate(row),
                },
                {
                    theme: 'primary',
                    icon: 'arrows-rotate',
                    acl: null,
                    tooltip: 'update_certificate',
                    show: (row: Fiscal) => row.status !== 'suspended' && row.status !== 'draft',
                    callback: () => {},
                },
                {
                    theme: 'accent',
                    icon: 'arrow-up-right',
                    acl: null,
                    tooltip: 'activate_cr',
                    show: (row: Fiscal) => row.status === 'suspended',
                    callback: (row: Fiscal) => this.activateCr(row),
                },
                {
                    theme: 'primary',
                    icon: 'arrow-up-right',
                    acl: null,
                    tooltip: 'activate_cr',
                    show: (row: Fiscal) => row.status !== 'suspended' && row.status !== 'draft',
                    callback: () => {},
                },
                {
                    theme: 'accent',
                    icon: 'thumbs-up',
                    acl: null,
                    tooltip: 'confirm_draft',
                    show: (row: Fiscal) => row.status === 'draft',
                    callback: () => {},
                },
            ]
        }
    }

    create() {
        this.fiscalEntity.getServiceOrganization().then((response) => {
            if (response.status === 'SUCCESS') {
                const [cashRegisterModels, softwareVersions, hardwareVersions] = this.parseBackendVMIResponse(response);

                this.Dialog.open(FiscalDialogComponent, {
                    cashRegisterModels,
                    certifiedEmployees: response.certifiedEmployees.employee,
                    hardwareVersions,
                    softwareVersions,
                }).then(() => this.refresh());
            }
        })
    }

    parseBackendVMIResponse = (response) => {
        const cashRegisterModels = [];
        const hardwareVersions = [];
        const softwareVersions = [];

        response.servicedCashRegisterModels.servicedCashRegisterModel.forEach((model) => {
            model.modelFullName = model.cashRegisterModel.modelFullName;
            cashRegisterModels.push(model);
            const versionH = model.cashRegisterModel.cashRegisterHardwareVersions.version;
            if (isObject(versionH)) {
                hardwareVersions.push(versionH);
            } else {
                versionH.forEach((m) => {
                    hardwareVersions.push(m);
                })
            }

            const versionS = model.cashRegisterModel.cashRegisterSoftwareVersions.version;
            if (isObject(versionS)) {
                softwareVersions.push(versionS);
            } else {
                versionS.forEach((m) => {
                    softwareVersions.push(m);
                })
            }
        });

        return [cashRegisterModels, softwareVersions, hardwareVersions];
    };

    registerCr = (row: Fiscal) => {
      this.Toast.pop('info', 'registration_cr');
      this.fiscalEntity.registerCr({ id: row.id }).then((resp) => {
          if (resp.status==='ERROR'){
              this.Toast.pop('error', resp.error.errorCode);
              this.refresh()
              return
          }
         this.Toast.pop('success', 'registration_cr_success')
         this.refresh()
      });
    }

    createCrContainer = (row: Fiscal) => {
      this.Toast.pop('info', 'creating_cr_container');
      this.fiscalEntity.createCrContainer({ id: row.id }).then((resp) => {
          if (resp.status==='ERROR'){
              this.Toast.pop('error', resp.error.errorCode)
              this.refresh()
              return
          }
          this.Toast.pop('success', 'creating_cr_container_success')
          this.refresh()
      })
    }

    registerCrCertificate = (row: Fiscal) => {
      this.Toast.pop('info', 'registration_cr_certificate');
      this.fiscalEntity.registerCrCertificate({ id: row.id }).then((resp) => {
          if (resp.status==='ERROR'){
              this.Toast.pop('error', resp.error.errorCode)
              this.refresh()
              return
          }
          this.Toast.pop('success', 'registration_cr_certificate_success')
          this.refresh()
      });
    }

    editFiscal = (row: Fiscal) => {
        this.fiscalEntity.getServiceOrganization().then((response) => {
            if (response.status === 'SUCCESS') {
                const [cashRegisterModels, softwareVersions, hardwareVersions] = this.parseBackendVMIResponse(response);

                this.Dialog.open(FiscalDialogComponent, {
                    fiscal: row,
                    cashRegisterModels,
                    certifiedEmployees: response.certifiedEmployees.employee,
                    hardwareVersions,
                    softwareVersions
                }).then(() => this.refresh());
            }
        });
    };

    suspend = (row: Fiscal) => {
      this.fiscalEntity.suspend({ id: row.id }).then(() => this.refresh());
    }

    deregister = (row: Fiscal) => {
      this.Dialog.open(ActionConfirmDialogComponent, { text: 'this_will_deregister_this_fiscal' }).then((response) => {
        if (response === 1) {
          this.fiscalEntity.deregister({ id: row.id }).then(() => this.refresh());
        }
      })
    }

    goTo = (row: Fiscal) => window.location.href = `https://${row.cashRegisterRegistrationNo}.fiscal.lt`;

    updateCertificate = (row: Fiscal) => {
      this.fiscalEntity.updateCertificate({ id: row.id}).then(() => this.refresh());
    }

    activateCr = (row: Fiscal) => {
      this.fiscalEntity.activateCr({ id: row.id }).then(() => this.refresh());
    }
}
