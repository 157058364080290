import { Component } from '@angular/core';
import { ObservablesService } from '@proman/services/observables.service';
import { AppInitService } from '@proman/services/app-init.service';
import { LanguageConfigService } from '@proman/services/language-config.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>

        <root-modal></root-modal>
        <root-update-spinner *ngIf="isUpdate"></root-update-spinner>
    `,
    styles: [`

    `]
})
export class AppComponent {
    isUpdate: boolean = false;

    constructor(
        private Observables: ObservablesService,
        private AppInit: AppInitService,
        private LanguageConfig: LanguageConfigService,
    ) {
        this.LanguageConfig.set(this.AppInit.currentLang);
        this.Observables.isUpdateVisible$
            .subscribe((value) => this.isUpdate = value);
    }

}
