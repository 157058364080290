import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { CONFIG } from '@proman/config';
import { RequestService } from '@proman/services/request.service';

@Injectable({ providedIn: 'root' })
export class TalkRoomService {
  currUser: CurrUser;
  namespace: string;


  constructor(
    private Request: RequestService,
    private store: Store,
  ) {
    this.store.select(getPublicSystemOptions).subscribe((value) => {
      this.namespace = value.namespace ?? CONFIG.namespace;
    });

    this.store.select(getCurrUser)
      .subscribe((value) => this.currUser = value);
  }

  async getOffer(roomData: any) {
    return await this.Request.get('https://master.proman.lt/api/' + 'public/chat_room/get_offer?id=' + roomData.id);
  }

  createRoom(offer: any) {
    const person = this.currUser.id;
    console.log('offer: ', offer);
    return this.Request.post('https://master.proman.lt/api/' + 'public/chat_room/make_call', { offer, person, namespace: this.namespace });
  }

  joinRoom(answer: any, person: number, namespace: string) {
    return this.Request.post('https://master.proman.lt/api/' + 'public/chat_room/accept_call', { answer, person, namespace, masterId: this.currUser.id });
  }

}
