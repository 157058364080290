import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    ViewChild, ElementRef, OnDestroy, Inject
} from '@angular/core';
import { Entity, EntityInterface } from '../../services/entity.service';
import { TableActionsParserService } from '../services/table-actions-parser.service';
import { TableCacheService } from '../services/table-cache.service';
import { TableHelperService } from '../services/table-helper.service';
import { FilterService } from '../../services/filter.service';
import moment from 'moment';
import {
    findByProperty,
    isDefined,
    isDefinedNotNull,
    isNumber,
    roundNumber,
    utcFormat,
    uniq, isNull, sequentialRequestsWithCancel, cloneDeep
} from '../../utils';
import { getFieldValue } from '../utils/tools';

import { TableSettingsDialogComponent } from './table-settings-dialog.component';
import { resourcesConfig } from '../../resources';
import { Location } from '@angular/common';
import { Search } from '../../services/search.service';
import { RequestService } from '../../services/request.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
    UiPreferencesService,
    UI_TABLE_PAGINATION_TOP,
    UI_TABLE_SAVED_FILTERS
} from '../../services/ui-preferences.service';
import { DynamicFieldsService } from '../../services/dynamic-fields.service';
import {
    CancelableSequentialRequest,
    PaginatedData,
    TableButton,
    TableConfig,
    TableField,
    TableFilter
} from '../../interfaces/object-interfaces';
import { TableParametersFormatterService } from '../services/table-parameters-formatter.service';
import { BehaviorSubject, Subject, takeUntil } from '../../rxjs-common';
import { Currency, SystemOptions } from '../../interfaces/entity-interfaces';
import { QueryExpressionService } from '../../services/query-expression.service';
import { ACL } from '../../services/acl.service';
import { CurrenciesService } from '../../services/currencies.service';
import { Store } from '@ngrx/store';
import { getDecimalPlaces, getSystemOptions } from '../../store/system-options';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { TABLE_MODULE_CONFIGURATION, TABLE_STATE_PARSER } from '../constants';
import { TableModuleConfiguration, TableStateParser } from '../types';
import { EntityRepositionDialogComponent } from '../../shared-dialogs/dialogs/entity-reposition-dialog.component';
import { Money } from '../../interfaces/common.interface';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';


const DEFAULT_VIEW_LIMIT = 50;

declare interface TableObject {
    _selected: boolean;
    [key: string]: string|object|boolean;
}

@Component({
    selector: 'pro-table',
    template: `
        <div class="EntityTable"
             [ngClass]="{ 'with-multiselect': isMultiselect, 'isLineNumbering': isLineNumbering, 'with-rowButtons': rowActions.length }"
             #element
             [attr.data-table]="config?.tableId"
             [attr.data-table-acl-root]="config?.aclRoot"
        >

            <!--  HEADER  -->
            <div fxLayout="column" *ngIf="config" class="SidePadding-16 EntityTable-Actions">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="RightPadding">
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <pro-label *ngIf="config.header">
                            <ng-container [ngSwitch]="!!config?.headerState">
                                <ng-container *ngSwitchCase="true">
                                    <a (click)="handleHeaderLinkClick($event)" class="Clickable">
                                        {{ config.header | translate }}
                                    </a>
                                </ng-container>
                                <ng-container *ngSwitchCase="false">
                                    {{ config.header | translate }}
                                </ng-container>
                            </ng-container>
                        </pro-label>
                        <pro-btn *ngIf="addButton"
                                [ngClass]="{ hidden: !addButton.show }"
                                class="AddButton"
                                (onClick)="addButton.callback($event)"
                                icon="plus"
                                size="1p3x"
                                theme="accent"
                                [tooltip]="'add'"
                        ></pro-btn>
                        <ng-container *ngFor="let button of topButtons">
                            <pro-btn [ngClass]="{ hidden: !button.show }"
                                     (onClick)="button.callback()"
                                     [icon]="button.icon"
                                     [faClass]="button.faClass || 'far'"
                                     [tooltip]="button.tooltip | translate"
                                     [label]="(button.label || button.title) | translate"
                                     [theme]="button.theme || 'accent'"
                                     [pattern]="button.pattern || button.template"></pro-btn>
                        </ng-container>
                        <ng-container *ngFor="let filter of config.customFilters">
                            <div class="Table-customFilters"
                                 *ngIf="filter.isSelected"
                                 (click)="toggleCustomFilter(null, filter)">
                                <pro-btn [label]="filter.name | translate"></pro-btn>
                                <pro-btn icon="times"
                                        [tooltip]="'delete_filter'"
                                        theme="warn"></pro-btn>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isSpecialisationFilter && canEditFilter">
                            <pro-btn icon="filter"
                                    [tooltip]="'filter'"
                                    theme="warn"
                                    (onClick)="removeSpecialisationFilter()"
                            ></pro-btn>
                        </ng-container>

                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <ng-container *ngIf="config.quickFilters?.length">
                            <pro-btn *ngFor="let filter of config.quickFilters"
                                    [label]="filter.name"
                                    (onClick)="handleQuickFilter(filter)"
                                ></pro-btn>
                        </ng-container>
                        <pro-pagination *ngIf="!dataSource && isTopPagination && payload && !config?.useListNotSearch"
                                        [total]="payload.total"
                                        [limit]="limit"
                                        [currPage]="currPage"
                                        [simple]="true"
                                        class="topPagination"
                                        (onSelect)="setPage($event)"></pro-pagination>
                        <pro-btn *ngIf="config.advancedSearch"
                                (onClick)="onAdvancedSearch.emit()"
                                icon="search"
                                [tooltip]="'search'"></pro-btn>
                        <pro-btn (onClick)="clearAllFilters()"
                                 icon="filter-slash" theme="accent"
                                 [disabled]="checkFilterStatus()"
                                 [tooltip]="'clear_all_filters'"></pro-btn>
                        <pro-btn *ngIf="config.customFilters?.length"
                                [matMenuTriggerFor]="customFilterMenu"
                                icon="filter"
                                [tooltip]="'filter'"></pro-btn>
                        <pro-btn *ngIf="onChart.observers.length"
                                [theme]="isChart ? 'accent' : 'grey'"
                                (onClick)="handleChartButton()"
                                icon="chart-bar"
                                [tooltip]="'see_chart'"></pro-btn>
                        <mat-menu #customFilterMenu="matMenu">
                            <ng-container *ngFor="let filter of config.customFilters; let $index = index;">
                                <button mat-menu-item (click)="toggleCustomFilter($index, filter)">
                                    <span [ngClass]="{ 'isActive' : filter.isSelected }">{{ filter.name | translate }}</span>
                                </button>
                            </ng-container>
                        </mat-menu>

                        <pro-btn *ngIf="config.enableReposition"
                                icon="bars-sort"
                                [tooltip]="'sort'"
                                (onClick)="repositionItems()"></pro-btn>

                        <pro-btn *ngIf="!config.hideSettings"
                                icon="cog"
                                [tooltip]="'settings'"
                                [ngClass]="{ 'DisplayNone': config?.hideIfEmpty && payload?.data?.length === 0  }"
                                (onClick)="editFields()"></pro-btn>
                    </div>
                </div>
            </div>

            <div class="EntityTable-rowsActionBtns" fxLayout="row" fxLayoutAlign="start center" *ngIf="isMultiselect">
                <div class="EntityTable-rowsActionBtns--info">
                    {{ 'selected' | translate }} {{ selectedRowsCount }} {{ 'elements' | translate }}
                </div>
                <pro-btn *ngFor="let item of actionButtons"
                        (onClick)="item.callback(selectedRows)"
                        [label]="item.label | translate"
                        [disabled]="!selectedRowsCount || !item.show()"
                ></pro-btn>
                <div fxFlex></div>
                <pro-btn *ngIf="!!selectedRowsCount" [label]="'cancel' | translate" theme="accent" (onClick)="resetSelectedRows()"></pro-btn>
            </div>

            <!--  HEADER END -->

<!--            <div class="Buffer-container">-->
<!--                <mat-progress-bar *ngIf="isLoading$ | async" mode="buffer" color="accent"></mat-progress-bar>-->
<!--            </div>-->

            <div class="TableContainer"
                 proShiftScroll
                 proScrollLimit [(scrollLimit)]="viewLimit"
            >
                <table class="Table stripped"
                       [proFixedHeader]="(!(config && config.preventFixedHeader))"
                       [timeStamp]="timeStamp"
                       [ngClass]="{ 'DisplayNone': config?.hideIfEmpty && payload?.data?.length === 0  }">

                    <!--  COLUMN FILTERS MARKERS  -->

                    <colgroup>
                        <col *ngIf="isMultiselect">
                        <col *ngIf="isLineNumbering">
                        <col *ngFor="let column of columns" [ngStyle]="columnStyleData[column.key] || {}">
                        <col class="Table-RowButtons">&nbsp;
                    </colgroup>

                    <!--  COLUMN FILTERS MARKERS END  -->

                    <!--  TABLE HEADER  -->

                    <thead>
                    <tr>
                        <th class="EntityTable-multiselectcheckbox"></th>
                        <th class="EntityTable-lineNumber">{{ 'line_number' | translate }}</th>
                        @for (column of columns; track $index) {
                            <th (click)="sortBy(column, $event)"
                                [ngClass]="{ 'is-sortable': isSortable(column) }"
                                class="{{ isSortedBy(column) }}">
                                <div fxLayout="row"
                                     fxLayoutAlign="start center"
                                     [proTooltip]="column.tooltip"
                                     class="has-tooltip-left">
                                    <div>
                                        <fa class="EntityTable-sortDesc"
                                            name="angle-down"></fa>
                                        <fa class="EntityTable-sortAsc"
                                            name="angle-up"></fa>
                                    </div>
                                    {{ column.name | translate }}
                                </div>

                            </th>
                        }
                        <th class="Table-RowButtons">&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr  class="Table-Filters">
                        <td class="EntityTable-multiselectcheckbox">
                            <pro-checkbox [value]="allRowsSelected"
                                         (onChange)="selectAllRows($event)"
                                         [config]="{  }"
                                         proClickStopPropagation></pro-checkbox>
                        </td>
                        <td class="EntityTable-lineNumber"></td>
                        @for (column of columns; track $index) {
                            <td>
                                <div fxLayout="row" fxLayoutAlign="start center" class="TableFilter" [attr.data-filter]="column.key">
                                    @if (column.formatter !== 'image' && column.filter &&
                                    column.filter.type !== 'date' &&
                                    column.filter.type !== 'dropdown' &&
                                    column.filter.type !== 'dropdown_multi' &&
                                    column.filter.type !== 'autocomplete') {
                                        <pro-text-simple [value]="column.filter.value"
                                                         [config]="{ label: 'value' }"
                                                         [disabled]="column.filter.disabled"
                                                         (onChange)="setFilter(column, $event)"></pro-text-simple>
                                    }
                                    @if (column.filter?.type === 'dropdown' || column.filter?.type === 'dropdown_multi') {
                                        <pro-select class="MinWidth75"
                                                    [value]="column.filter.value"
                                                    [config]="{ label: 'value', key: column.filter.key, disableSearch: !column.filter.showSearch, multiple: column.filter.type === 'dropdown_multi' }"
                                                    [options]="column.filter.options"
                                                    [disabled]="column.filter.disabled"
                                                    (onChange)="setFilter(column, $event)"></pro-select>
                                    }

                                    @if (column.filter?.type === 'date') {
                                        <pro-table-filter-date [value]="column.filter.value"
                                                               [showTime]="column.showTime"
                                                               [min]="column.filter?.min"
                                                               [max]="column.filter?.max"
                                                               (onChange)="setFilter(column, $event)"></pro-table-filter-date>
                                    }

                                    @if (column.filter?.type === 'autocomplete') {
                                        <pro-table-filter-autocomplete [value]="column.filter.value"
                                                                       [config]="{ entity: column.filter.entity }"
                                                                       (onChange)="setFilter(column, $event)"></pro-table-filter-autocomplete>
                                    }

                                    @if (isFilterValue(column.filter?.value) || column.filterValue) {
                                        <pro-btn (onClick)="setFilter(column, null)"
                                                 icon="times"
                                                 [tooltip]="'delete_filter'"
                                                 theme="warn"
                                                 class="TableFilter-closeButton"></pro-btn>
                                    }
                                </div>
                            </td>
                        }
                        <td class="Table-RowButtons">&nbsp;</td>
                    </tr>

                    <!--  TABLE HEADER END -->

                    <!--  TABLE DATA -->
                    <ng-container *proFor="let row of payload?.data; let rowIndex = index; let $first = first;offset: 20;renderInterval: 25; renderIterator: 10;">

<!--                    <ng-container *ngFor="let row of payload?.data; let rowIndex = index; let $first = first;">-->
                        <ng-container *ngIf="rowIndex <= viewLimit && !!row">
                        <tr (click)="handleRowClick(row, $event)"
                            [ngClass]="[rowClass[rowIndex] || '']"
                            [attr.data-name]="'tableLine-' + rowIndex"
                        >
                            <td class="EntityTable-multiselectcheckbox" (click)="setRowSelected(row, !row._selected, rowIndex);$event.stopPropagation();">
                                <pro-checkbox [value]="row._selected"
                                             (onChange)="setRowSelected(row, $event, rowIndex)"
                                             proClickStopPropagation></pro-checkbox> </td>
                            <td class="EntityTable-lineNumber">{{ (rowIndex + 1) }}</td>
                            <td *ngFor="let column of columns">
                                <pro-table-cell [row]="row" [column]="column" [rowIndex]="rowIndex" [attr.data-name]="'cell-' + column.key"></pro-table-cell>
                            </td>
                            <td class="Table-RowButtons"
                                proClickStopPropagation>
                                <ng-container *ngFor="let button of rowActions">
                                    <pro-btn *ngIf="button.show(row)"
                                            [theme]="button.theme"
                                            [faClass]="button.faClass"
                                            [icon]="button.icon"
                                            [tooltip]="button.tooltip | translate"
                                            [tooltipPosition]="'left'"
                                            (onClick)="handleRowAction($event, button, row)"></pro-btn>
                                </ng-container>

                            </td>
                        </tr>

                        <tr class="EntityTable-subtotal-row" *ngIf="isSubtotalRow(rowIndex)">
                            <td class="EntityTable-multiselectcheckbox">
                            <td class="EntityTable-lineNumber"></td>
                            <td *ngFor="let column of columns">
                                {{ getSubtotalValue(column, rowIndex) }}
                            </td>
                            <td class="Table-RowButtons"></td>
                        </tr>
                        </ng-container>
                    </ng-container>

                    <!--  TABLE DATA END -->

                    </tbody>

                    <tfoot *ngIf="payload?.data && payload.data.length">
                    <tr>
                        <td class="EntityTable-multiselectcheckbox">
                        <td class="EntityTable-lineNumber"></td>
                        <td *ngFor="let column of columns" [innerHTML]="footerData[column.key] || ''"></td>
                        <td class="Table-RowButtons"></td>

                    </tr>
                    </tfoot>

                </table>
            </div>

            <pro-no-records *ngIf="!(isLoading$ | async) && !isError && payload?.data && payload.data.length === 0" [attr.table-empty]="config?.tableId"
                           [ngClass]="{ 'DisplayNone': config?.hideIfEmpty && payload?.data?.length == 0  }"
            ></pro-no-records>

            <div fxLayout="row" fxLayoutAlign="center center" class="Error" *ngIf="isError">
                <fa name="exclamation-triangle" class="SidePadding"></fa>
                {{ 'error' | translate }} {{ isError }}
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <pro-pagination *ngIf="!dataSource && !config?.useListNotSearch"
                               [total]="payload?.total"
                               [limit]="limit"
                               [currPage]="currPage"
                               (onSelect)="setPage($event)"></pro-pagination>
            </div>

        </div>
    `,
    styles: [
        ':host { width: 100%; padding: 0 !important; }',
        'td { font-size: 0.90em; }',
        '.MinWidth75 { min-width: 75px; }',
        'tfoot td { padding: 0 8px; }',
        '.TableFilter { position: relative; margin-top: 2px; height: 18px; overflow: hidden; min-width: 40px; }',
        'pro-btn.hidden { display: none; }',
        '.isActive { color: #2196f3 }'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() config: TableConfig;
    @Input() timeStamp: number;
    @Input() dataSource: { data: any[]; total: number };
    @Input() loadWhen: any;
    @Input() dynamicTableId: string;
    @Input() _loading: boolean;
    @Input() configTimeStamp: number;
    @Output() onAdvancedSearch: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCustomFilter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChart: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('element', { static: true }) element: ElementRef;
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    requestPending: boolean = false;

    payload: { data: TableObject[]; total: number } = { data: [], total: 0 };
    columns: TableField[];

    rowActions: TableButton[] = [];
    addButton: TableButton;
    topButtons: TableButton[] = [];
    actionButtons: TableButton[] = [];

    dataEntity: EntityInterface;

    // defaults
    sort: any = {};
    params: any = {};
    offset: number = 0;
    limit: number = 0;
    currPage: number = 0;
    isMultiselect: boolean = false;
    isLineNumbering: boolean = false;
    isSpecialisationFilter: boolean = false;
    isSpecialisationFilterDisabled: boolean = false;
    isMultipleSort: boolean = false;
    isFilteringDisabled: boolean = false;
    search: any = {};

    defaultParams: any = { limit: this.limit, offset: this.offset, paginate: true };

    selectedRows: any = [];
    selectedRowsCount: any;

    allRowsSelected: boolean;

    subtotal: any;
    includeDynamic: any;

    currenciesByName: any;

    isCustomFiltersInToolbar: any;
    customFilter: any;
    _queryParams: any;
    queryFilter: any;
    queryColumns: any;
    queryIgnore: boolean;

    isError: any;
    isTopPagination: boolean;
    viewLimit: number = DEFAULT_VIEW_LIMIT;
    _inited: boolean;
    isChart: boolean = false;
    destroyed$: Subject<void> = new Subject<void>();
    systemOptions: SystemOptions;
    loadCount: number = 0;
    decimalPlacesDefault: number = 0;
    isCanceledMap: { [key: number]: boolean } = {};
    isCanceledIter: number = 0;
    isSplitLoading: boolean = false;
    canEditFilter: boolean = false;
    getMethod: 'search'|'list' = 'search';
    processingRequest: CancelableSequentialRequest|null = null;

    rowClass: {[key: number]: string} = {};
    footerData: {[key: string]: string } = {};
    columnStyleData: {[key: string]: any } = {};

    lastSelectedRow: number = 0;
    isShift: boolean = false;

    constructor(
        @Inject(TABLE_STATE_PARSER) private stateParser: TableStateParser,
        @Inject(TABLE_MODULE_CONFIGURATION) private configuration: TableModuleConfiguration,
        Search: Search,
        private Entity: Entity,
        private MatLegacyDialog: MatLegacyDialog,
        private Filter: FilterService,
        private TableCache: TableCacheService,
        private TableHelper: TableHelperService,
        private ActionsParser: TableActionsParserService,
        private cd: ChangeDetectorRef,
        private Location: Location,
        private store: Store,
        private Request: RequestService,
        private UiPrefs: UiPreferencesService,
        private DynamicFields: DynamicFieldsService,
        private TableParametersFormatter: TableParametersFormatterService,
        private QueryExpression: QueryExpressionService,
        private route: ActivatedRoute,
        private Router: Router,
        private Currencies: CurrenciesService,
        private ACL: ACL,
    ) {
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);

        this.store.select(getDecimalPlaces)
            .subscribe((decimal) => this.decimalPlacesDefault = decimal);

        this.limit = this.systemOptions?.defaultTableRows || 50;

        this.currenciesByName = this.Currencies.dataMappedByName;

        Search.useAsFilter((query: string) => {
            if (isDefined(query)) this.handleSearch(query);
        });

        const topPagination = this.UiPrefs.get(UI_TABLE_PAGINATION_TOP);

        if (isNull(topPagination)) {
            this.isTopPagination = true;
            this.UiPrefs.set(UI_TABLE_PAGINATION_TOP, true);

        } else {
            this.isTopPagination = topPagination;
        }

        this.canEditFilter = this.ACL.check('filter.edit');

    }

    ngOnInit() {
        function getCustomFilter(data: any) {
            if (!isDefined(data)) return {};

            let result = {};
            let parts = data.split(',');

            parts.forEach((part: string) => {
                let p = part.split(':');

                result[p[0]] = p[1];
            });

            return result;
        }

        // this.checkIfToolbarCustomFilter();
        this.columns = [];
        this.loadCount = 0;
        if (this.config) this.getMethod = this.config.useListNotSearch ? 'list' : 'search';

        this.TableHelper.timeStamp
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.getData();
            });

        this.route.queryParams.subscribe((params: Params) => {
            if (params['savedFilter']) {
                const prefsKey = UI_TABLE_SAVED_FILTERS;
                this.queryFilter =  (this.UiPrefs.get(prefsKey) || {})[params['savedFilter']];
                this.queryColumns = (this.UiPrefs.get(prefsKey) || {})[`${params['savedFilter']}_cols`];
            } else if (params['tableShare']) {
                try {
                    const tableId = this.config.tableId;
                    if (tableId === params['tableShare']) {
                        const cache = decodeURI(params['tableData']);
                        this.TableCache.set(JSON.parse(cache), tableId);

                        this.init();
                        this.Router.navigate([], { relativeTo: this.route });
                    }


                } catch (e) {

                }
            } else {
                this.queryFilter =  null;
                this.queryColumns = null;
            }

            if (this.queryFilter && this.payload.total) this.init(); // run init() if has loaded before

            this.customFilter = getCustomFilter(params['filter']);

        });

        this.init();

        fromEvent<KeyboardEvent>(document, 'keydown').pipe(takeUntil(this.destroyed$))
            .subscribe((event: KeyboardEvent) => {
                if (event.key === 'Shift') {
                    this.isShift = true;
                }
            });

        fromEvent<KeyboardEvent>(document, 'keyup').pipe(takeUntil(this.destroyed$))
            .subscribe((event: KeyboardEvent) => {
                if (event.key === 'Shift') {
                    this.isShift = false;
                }

                if ((event.key === 'u' || event.key === 'U') && (event.metaKey || event.ctrlKey)) {
                    this.handleShortcutAdd();
                }

            });

        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e: any) => {
                document.onselectstart = () => {
                    return !(e.key == "Shift" && e.shiftKey);
                }
            });
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        let timeStamp = changes.timeStamp;
        let data = changes.dataSource;
        let loadWhen = changes.loadWhen;
        let config = changes.config;
        let _loading = changes._loading;
        let configTimeStamp = changes.configTimeStamp;

        if (config && !config.firstChange) {
            this.parseRowActions();
        }

        if (timeStamp && !timeStamp.firstChange) {
            this.init();
        }

        if (data && data.currentValue && !data.firstChange) {
            this.payload = this.dataSource;

            this.resetRowClasses();

            if (this.config?.transform) {
                this.handleTransform();
            }

            this.setRowClasses();

            if (data?.currentValue?.data?.length) {
                this.isLoading$.next(false);
            }
        }

        if (loadWhen && loadWhen.currentValue && !loadWhen.firstChange) { //
            this.init();
        }

        if (_loading) {
            this.isLoading$.next(_loading.currentValue);
        }

        if (configTimeStamp) {
            this.init({ resetFields: true });
        }

    }

    checkIfToolbarCustomFilter = () => {
        this.isCustomFiltersInToolbar = this.Location.path().split('/').length < 3 && this.config && this.config.customFilters && this.config.customFilters.length;
    };

    ngOnDestroy() {
        if (this.isCustomFiltersInToolbar && document.querySelector('.Table-customFilters')) {
            document.querySelector('.Table-customFilters').remove();
        }

        this.destroyed$.next();
        this.cancelRequests();

    }

    async init(config: { resetFields?: boolean } = {}) {
        let isCachedFields;

        const resetFields = config.resetFields;

        if (!(this.config && this.config.fields)) return;

        let settings = this.TableHelper.getSettings(Object.assign({}, this.config.extraParameters, this.customFilter), this.config.tableId);

        if (this.queryFilter || this.customFilter) this.TableHelper.handleExtendedFilter(settings, Object.assign({}, this.queryFilter, this.customFilter));

        let params = this.TableHelper.getQueryParams(settings);

        this.dataEntity = this.Entity.get({ name: this.config.entity });

        this.addButton = this.config.addButton ? this.ActionsParser.parseButtons(this.config.aclRoot, [this.config.addButton])[0] : null;
        this.topButtons = this.ActionsParser.parseButtons(this.config.aclRoot, this.config.topButtons);
        this.actionButtons = this.ActionsParser.parseButtons(this.config.aclRoot, this.config.actionButtons);
        this.parseRowActions();
        this.ActionsParser.addDefaultRowCallback(this.config, this.rowActions);

        // this.currPage = params.currentPage;
        this.offset = params.offset;
        this.sort = params.sort;
        this.limit = params.limit;
        this.isMultiselect = this.config.multiselect || settings.isMultiselect;
        this.isMultipleSort = settings.isMultipleSort;
        this.isLineNumbering = this.config.multiselect || settings.isLineNumbering;
        this.isFilteringDisabled = settings.isFilteringDisabled;

        const tableCache = this.TableCache.get(this.config.tableId);

        this.subtotal = tableCache && tableCache.subtotal || { enabled: false };
        this.includeDynamic = tableCache && tableCache.includeDynamic || this.config.dynamicFields || false;
        this.isChart = tableCache && tableCache.isChart;

        [this.columns, isCachedFields] = this.TableHelper.initFields(this.config.fields, this.config.tableId, true);
        if (!isCachedFields || resetFields) this.columns = this.columns.filter((item) => !item.hidden);
        if (this.queryColumns) {
            this.columns = this.queryColumns
                .map((field: TableField) => this.columns
                .find((col) => col.key === field.key))
                .filter((field: TableField) => !!field) // filter our 'undefined' columns;
        }

        await this.checkPreloadParameters();

        // move to handle dynamic fields after table settings dialog
        if (this.includeDynamic) {
            await this.handleDynamicFields();
        }

        this.TableHelper.inferFilterType(this.columns);

        // init query parameters and filter values if cached
        // either saved query filter or cached table settings
        this.TableHelper.initQueryParameters(this.queryFilter || settings, this.columns, this.config.customFilters, this.params, this.config);

        this._inited = true;

        this.getData();
    }

    checkPreloadParameters = async () => {
        if (this.config.preloadParametersData || this.columns.some((column) => column.preloadParametersData)) {
            this.isLoading$.next(true);
            await this.TableParametersFormatter.preloadParameters();
        }
    };

    parseRowActions = () => this.rowActions = this.ActionsParser.parseButtons(this.config.aclRoot, this.config.rowButtons);

    setFilter(column: TableField, value: any) {
        if (this.processingRequest) this.processingRequest.cancel();

        if (this.config.customFilters &&
          this.config.customFilters.filter((filter) => filter.isSelected).length && value) {
            // remove custom filter with same key
            this.config.customFilters.forEach((filter, index) => {
                if (filter.isSelected && filter.key === column.key) {
                    this.toggleCustomFilter(null, filter, false);
                }
            });
        }

        if (this.onFilterChange.observers.length) {
            this.onFilterChange.emit({ column, value });

        } else if (column.emitFilterValue) {
            return column.emitFilterValue(column, value);

        } else if (column.formatter === 'money') {
            this.params[`${column.key}.amount`] = value;  // if field is of currency object, filter by object.amount

        } else if (column.filter && column.filter.type === 'dropdown_multi') {
            this.params[column.key] = value ? this.QueryExpression[column.filter.notStrict ? 'in' : 'orStrict'](value.map((item: any) => item.id)) : value;

        } else if (column.filter && column.filter.type === 'autocomplete') {
            this.params[`${column.key}.id`] = value;

        } else if (column.filter && column.filter.type === 'date') {
            if (value) {
                this.params[column.key] = value;
            } else {
                this.params[column.key] = null;
            }
        } else if (column.filter && column.filter.type === 'search') {
            const values = {};

            for (const key of column.filter.keys) {
                values[key] = value;
            }

            this.params.search = values;

        } else {
            this.params[column.key] = value;
        }

        column.filter.value = value;

        if (this.queryFilter) {
            this.queryFilter = null;
            this.queryColumns = null;
            this.queryIgnore = true;
        }

        this.isLoading$.next(true);
        setTimeout(() =>this.getData(), 2000);

        if (value === null && this.config.tableId) {
            this.setQueryParams();
            this.TableCache.saveSettings();
        }

    }

    cancelRequests() {
        this.isCanceledMap[this.isCanceledIter] = true;
    }

    async getData(force?: boolean) {
        this.cancelRequests();
        if (!this._inited || (this.requestPending && !force)) {
            return;
        }

        this.isCanceledIter++;

        this.loadCount++;
        const params = Object.assign(
            this.defaultParams,
            this.config.extraParameters,
            (Object.keys(this.sort).length ? { sort: this.sort } : {}),
            this.params,
            { offset: this.offset },
            { limit: this.config.extraParameters && this.config.extraParameters.limit || this.limit },
            (Object.keys(this.search).length ? { search: this.search } : {}),
            (this.queryFilter && !this.queryIgnore ? this.queryFilter.filters : {}),
            (this.includeDynamic ? { dynamicFields: true } : { }),
        );

        if (!this.includeDynamic) delete params.dynamicFields;

        if (!params.join) params.join = [];

        // extraJoins
        this.columns
            .filter((col) => !!col.extraJoins && !this.dynamicTableId)
            .forEach((col) => col.extraJoins
                .forEach((join) => {
                    if (!params.join.includes(join)) params.join.push(join);
                }));

        // partialJoins
        this.columns
            .filter((col) => !!col.extraPartialJoins)
            .forEach((col) => {
                Object.keys(col.extraPartialJoins)
                    .forEach((partialJoinKey) => {
                        if (!params.partialJoin) params.partialJoin = {};
                        if (!params.partialJoin[partialJoinKey]) params.partialJoin[partialJoinKey] = [];
                        col.extraPartialJoins[partialJoinKey].forEach((partialJoinValue) => {
                            if (!params.partialJoin[partialJoinKey].includes(partialJoinValue)) {
                                params.partialJoin[partialJoinKey].push(partialJoinValue);
                            }
                        });
                    })
            });

        if (this.isChart && this.config.chartJoins) {
            this.config.chartJoins
                .forEach((join) => {
                    if (!params.join.includes(join)) params.join.push(join);
                });
        }

        this.limit = params.limit;

        if (this.isSpecialisationFilterDisabled) {
            this.TableHelper.removeSpecialisationFilters(params, this.config.tableId);

            this.columns.forEach((col) => {
                if (col.filter) col.filter.disabled = false;
            });

        } else {
            this.isSpecialisationFilter = !this.isSpecialisationFilterDisabled && this.TableHelper.checkSpecialisationFilters(params, this.config.tableId);

            if (this.isSpecialisationFilter) {
                this.columns.forEach((col) => {
                   if (col.filter) col.filter.disabled = Object.keys(this.isSpecialisationFilter).includes(col.key);
                });

            }

        }

        this._queryParams = params;

        this.resetSelectedRows();
        // setTimeout(() => this.moveCustomFilters());

        this.viewLimit = DEFAULT_VIEW_LIMIT;

        if (isDefined(this.config.beforeLoad)) {
            await this.config.beforeLoad({ params });
        }

        if (this.dataSource) {

            this.payload = {
                data: this.TableHelper.filterDataSourceTable(this.dataSource.data, this.columns),
                total: this.dataSource.total
            };

            this.handleTransform();

            this.setRowClasses();

            if (this.dataSource && Object.keys(this.sort).length) {
                const key = Object.keys(this.sort)[0];
                const field = this.columns.filter((field: any) => field.key === key.split('.amount')[0])[0];

                this.payload.data.sort((a: any, b: any) => {
                    const aVal = key.endsWith('.amount') ? +getFieldValue(field, a).amount : getFieldValue(field, a);
                    const bVal = key.endsWith('.amount') ? +getFieldValue(field, b).amount : getFieldValue(field, b);

                    if (this.sort[key] === 'asc') {
                        if (aVal < bVal) return -1;
                        if (aVal > bVal) return 1;
                        return 0;

                    } else {
                        if (aVal < bVal) return 1;
                        if (aVal > bVal) return -1;
                        return 0;

                    }

                });
            }

            if (Object.keys(this.TableHelper.serializeFilterValues(this.columns)).length && this.config.dataSourceFiltering) {
                this.payload.data = this.TableHelper.filterDataSourceTable(this.payload.data, this.columns);

            }

            this.setFooter();
            this.cd.markForCheck();
            this.cd.detectChanges();

        } else {
            this.isLoading$.next(true);
            this.requestPending = true;

            this.loadData(params);

        }
    }

    loadData = (params: { [key: string]: any}) => {
        const lazyLoadIncrement = this.configuration.lazyLoadIncrements;
        const handleError = (error: any) => {
            this.isError = error;
            console.error(error);
            this.isLoading$.next(false);
            this.requestPending = false;

            this.cd.markForCheck();
        };
        if (params.limit <= lazyLoadIncrement || this.config.useListNotSearch) {

            const promise = this.dataEntity[this.getMethod](params) as unknown as Promise<PaginatedData<TableObject>>;

            promise.then(this.handleResponse)
                .catch(handleError);
        } else {
            const requestIter = this.isCanceledIter;

            const initialLimit = params.limit;
            const initialOffset = params.offset;
            let totalLoad = 0;

            const iter = Math.ceil(initialLimit / lazyLoadIncrement);

            const _params = { ...params, limit: lazyLoadIncrement, offset: initialOffset };

            const promise = this.dataEntity[this.getMethod](_params) as unknown as Promise<PaginatedData<TableObject>>;

            let initialResponse: PaginatedData<TableObject> = { data: [], total: 0, length: 0 };
            let data: TableObject[] = [];

            promise.then((response) =>  {
                initialResponse = response;
                this.handleResponse(initialResponse);

                data = data.concat(initialResponse.data);

                totalLoad += lazyLoadIncrement;
                if (totalLoad > response.total) this.cancelRequests();

                const requests: (() => Promise<unknown>)[] = [];
                this.isSplitLoading = true;

                for (let i = 1; i < iter; i++) {
                    const additionalParams = { ...params, limit: lazyLoadIncrement, offset: initialOffset + i * lazyLoadIncrement };
                    requests.push(() => new Promise<void>((resolve) => {
                        if (!this.isCanceledMap[requestIter]) {

                            const additionalPromise = this.dataEntity[this.getMethod](additionalParams) as unknown as Promise<PaginatedData<TableObject>>;

                            additionalPromise.then((additionalResponse) => {
                                // initialResponse =
                                // { data:
                                // [...initialResponse.data, ...additionalResponse.data],
                                // total: additionalResponse.total,
                                // length: initialResponse.length + additionalResponse.length };

                                if (additionalResponse && additionalResponse.data) data = data.concat(additionalResponse.data);
                                initialResponse.data = [...data];
                                initialResponse.length = initialResponse.length + additionalResponse.length
// console.log('initialResponse', deepCopy(initialResponse));
// console.log('data', deepCopy(data));
                                if (i + 1 == iter) this.isSplitLoading = false;

                                this.handleResponse(initialResponse);

                                totalLoad += lazyLoadIncrement;
                                if (totalLoad > response.total) {
                                    i = iter;
                                    this.cancelRequests();
                                    return;
                                }

                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    }))
                }

                this.processingRequest = sequentialRequestsWithCancel(requests);
                this.processingRequest.do().then(() => {
                  this.processingRequest = null;
                })

            })
                .catch(handleError);

        }
    };

    handleTransform = () => {
        if (this.config.transform) this.payload.data = this.config.transform(this.payload?.data);
        if (this.isChart && this.config.chartTransform) this.payload.data = this.config.chartTransform(this.payload?.data);

        if (this.config.transformAfter) this.config.transformAfter(this.payload.data).then((newData) => {
            // this.payload.data = [];
            // setTimeout(() => {
                this.payload.data = newData;
                this.cd.markForCheck();
            // });
        });
    };

    handleResponse = (response: PaginatedData<TableObject>) => {
        this.payload = response;

        this.handleTransform();

        this.setRowClasses();

        this.setFooter();
        this.setColumnStyle();

        if (this.payload && this.payload.data && this.payload.data.length === 0 && this.payload.total > 0 && this.currPage > 0) {
            // current offset is greater than total filtered records
            // reset current page and retry search
            this.currPage = 0;
            this.offset = this.currPage * this.limit;

            this.resetSelectedRows();
            this.getData(true);

        }

        if (!this.payload?.data) {
            // @ts-ignore
            if (this.payload?.length > 0) {
                // current offset is greater than total filtered records
                // reset current page and retry search
                this.currPage = 0;
                this.offset = this.currPage * this.limit;

                this.resetSelectedRows();
            }
        }

        this.isError = null;
        this.isLoading$.next(false);
        this.requestPending = false;

        this.cd.markForCheck();

        this.setQueryParams();

        if (this.isChart && this.loadCount === 1) this.handleChartButton(true);
    };

    isSortable = (column: any): boolean => {
        const key: string = column.formatter === 'money' ? `${column.key}.amount` : column.key;
        let isSortable: boolean = false;

        if (key && !((column.sort === false) || (column.sort === null))   ) {
            for (let field of this.columns) {

                if (field.formatter === 'money' && `${field.key}.amount` === key && field.sortable !== false) {
                    isSortable = true;
                    break;

                } else if (field.key === key && field.sortable !== false) {
                    isSortable = true;
                    break;

                }

            }

        }

        return isSortable;
    };

    sortBy = (column: any, $event: any): void => {
        const key: string = column.formatter === 'money' ? `${column.key}.amount` : column.key;
        let dir: string;

        if (this.isSortable(column)) {
            if (this.isDateField(column)) {
                switch (this.sort[key]) {
                    case 'asc':
                        dir = 'desc';
                        break;
                    case 'desc':
                        dir = '';
                        break;
                    default:
                        dir = 'asc';
                        break;
                }
            } else {
                switch (this.sort[key]) {
                    case 'asc':
                        dir = '';
                        break;
                    case 'desc':
                        dir = 'asc';
                        break;
                    default:
                        dir = 'desc';
                        break;
                }
            }


            if (!$event.shiftKey) {
                if (this.isMultipleSort) {
                    this.sort = cloneDeep(this.sort);
                } else {
                    this.sort = {};
                }
                delete this.sort.id;
                if (dir.length > 1) {
                    this.sort[key] = dir;
                } else {
                    delete this.sort[key];
                }
            } else {
                this.sort = { id: 'asc' };
            }

            this.getData();

        }

    };

    isSortedBy = (column: any) => {
        const key: string = column.formatter === 'money' ? `${column.key}.amount` : column.key;

        return this.sort && this.sort[key] || null;
    };

    isDateField = (column: any): boolean => {
        const key: string = column.key;
        let field = this.getFieldByKey(key);

        return !!field.date || !!field.datetime;
    };

    getFieldByKey = (key: string): any => {
        for (let column of this.columns) {
            if (column.key === key) return column;
        }
    };

    setPage(page: number) {
        this.cancelRequests();

        this.currPage = page;
        this.offset = this.currPage * this.limit;

        this.getData();
    }

    handleRowAction(event: any, button: TableButton, row: any) {
        button.callback(row, event);
    }

    setQueryParams() {
        this.TableCache.set({
            limit: this.limit,
            currentPage: this.currPage,
            sort: this.sort,
            filters: this.isFilteringDisabled ? {} : this.TableHelper.serializeFilterValues(this.columns),
            customFilters: this.TableHelper.getCustomFilters(this.config.customFilters),
            _lastSave: utcFormat(moment()),
            isMultiselect: this.isMultiselect,
            isLineNumbering: this.isLineNumbering,
            isMultipleSort: this.isMultipleSort,
            isFilteringDisabled: this.isFilteringDisabled,
        }, this.config.tableId);

        // save filter params for quick table filter with hash key
        this.TableHelper.setFilterParams(this.TableHelper.getSettings(this.config.extraParameters, this.config.tableId), this.columns);

        // @persontablesettingsrework
        // this.TableCache.saveSettings();

    }

    handleRowClick(row: any, event: MouseEvent) {
        if (this.config.onRowClick) return this.config.onRowClick(row, event);
    }

    editFields = () => {
        this.config.fields = this.config.fields.filter((item: TableField) => isDefined(item.acl) ? this.ACL.check(item.acl) : true);

        for (let field of this.config.fields) {
            field._enabled = !!findByProperty(this.columns, 'key', field.key);

        }

        const savedColumns: TableField[] = this.TableHelper.initFields(this.config.fields, this.config.tableId);

        this.MatLegacyDialog
            .open(
                TableSettingsDialogComponent, {
                    data: {
                      tableId: this.config.tableId,
                      fields: this.config.fields.sort((a, b) => {
                        const itemA = savedColumns.find((item) => item.key === a.key);
                        const itemB = savedColumns.find((item) => item.key === b.key);

                        if (!itemA) return 1;
                        if (!itemB) return -1;

                        return savedColumns.indexOf(itemA) - savedColumns.indexOf(itemB);
                      }),
                      isMultiselect: this.isMultiselect,
                      isMultiselectAvailable: this.actionButtons && this.actionButtons.length,
                      isLineNumbering: this.isLineNumbering,
                        isMultipleSort: this.isMultipleSort,
                        isFilteringDisabled: this.isFilteringDisabled,
                      subtotal: this.subtotal,
                      dynamicTableId: this.dynamicTableId,
                      includeDynamic: this.includeDynamic,
                      query: {
                        entity: this.config.entity,
                        params: this._queryParams
                      },
                      config: this.config,
                      htmlElement: this.element.nativeElement,
                      cache: this.TableCache.get(this.config.tableId)
                    },
                  width: '600px'
                },
            )
            .afterClosed()
            .toPromise()
            .then(async (response: { fields: TableField[]; limit: number; isMultiselect: boolean; isMultipleSort: boolean; isLineNumbering: boolean; isFilteringDisabled: boolean; includeDynamic: any; subtotal: any }) => {
                if (!response) return;
                if ((response as any).settingsReset) {
                    window.location.reload();
                    return;
                }

                this.columns = response.fields.filter((column: TableField) => column._enabled);

                if (response.limit && response.limit !== this.limit) {
                    this.limit = response.limit;

                }

                if (response.isMultiselect !== this.isMultiselect) {
                    this.isMultiselect = response.isMultiselect;

                }

                if (response.isLineNumbering !== this.isLineNumbering) {
                    this.isLineNumbering = response.isLineNumbering;

                }

                if (response.isMultipleSort !== this.isMultipleSort) {
                    this.isMultipleSort = response.isMultipleSort;

                }

                if (response.isFilteringDisabled !== this.isFilteringDisabled) {
                    this.isFilteringDisabled = response.isFilteringDisabled;

                }

                if (response.includeDynamic !== this.includeDynamic) {
                    this.includeDynamic = response.includeDynamic;
                }

                if (response.subtotal) {
                    this.subtotal = response.subtotal;
                }

                if (this.includeDynamic) await this.handleDynamicFields();

                this.checkPreloadParameters();

                this.isTopPagination = this.UiPrefs.get(UI_TABLE_PAGINATION_TOP);

                this.cancelRequests();
                this.setQueryParams();
                this.saveTableConfig();
                this.TableHelper.inferFilterType(this.columns);
                this.resetSelectedRows();
                this.getData();

                this.timeStamp = new Date().getTime();

                this.TableCache.saveSettings();

            });
    };

    saveTableConfig = () => {
        let columns = this.columns.map((_column: any) => {
            let column: any = { key: _column.key };

            if (_column.config) {
                if (_column.config.statsConfig) column.statsConfig = _column.config.statsConfig;
                if (_column.config.showFullResult) column.config = { showFullResult: true };
            }

            if (_column.formatter === 'dateTime') {
              column.showTime = _column.showTime;
            }

            return column;
        });

        let params = {
            fields: columns,
            subtotal: { enabled: false },
            includeDynamic: this.includeDynamic,
            isChart: this.isChart
        };

        if (this.subtotal.enabled) params.subtotal = this.subtotal;

        this.TableCache.set(params, this.config.tableId);
    };

    toggleCustomFilter = ($index: number, filter: TableFilter, shouldReload: boolean = true) => {
        let customFilters = this.config.customFilters || [];

        for (let iter = 0; iter < customFilters.length; iter++) {
            let customFilter = customFilters[iter];

            (iter !== $index) ?
                customFilter.isSelected = false :
                customFilter.isSelected = !customFilter.isSelected;

            if (typeof customFilter.value === 'object') {
                for (let key in customFilter.value) {
                    this.params[key] = [];
                }

            } else {
                this.params[customFilter.key] = [];
            }

            if (filter.isSelected) {

                if (typeof filter.value === 'object') {
                    for (let key in filter.value) {
                        this.params[key] = filter.value[key];
                    }

                } else {
                    this.params[filter.key] = filter.value;

                }

            }

            if (!filter.isSelected && filter.isDefault) {
                this.config.isDefaultFilterDisabled = true;
                this.TableCache.set({ isDefaultFilterDisabled: true }, this.config.tableId);
                this.TableCache.saveSettings();
            }

        }

        if (shouldReload) {
            this.resetSelectedRows();
            this.getData();
        }

        this.setQueryParams();
        if (this.onCustomFilter.observers.length) {
            this.onCustomFilter.emit();
        }
    };

    setRowSelected(row: any, value: boolean, rowIndex?: number) {
        row._selected = value;

        if (this.isShift) {
            const [a,b] = [this.lastSelectedRow, rowIndex].sort();
            this.payload.data.filter((r, i) => {
                if (i >= a && i <= b) {
                    r._selected = value;

                }
            });

        }

        if (isDefined(rowIndex)) this.lastSelectedRow = rowIndex;


        this.checkSelectedRows();
    }

    checkSelectedRows() {
        this.selectedRowsCount = 0;
        this.selectedRows = [];

        for (const row of this.payload.data) {
            if (row._selected) {
                this.selectedRowsCount++;
                this.selectedRows.push(row);

            }

        }

        this.allRowsSelected = (this.selectedRowsCount === this.payload.data.length);

        if (this.config.customFooter && Object.keys(this.config.customFooter).length > 0) {
            this.setFooter();
        }

        this.cd.markForCheck();

    }

    resetSelectedRows() {

        if (this.config.useListNotSearch) {
            const tempArray = [];
            // @ts-ignore
            for (let i = 0; i < this.payload.length; i++) {
                tempArray.push(this.payload[i]);
            }

            // @ts-ignore
            this.payload = { data: tempArray, total: this.payload.length };
        }

        if (this.payload) for (const row of this.payload.data) {
            if (row) row._selected = false;
        }

        this.selectedRowsCount = 0;
        this.allRowsSelected = false;

        if (this.config.customFooter && Object.keys(this.config.customFooter).length > 0) {
            this.setFooter();
        }
    }

    selectAllRows(value: boolean) {
        this.allRowsSelected = value;
        this.selectedRowsCount = value ? this.payload.data.length : 0;
        this.selectedRows = [];

        for (const row of this.payload.data) {
            row._selected = value;

            if (value) this.selectedRows.push(row);
        }
    }
    setColumnStyle(){
        this.columns.forEach((col) => {
            this.columnStyleData[col.key] = this.getColumnStyle(col);
        });

        this.cd.markForCheck();
    }

    clearFooter() {
        this.footerData = {};
    }

    setFooter() {
        this.columns.forEach((col) => {
           this.footerData[col.key] = this.getFooterData(col);
        });

        this.cd.markForCheck();
    }
    getFooterData(column: TableField) {
        let output: any[] = [];
        let data: any[] = [];
        const isCustomFooter = !!(this.config.customFooter && this.config.customFooter[column.key]);
        const columnUnit = column.customFooterUnit || '';

        if (!(column.stats && this.currenciesByName || isCustomFooter)) return '';

        if (isCustomFooter) {
            return this.config.customFooter[column.key](this.payload.data);

        } else {
            const isSum: boolean = column.config && column.config.statsConfig['sum'] || column.formatter === 'numeric' && column.key.startsWith('df_');
            const isAvg: boolean = column.config && column.config.statsConfig['average'];

            this.payload.data.forEach((row: any) => {
                const value = getFieldValue(column, row);

                data.push(value);
            });

            const isMoneyFormatter: boolean = column.formatter === 'money';
            const isDurationFormatter: boolean = column.formatter === 'duration';
            let sum: any = isMoneyFormatter ? [] : 0;

            data.forEach((item: any) => {
                if (isMoneyFormatter) {

                    if (item && isDefinedNotNull(item.amount) && item.currency) {
                        let isCurrencyFound: boolean = false;

                        sum.filter((sumItem: any) => {
                            if (item.currency === sumItem.currency) {
                                sumItem.amount += roundNumber(+item.amount);
                                isCurrencyFound = true;
                            }
                        });

                        if (!isCurrencyFound) {
                            sum.push({ amount: parseFloat(item.amount) || 0, currency: item.currency });
                        }

                    }

                } else {
                    sum += isNumber(parseFloat(item)) ? parseFloat(item) : 0;
                }

            });

            if (isSum) {
                output.push(`<div><span>${this.Filter.translate('total')}:</span> `);

                if (isMoneyFormatter) {
                    sum.forEach((sumItem: Money) => output.push(`<span>${roundNumber(+sumItem.amount)} ${(this.currenciesByName[sumItem.currency] || {}).shorthand}</span> <br/>`));

                } else if (isDurationFormatter) {
                    output.push(this.Filter.duration(sum));

                } else output.push(`${roundNumber(sum, this.systemOptions.defaultDecimalPlaces)}${columnUnit}`);

                output.push(`</div>`);

            }

            if (isAvg) {
                output.push(`<div>${this.Filter.translate('average')}: `);

                if (isMoneyFormatter) {
                    sum.forEach((sumItem: any) => output.push(`<span>${(this.currenciesByName[sumItem.currency] || {}).shorthand} ${roundNumber((sumItem.amount / data.length), 4)}</span> <br/>`));

                } else if (isDurationFormatter) {
                    output.push(this.Filter.duration(roundNumber((sum / data.length), 0)));

                } else {
                    if (column.avgFormat) {
                        output.push(`${column.avgFormat((sum / data.length).toFixed(2))}</div>`);
                    } else {
                        output.push(`${(sum / data.length).toFixed(2)}</div>`);
                    }

                }

                output.push('</div>');
            }

            return output.join('');
        }
    }

    getSubtotalValue(column: any, index: any) {
        let i: number = index;
        let data = this.payload.data;
        let groupFields = this.subtotal.groupFields.map((item: any) => item.key);
        let baseCurrency: Currency = this.Currencies.base;

        if (this.subtotal.groupBy.key === column.key) {
            let count: number = 1;

            while ((i >= 1) && getFieldValue(this.subtotal.groupBy, data[i]) === getFieldValue(this.subtotal.groupBy, data[i - 1])) {
                count++;
                i--;
            }

            return this.Filter.translate('record_count', { count });
        }

        if (groupFields.indexOf(column.key)  > -1) {
            let isMoney: boolean = column.formatter === 'money';
            let sum: any = getFieldValue(column, data[i]);
            const decimal = this.decimalPlacesDefault;

            if (isMoney) {
                if (!(sum && sum.amount && sum.currency)) {
                    sum = { amount: 0, currency: baseCurrency.name };

                }

            } else {
                if (isNaN(sum) || sum === null) sum = 0;

                sum = (+sum).toFixed(decimal);

            }

            while ( (i >= 1) && getFieldValue(this.subtotal.groupBy, data[i]) === getFieldValue(this.subtotal.groupBy, data[i - 1]) ) {
                let value: any = getFieldValue(column, data[--i]);

                if (isMoney) {
                    sum = { amount: (+sum.amount + (+value.amount || 0)).toFixed(2), currency: sum.currency };
                } else {
                    sum = (+sum + (+value || 0)).toFixed(decimal);
                }

            }

            return  `${this.Filter.translate('subtotal')} ` + (isMoney ? `${this.currenciesByName[sum.currency].shorthand} ${sum.amount}` : sum);
        }

        return ;
    }

    isSubtotalRow(index: any) {
        if (!(this.subtotal && this.subtotal.enabled && this.subtotal.groupFields && this.subtotal.groupFields.length) ) return false;

        let data = this.payload.data;

        if (index === this.payload.data.length - 1) return true;

        // if next line is of different value, print out subtotal
        return getFieldValue(this.subtotal.groupBy, data[index]) !== getFieldValue(this.subtotal.groupBy, data[index + 1]);
    }

    moveCustomFilters() {
        this.checkIfToolbarCustomFilter();

        if (!this.isCustomFiltersInToolbar) return;

        const toolbar = document.querySelector('.Toolbar-Title');
        const filter = document.querySelector('.Table-customFilters');

        if (toolbar && filter) toolbar.parentNode.insertBefore(filter, toolbar.nextSibling);
    };

    handleSearch = (query: string) => {
        if (!this.config) return;

        let searchFields: any = ['name'];
        let config = resourcesConfig[this.config.entity];

        this.search = {};

        if (config && config.params.searchFields) {
            searchFields = uniq(searchFields.concat(config.params.searchFields));

            if (isDefinedNotNull(query)) {
                searchFields.forEach((field: any) => this.search[field] = query);

                this.resetSelectedRows();
                this.getData();
            }

        }

    };

    getColumnStyle(column: TableField) {
        const filter = column.filter;

        if (filter && filter.value) return { background: 'rgba(155, 155, 155, 0.1)' };

        const formatterConfig = column.formatterConfig?.toString();
        if (formatterConfig && formatterConfig.includes('entity-table-color')) return { width: '30px;' }

        return {};
    }

    handleDynamicFields() {
        return this.TableHelper.handleDynamicFields(this.columns, this.includeDynamic);
    }

    handleChartButton(value?: boolean) {
        this.isChart = isDefined(value) ? value : !this.isChart;
        this.onChart.emit(this.isChart);

        this.saveTableConfig();

        if (this.isChart && this.config.chartJoins) {
            this.getData();
        }
    }

    removeSpecialisationFilter() {
        this.isSpecialisationFilterDisabled = true;
        this.isSpecialisationFilter = false;
        this.getData();
    }

    handleHeaderLinkClick(event: MouseEvent) {
        event.stopPropagation();

        const state = this.config.headerState;
        if (!isDefined(state.acl) || isDefined(state.acl) && this.ACL.check(state.acl)) {
          this.stateParser.goToState({
            name: state.name,
            id: state.key && { [state.key]: state.id } as unknown as string,
          });
        }

    }

    handleQuickFilter(filter: TableFilter) {
        this.setFilter(findByProperty(this.columns, 'key', filter.key), filter.value);
    }

    resetRowClasses() {
      this.rowClass = {};
    }

    setRowClasses() {

        this.payload?.data?.forEach((row: unknown, index) => {
          let className = '';

          if (!!this.config.onRowClick) className += 'Clickable ';

          if (this.config?.rowMarker) {
            const rowClassName = this.config?.rowMarker(row);
            if (rowClassName) className += `${rowClassName} `;
          }

          this.rowClass[index] = className;
        });
    }

  repositionItems() {
    this.MatLegacyDialog.open(EntityRepositionDialogComponent, { data: { entity: this.config.entity, params: this.config.extraParameters } })
      .afterClosed()
      .subscribe(() => {
        this.getData();
      });
  }

    handleShortcutAdd() {
        if (this.addButton) {
            if (this.addButton.show)
                this.addButton.callback(new MouseEvent('click', {}));
        }
    }

    isFilterValue = (value: unknown) => {
        return isDefinedNotNull(value);
    };

    clearAllFilters() {
        this.columns.forEach((col) => {
            this.setFilter(col, null);
        });
        setTimeout(() => this.getData(), 1000);
    }

    checkFilterStatus() {
        return !this.columns.some((col) => col.filter?.value);
    }
}
