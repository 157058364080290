import { Inject, Injectable } from '@angular/core';
import { CONFIG } from '../config';
import { Entity } from './entity.service';
import { RequestService } from './request.service';
import { PreferencesService } from './preferences.service';
import {
  getPublicSystemOptions,
  loadNamespaces,
  loadPublicSystemOptions,
  setUpdateStatus
} from '../store/system-options';
import { Store } from '@ngrx/store';
import { filter } from '@proman/rxjs-common';
import { loadLanguage } from '@translations/json-language-loader';
import { getCurrUser } from '@proman/store/curr-user';

@Injectable({ providedIn: 'root' })
export class AppInitService {
    translations: { [key: string]: any } = {};
    currentLang: string;

    constructor(
        @Inject(Store) private store: Store,
        @Inject(Entity) private Entity: Entity,
        @Inject(RequestService) private Request: RequestService,
        @Inject(PreferencesService) private Prefs: PreferencesService,
    ) {

    }

    private getParameterByName(name: string) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');

        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    loadTranslations(lang: string): Promise<any> {
        // console.log('loadTranslations', lang);
        return loadLanguage(lang)
            .then((response) => {
                // console.log('loadTranslations res', response);
                this.translations[lang] = response;
                this.currentLang = lang;

                return response;
            });

    }

    getPublicOptions(): Promise<void> {
      let namespacesInited: boolean;
        return new Promise((resolve) => {
            this.store.dispatch(loadPublicSystemOptions());
            this.store.select(getPublicSystemOptions)
               .pipe(filter((publicOptions) => Object.keys(publicOptions).length > 0))
               .subscribe(async (publicSystemOptions) => {
                   await this.loadTranslations(this.Prefs.language() || publicSystemOptions.defaultLanguage);
                   if (publicSystemOptions.corporate || publicSystemOptions.corporateChild) {
                     this.store.select(getCurrUser).subscribe((user) => {
                       if (user && !namespacesInited) {
                         namespacesInited = true;
                         this.store.dispatch(loadNamespaces());
                       }
                     });
                   }
                   resolve();
               });

        });
    }

    initialize() {
        const triggerUpdate = () => {
            this.store.dispatch(setUpdateStatus({ payload: true }));
        };

        return this.Request.get(`${CONFIG.root}ping`)
            .then((response: any) => {
                return response.deploy ?
                    triggerUpdate() :
                    this.getPublicOptions();

            })
            .catch(() => triggerUpdate());

    }

}
