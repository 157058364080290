import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'root-input',
    template: `
        <ng-container [ngSwitch]="type">
            <pro-text-simple *ngSwitchCase="'text'"
                             [value]="value"
                             [config]="config"
                             (onChange)="handleChange($event)"></pro-text-simple>
            <root-checkbox *ngSwitchCase="'boolean'"
                           [value]="value"
                           [config]="config"
                           (onChange)="handleChange($event)"></root-checkbox>
            <pro-select *ngSwitchCase="'select'"
                        [value]="value"
                        [config]="config"
                        [options]="config.options"
                        (onChange)="handleChange($event)"></pro-select>
            <pro-color *ngSwitchCase="'color'"
                       [value]="value"
                       [config]="config"
                       (onChange)="handleChange($event)"></pro-color>
        </ng-container>

    `
})

export class InputComponent {
    @Input() type: string;
    @Input() value: any;
    @Input() config: any;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(

    ) {

    }

    handleChange = (value: any) => {
        this.onChange.emit(value);
    };

}
