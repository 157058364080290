import { Component, OnDestroy, OnInit } from '@angular/core';
import { RequestService } from '@proman/services/request.service';
import { MasterModalService } from '../services/master-modal.service';
import { Entity } from '@proman/services/entity.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { ACL } from '@proman/services/acl.service';
import { ObservablesService } from '@proman/services/observables.service';
import { ServerEntityInterface } from '@proman/resources/server';
import { ActionConfirmDialogComponent } from '../dialogs/action-confirm-dialog.component';
import { Dialog } from '../services/dialog.service';
import { EntityCreateDialogComponent } from '../dialogs/entity-create-dialog.component';
import { ServerLogPreviewDialogComponent } from '../dialogs/server-log-preview-dialog.component';
import { TableConfig } from '@proman/interfaces/object-interfaces';
import { Container, Server, ServerType } from '@proman/interfaces/entity-interfaces';
import { Router } from '@angular/router';

@Component({
    selector: 'root-servers',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [loadWhen]="tableConfig"></pro-table>
            </div>
        </div>
    `,
    styles: [`
        td {
            vertical-align: middle;
        }

        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class ServersComponent implements OnInit, OnDestroy {
    query: string;
    servers: Server[];
    serverEntity: ServerEntityInterface;
    tableConfig: TableConfig;
    containerStatusChannel: string = '/master/container/status';
    types: ServerType[];

    constructor(
        private Request: RequestService,
        private Modal: MasterModalService,
        private Entity: Entity,
        private WS: WebsocketService,
        public ACL: ACL,
        private Router: Router,
        private Dialog: Dialog,
        private Observables: ObservablesService,
    ) {
        this.serverEntity = this.Entity.get('serv') as ServerEntityInterface;
        this.types = ['rr', 'mysql', 'git', 'redis', 'mqtt'];
    }

    ngOnInit() {
        this.setTableConfig();
        this.subMqtt();
    }

    ngOnDestroy() {
        this.unsubMqtt();
    }

    setTableConfig() {
        this.tableConfig = {
            entity: 'serv',
            tableId: 'servers',
            aclRoot: 'server',
            preventFixedHeader: true,
            addButton: {
                acl: 'server.create',
                callback: () => this.createServer(),
            },
            fields: [
                {
                    name: 'Server ID',
                    key: 'id',
                },
                {
                    name: 'Group',
                    key: 'group',
                },
                {
                    name: 'Type',
                    key: 'type',
                },
                {
                    name: 'Host',
                    key: 'host',
                },
                {
                    name: 'IP',
                    key: 'ip',
                },
                {
                    name: 'Status',
                    key: 'status',
                },
                {
                    name: 'Enabled',
                    key: 'isEnabled',
                    formatter: 'iconBoolean',
                    filter: {
                        key: 'id',
                        type: 'dropdown',
                        options: [
                            {
                                name: 'yes',
                                id: 'true'
                            },
                            {
                                name: 'no',
                                id: 'false'
                            }
                        ]
                    }
                },
                {
                    name: 'Comment',
                    key: 'comment',
                }
            ],
            rowButtons: [
                {
                    icon: 'plus',
                    tooltip: 'Create container',
                    action: 'create',
                    callback: (row: Server) => this.addContainer(row),
                },
                {
                    icon: 'sync',
                    tooltip: 'Update all containers',
                    action: 'edit',
                    callback: (row: Server) => this.updateAll(row),
                },
                {
                    icon: 'eye',
                    show: () => false,
                    tooltip: 'View server containers',
                    acl: 'container.view',
                    defaultRowAction: true,
                    callback: (row: Server) => this.Router.navigate(['containers'], { queryParams: { server: row.id } }),
                }
            ]
        }
    }

    test() {
        this.Request.get(this.query)
            .then((response: any) => {
                console.log('response', response);
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    createServer() {
        this.Dialog.open(EntityCreateDialogComponent, {
            header: 'New server',
            mainField: {
                key: 'host',
                config: { required: true, label: 'host' },
                type: 'text',
            },
            parameters: [
                {
                    key: 'ip',
                    type: 'text',
                    config: { label: 'IP', required: true },
                },
                {
                    key: 'port',
                    type: 'text',
                    config: { label: 'port' },
                },
                {
                    key: 'localIp',
                    type: 'text',
                    config: { label: 'Local Ip' },
                },
                {
                    key: 'sshKey',
                    type: 'text',
                    config: { label: 'SSH Key' },
                },
                {
                    key: 'username',
                    type: 'text',
                    config: { label: 'username' }
                },
                {
                    key: 'password',
                    type: 'text',
                    config: { label: 'password', type: 'password' },
                },
                {
                    key: 'isEnabled',
                    type: 'boolean',
                    config: { label: 'Enabled' }
                },
                {
                    key: 'setupServer',
                    type: 'boolean',
                    config: { label: 'Setup server' }
                },
                {
                    key: 'status',
                    type: 'text',
                    config: { label: 'status' },
                },
                {
                    key: 'type',
                    type: 'select',
                    config: { label: 'type', required: true, options: this.types }
                },
                {
                    key: 'comment',
                    type: 'text',
                    config: { label: 'comment' }
                }
            ]
        }).then(() => this.loadServers());
    }

    loadServers() {
        this.serverEntity.search({ join: ['containers'] })
            .then((response) => {
                this.servers = response;
            });
    }

    removeServer(server: Server) {
        this.serverEntity.remove({ id: server.id })
            .then(() => {
               this.loadServers();
            });
    }

    addContainer(server: Server) {
        this.Dialog.open(EntityCreateDialogComponent, {
            header: 'New container',
            mainField: {
                key: 'domain',
                type: 'text',
                config: { required: true, label: 'Domain' }
            },
            parameters: [
                {
                    key: 'email',
                    type: 'text',
                    config: { label: 'Email', validators: { email: true } },
                },
                {
                    key: 'database',
                    type: 'text',
                    config: { label: 'database', required: true },
                },
                {
                    key: 'allowUpdateDb',
                    type: 'boolean',
                    config: { label: 'Allow database update' },
                },
                {
                    key: 'checkHealth',
                    type: 'boolean',
                    config: { label: 'Check health' },
                },
                {
                    key: 'isCorporate',
                    type: 'boolean',
                    config: { label: 'Corporate' },
                },
                {
                    key: 'mqttLocal',
                    type: 'boolean',
                    config: { label: 'Local MQTT' },
                },
                {
                    key: 'mysqlLocal',
                    type: 'boolean',
                    config: { label: 'Local MySQL'},
                },
                {
                    key: 'redisLocal',
                    type: 'boolean',
                    config: { label: 'Local Redis'},
                },
                {
                    key: 'useMultiNamespace',
                    type: 'boolean',
                    config: { label: 'Use multinamespace' },
                }
            ],
            params: { server: server.id },
        }).then(() => this.loadServers());
    }

    updateAll(server: Server) {
        const text = `Update server (${server.id}) containers?`;
        const callback = () => {
            this.serverEntity.updateAll({ id: server.id })
                .then(() => this.loadServers());
        };

        this.Dialog.open(ActionConfirmDialogComponent, { text }).then((response: any) => {
            if (response === 1) {
                callback();
            }
        });
    }

    downloadDatabase(container: Container) {
        console.log('downloadDatabase', container);
    }

    uploadDatabase(container: Container) {
        console.log('uploadDatabase', container);
    }

    subMqtt() {
        this.WS.subscribeChannel('/master/container/status', (data: { container: string, status: string }) => {

            this.servers.forEach((server) => {
               server.containers.forEach((container) => {
                 if (container.domain === data.container) container.status = data.status;
               });
            });

        });

        this.WS.subscribeChannel('/master/container/version', (data: {
            container: string;
            version: string;
            status: string;
            date: string;
        }) => {
            this.servers.forEach((server) => {
               server.containers.forEach((container) => {
                  if (container.domain === data.container) {
                      container.version = data.version;
                      container.status = data.status;
                      container.updatedAt = data.date;
                  }
               });
            });
        });
    }

    unsubMqtt() {
        this.WS.unsubscribeChannel('/master/container/status');
    }

    showServerLogs(container: Container) {
        this.Dialog.open(ServerLogPreviewDialogComponent, { container });
    }

    updateContainerData(container: Container, property: string, value: any) {
        this.Entity.get('container')
            .update({ id: container.id, [property]: value })
            .then(() => container[property] = value);
    }

}
