import { Injectable } from '@angular/core';
import { TableStateParser, TableStateParserType } from '@proman/table';

@Injectable({ providedIn: 'root' })
export class MasterTableStateParserService implements TableStateParser {

  constructor() {}

  getPath(data: TableStateParserType): [string[], any] {
    return [[], undefined];
  }

  goToState(data: TableStateParserType): void {
  }

}
