import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@proman/services/auth.service';
import { LoginProject } from '@proman/interfaces/object-interfaces';

@Component({
    selector: 'root-login',
    template: `
        <div class="hero">
            <div class="hero-body is-centered fl-row fl-center">
                <div class="LoginForm fl-column fl-center">
                    <img src="../../assets/img/smarton_logo_ball_master.png"><br>
                    <pro-text-simple [value]="login"
                                     [config]="{ label: 'Username' }"
                                     (onChange)="set('login', $event)"></pro-text-simple>
                    <pro-text-simple [value]="password"
                                     [config]="{ label: 'Password', type: 'password', debounce: 0 }"
                                     (onChange)="set('password', $event)"></pro-text-simple>

                    <pro-btn class="LoginForm" label="Login"
                             (onClick)="handleLogin()"
                             [disabled]="!login || !password"
                             [theme]="'accent'"
                    ></pro-btn>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .LoginForm {
            width: 350px;
        }

        .hero {
            height: 100%;
            height: 100vh;
        }

        img {
            max-height: initial!important;
        }
    `]
})

export class LoginComponent implements OnDestroy{
    login: string;
    password: string;

    constructor(
        private Router: Router,
        private Auth: AuthService,
    ) {
        window.addEventListener('keydown', this.handleEnter);
    }

    ngOnDestroy() {
        window.removeEventListener('keydown', this.handleEnter);
    }

    set(property: string, value: string) {
        this[property] = value;
    }

    handleEnter = (event: KeyboardEvent) => {
        if (this.login && this.password) {
            if (event.code === 'Enter' || event.key === 'Enter' || event.code === 'Enter') {
                this.handleLogin();
            }
        }
    }

    handleLogin() {
        this.Auth.login({ _username: this.login, _password: this.password, project: LoginProject.MASTER }).then(() => {
          this.Router.navigate(['/servers']);
        });
    }

}
