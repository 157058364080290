import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login.component';
import { RegistractionComponent } from './pages/registraction.component';
import { ServersComponent } from './pages/servers.component';
import { MasterGuardService } from './services/master-guard.service';
import { LanguagesComponent } from './pages/languages.component';
import { PersonsComponent } from './pages/persons.component';
import { DefaultRouteComponent } from './pages/default-route.component';
import { ServerStatsChartComponent } from './components/server-stats-chart.component';
import { NotificationsComponent } from './pages/notifications.component';
import { ContainersComponent } from './pages/containers.component';
import { AppComponent } from './app.component';
import { UserResolver } from './app-resolvers';
import { FiscalsComponent } from './pages/fiscals.component';
import { FiscalsStatsComponent } from './pages/fiscals-stats.component';
import { PublicContainerCreateComponent } from './components/public/public-container-create.component';
import { PublicContainerCreateProchefComponent } from './components/public/public-container-create-prochef.component';
import { PublicContainerCreateSmartonProductionComponent } from './components/public/public-container-create-smarton-production.component';
import { PublicContainerCreateSmartonTrProductionComponent } from './components/public/public-container-create-smarton-tr-production.component';
import { PublicContainerCreateSmartonUaProdComponent } from './components/public/public-container-create-smarton-ua-prod.component';
import { PublicContainerCreateSmartonUaComponent } from './components/public/public-container-create-smarton-ua.component';
import { PublicContainerCreateSmartonTrComponent } from './components/public/public-container-create-smarton-tr.component';
import { PublicContainerCreateSmartonComponent } from './components/public/public-container-create-smarton.component';
import { PublicContainerCreatePromanComponent } from './components/public/public-container-create-proman.component';
import { GitBuildsComponent } from './pages/builds-component';
import { MasterTagsComponent } from './pages/tags.component';
import { MasterTalkRoomComponent } from './pages/master-talk-room.component';
import { PublicContainerCreateSmartonLtComponent } from './components/public/public-container-create-smarton-lt.component';
import { PublicContainerCreateSmartonLtProductionComponent } from './components/public/public-container-create-smarton-lt-production.component';
import { PublicFiscalFormComponent } from './components/public/public-fiscal-form.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'public/create', component: PublicContainerCreateComponent },
  { path: 'public/create/prochef', component: PublicContainerCreateProchefComponent },
  { path: 'public/create/proman', component: PublicContainerCreatePromanComponent },
  { path: 'public/create/smarton', component: PublicContainerCreateSmartonComponent },
  { path: 'public/create/tr/smarton', component: PublicContainerCreateSmartonTrComponent },
  { path: 'public/create/ua/smarton', component: PublicContainerCreateSmartonUaComponent },
  { path: 'public/create/production/smarton', component: PublicContainerCreateSmartonProductionComponent },
  { path: 'public/create/tr/production/smarton', component: PublicContainerCreateSmartonTrProductionComponent },
  { path: 'public/create/ua/production/smarton', component: PublicContainerCreateSmartonUaProdComponent },
  { path: 'public/create/lt/smarton', component: PublicContainerCreateSmartonLtComponent },
  { path: 'public/create/lt/production/smarton', component: PublicContainerCreateSmartonLtProductionComponent },
  { path: 'public/fiscal-form', component: PublicFiscalFormComponent },

  { path: '', component: AppComponent, resolve: { user: UserResolver }, children: [
      { path: '', pathMatch: 'full', component: DefaultRouteComponent, canActivate: [MasterGuardService] },
      { path: 'servers', component: ServersComponent, canActivate: [MasterGuardService] },
      { path: 'servers-stats', component: ServerStatsChartComponent, canActivate: [MasterGuardService] },
      { path: 'containers', component: ContainersComponent, canActivate: [MasterGuardService] },
      { path: 'languages', component: LanguagesComponent, canActivate: [MasterGuardService] },
      { path: 'persons', component: PersonsComponent, canActivate: [MasterGuardService] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [MasterGuardService] },
      { path: 'register', component: RegistractionComponent },
      { path: 'builds', component: GitBuildsComponent },
      { path: 'tags', component: MasterTagsComponent },
      { path: 'fiscals', component: FiscalsComponent, canActivate: [MasterGuardService] },
      { path: 'fiscals-stats', component: FiscalsStatsComponent, canActivate: [MasterGuardService] },
      { path: 'talk-room', component: MasterTalkRoomComponent },
      { path: '**', component: DefaultRouteComponent, canActivate: [MasterGuardService] }
    ]
  },
  { path: '**', component: DefaultRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
