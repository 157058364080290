import { Component, Inject } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Container } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'root-container-update-dialog',
    template: `
        <pro-dialog-title [title]="'Update ' + container.namespace + '.' + container.domain + '?'"></pro-dialog-title>
        <div mat-dialog-content>
            <root-checkbox [value]="mysqlLocal"
                           [config]="{ label: 'Use local mysql' }"
                           (onChange)="updateLocalMysql($event)"></root-checkbox>
        </div>
        <pro-dialog-actions (callback)="update()" [variant]="'update'"></pro-dialog-actions>
    `
})

export class ContainerUpdateDialogComponent {
    container: Container;
    mysqlLocal: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        public dialogRef: MatLegacyDialogRef<ContainerUpdateDialogComponent>,
    ) {
        this.container = data.container;
    }

    set = (number: string) => {
    }

    updateLocalMysql = (value: any) => {
        this.mysqlLocal = value;
        this.Entity.get('container').update({ id: this.container.id, mysqlLocal: this.mysqlLocal });
    }

    update() {
        this.Entity.get('container').updateContainer({id: this.container.id}).then(() => this.dialogRef.close(1));
    }

    close() {
        this.dialogRef.close();
    }
}
