import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import { isDefined, isDefinedNotNull, isNumber } from '@proman/utils';
import { FormControl, Validators } from '@angular/forms';
import { EmailValidator } from './email.validator';
import { Subscription, fromEvent as observableFromEvent, merge as observableMerge, } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from '@proman/rxjs-common';
const DEFAULT_DEBOUNCE_TIME = 5000;

@Component({
  selector: 'root-text',
  template: `
        <div class="field">
            <div class="control" [attr.data-name]="config.label">
                <label class="label" *ngIf="config.label && !config.hideLabel">{{ config.label | translate }}{{ config.required &&  '*' || '' }}</label>
                <input class="input"
                       #box
                       [type]="config.type || 'text'"
                       [placeholder]="(config.label || '') | translate"
                       [value]="isDefinedNotNull(value) ? value : ''"
                       [formControl]="control"
                       (focus)="handleFocus($event)"
                       (keydown.enter)="onReturn.emit(value)">
            </div>
        </div>
    `
})

export class TextComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() value: any;
    @Input() config: {
        label?: string;
        validators?: {
            range?: { min?: number; max?: number };
            length?: { min?: number; max?: number };
            unique?: { resource: string; field?: string };
            noComma?: boolean;
            number?: boolean;
            email?: boolean;
        };
        parseNumber?: boolean;
        type?: 'text'|'password'|'textarea'|'number';
        autofocus?: boolean;
        required?: boolean;
        debounce?: number;
        trim?: boolean;
        preventNewLine?: boolean;
        floatLabel?: 'always' | 'auto';
        prefix?: string;
        suffix?: string;
        hideLabel?: boolean;
    };
    @Input() disabled: boolean;
    @Input() control: FormControl;
    @Output() onChange: EventEmitter<string> = new EventEmitter();
    @Output() onFocus: EventEmitter<string> = new EventEmitter();
    @Output() onBlur: EventEmitter<string> = new EventEmitter();
    @Output() onReturn: EventEmitter<string> = new EventEmitter();
    @ViewChild('box', { static: true }) box: ElementRef;
    emittedValue: string;
    blurSubscription: Subscription;
    inputSubscription: Subscription;
    enterSubscription: Subscription;
    parseNumberSubscription: Subscription;

    constructor(

    ) {

    }

  ngOnInit() {
    if (!isDefined(this.value)) this.value = '';

    this.setControl();
  }

    isDefinedNotNull = (value: any) => {
        return isDefinedNotNull(value);
    };

    getEventObservable(eventName: string) {
        return observableFromEvent(this.box.nativeElement, eventName).pipe(
            map(this.mapEventData));
    }



    mapEventData = (event: any) => {
        return event.target.value;
    };

   setControl() {
        let config = this.config;
        let validators: any = [];
        let asyncValidator;

        if (!this.control) {

            // Set validators
            if (config.validators) {

              /*  if (config.validators.range) validators.push(RangeValidator(config.validators.range));

                if (config.validators.number) validators.push(NumberValidator);

                if (config.validators.noComma) validators.push(NoCommaValidator);*/

                if (config.validators.email) validators.push(EmailValidator);

                if (config.validators.length) {
                    if (config.validators.length.min) validators.push(Validators.minLength(config.validators.length.min));
                    if (config.validators.length.max) validators.push(Validators.maxLength(config.validators.length.max));

                }
            }

            if (config.required) validators.push(Validators.required);

            this.control = new FormControl(isDefinedNotNull(this.value) ? this.value : '',  validators, asyncValidator);

        }

        if (this.disabled) this.control.disable();

    }

    ngAfterViewInit() {

        const getDebounceTime = () => {
            let debounce = this.config.debounce;

            return (!isNaN(debounce)) ? debounce : DEFAULT_DEBOUNCE_TIME;
        };

        this.enterSubscription = observableFromEvent(this.box.nativeElement, 'keydown')
            .pipe(
                filter((event: KeyboardEvent) => (event.code === 'Enter' && this.config.type !== 'textarea'))
            )
            .subscribe((event: any) => this.handleChange(event.target.value));

        this.inputSubscription = observableMerge(
            this.getEventObservable('blur'),
            this.control.valueChanges.pipe(debounceTime(getDebounceTime()))
        ).pipe(
            distinctUntilChanged())
            .subscribe((value: string) => {
                // allow string / number comparison
                // tslint:disable-next-line

                if (this.config.validators && (this.config.validators.email)) {
                    value = value.trim();
                }

                if (value != this.value) {
                    if (this.control.pending) {
                        let Subscription = this.control.statusChanges.subscribe(() => {
                            this.handleChange(value);
                            Subscription.unsubscribe();
                        });

                    } else {
                        this.handleChange(value);

                    }

                }

            });

        this.blurSubscription = observableFromEvent(this.box.nativeElement, 'blur').pipe(
            distinctUntilChanged())
            .subscribe((value: any) => {
                this.onBlur.emit();
                this.handleBlur();
            });

        if (this.config.autofocus) setTimeout(() => this.box.nativeElement.focus());

        if (this.config.parseNumber) {
            this.parseNumberSubscription = observableFromEvent(this.box.nativeElement, 'keyup')
                .subscribe((event: any) => this.handleParseNumber(event));

        }

    }

  ngOnChanges(changes: SimpleChanges) {
    let value = changes.value;

    if (value) {
      this.value = value.currentValue;
    }
  }

  ngOnDestroy() {
      if (this.inputSubscription) this.inputSubscription.unsubscribe();
      if (this.enterSubscription) this.enterSubscription.unsubscribe();
      if (this.blurSubscription) this.blurSubscription.unsubscribe();

      if (this.config && this.config.parseNumber) this.parseNumberSubscription.unsubscribe();

  }

    handleChange = (value: string) => {
        if (this.config.trim) value = value.trim();

        if (this.control.valid) {
            this.onChange.emit(value);
            this.emittedValue = value;

        }

    };

  handleFocus(event: any) {
      this.onFocus.emit();
  }

  handleParseNumber(value: any) {
    if (value.indexOf(',') > -1) value = value.replace(',', '.');

    value = value.replace(/^0+(?!\.|$)/, ''); // remove zeros from start

    return value;
  }

    handleBlur() {

        if (this.config.required) {
            this.control.setValue(this.emittedValue || this.value);

        }

        this.onBlur.emit();

    }

}
