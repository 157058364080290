import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import {Entity} from '@proman/services/entity.service';
import { mapId, isArray } from '@proman/utils';
import {FormGroup} from '@angular/forms';
import * as lodash from "lodash";

@Component({
    selector: 'root-entity-edit-dialog',
    template: `
        <form #editForm="ngForm"
              [formGroup]="form">
            <pro-dialog-title [title]="data.header || 'edit'"></pro-dialog-title>
            <div mat-dialog-content>
                <root-input *ngFor="let parameter of parameters"
                            [value]="parameter.value"
                            [type]="parameter.type"
                            [config]="parameter.config"
                            (onChange)="set(parameter, $event)"
                ></root-input>
            </div>
            <pro-dialog-actions></pro-dialog-actions>
        </form>
    `
})

export class EntityEditDialogComponent {
    parameters: any;
    Entity: any;
    form: FormGroup;
    controls: any = {};

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        Entity: Entity,
        public dialogRef: MatLegacyDialogRef<EntityEditDialogComponent>
    ) {
        let mainField: any = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: {
                required: true,
                validators: { unique : { resource: this.data.entity, field: 'name' } }
            }
        };

        if (this.data.entity) this.Entity = Entity.get({ name: this.data.entity });

        this[this.data.entity] = {};

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) {
            this.parameters = [].concat(this.parameters, this.data.parameters);

        }

        for (let parameter of this.parameters) {

            if (parameter.config && parameter.config.control) {
                this.controls[parameter.key] = parameter.config.control;

            }

            parameter.value = this.data.item[parameter.key];

        }

        this.form = new FormGroup(this.controls);
    }

    set(parameter: any, value: any) {
        let property = parameter.key;
        let data = { id: this.data.item.id };
        let method = 'update';

        data[property] = value && value.id ? value.id : value;

        // for files
        if (isArray(value) && value.length /* && isFile(value[0]) || property === 'files'|| property === 'logo' */) {
            data[property] = value.map(mapId);
            method = 'addAssociation';

            value.map(mapId).forEach((id: number) => {
                if (id < 0) {
                    method = 'removeAssociation';
                    data[property] = Math.abs(id);
                }
            });

        }

      // for entity associations
      const isAssociation = parameter && parameter.config && parameter.config.associations;
      if (isAssociation) {
        const arr = this.data.item[property];
        const newArr = value;
        if (this.data.item[property].length > value.length) {
          method = 'removeAssociation';
          value = lodash.differenceBy(arr, value, 'id')[0];
        } else {
          method = 'addAssociation';
          value = lodash.differenceBy(value, arr, 'id')[0];
        }
        value = value.id;
        data[property] = value;
        this.data.item[property] = newArr;
      }

      if (!isAssociation) this.data.item[property] = value;

        if (this.data.params) Object.assign(data, this.data.params);

        if (this.Entity) {
            if (this.data.method) {
                this.Entity.postName(this.data.method)(data);
            } else {
                this.Entity[method](data);
            }
        }

    }
}
