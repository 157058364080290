import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {EntityCreateDialogComponent} from './entity-create-dialog.component';
import {EntityEditDialogComponent} from './entity-edit-dialog.component';
import {InputsModule} from '../inputs/inputs.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ActionConfirmDialogComponent} from './action-confirm-dialog.component';
import {ContainerUpdateDialogComponent} from './container-update-dialog.component';
import {TranslationEditDialogComponent} from './translation-edit-dialog.component';
import {ContainerChangeDatabaseDialogComponent} from './container-change-database-dialog.component';
import {ContainerLogPreviewDialogComponent} from './container-log-preview-dialog.component';
import {ServerLogPreviewDialogComponent} from './server-log-preview-dialog.component';
import {PermissionsEditDialogComponent} from './permissions-edit-dialog.component';
import {PermissionsComponent} from '../components/permissions.component';
import {ActionListenerCreateDialogComponent} from './action-listener-create-dialog.component';
import {ActionListenerEditDialogComponent} from './action-listener-edit-dialog.component';
import {CronBuilderComponent} from '../components/cron-builder.component';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { FiscalDialogComponent } from "./fiscal-dialog.component";
import { PromanTextSimpleComponent } from "@proman/text-simple/proman-text-simple.component";
import { PromanSelectComponent } from "@proman/select";
import { PromanCheckboxComponent } from "@proman/checkbox/proman-checkbox.component";
import { PromanButtonComponent } from "@proman/button/proman-button.component";
import { PermissionsService } from '@proman/services/permissions.service';
import { MatTabsModule } from '@angular/material/tabs';

const COMPONENTS = [
    ActionConfirmDialogComponent,
    ActionListenerCreateDialogComponent,
    ActionListenerEditDialogComponent,
    EntityCreateDialogComponent,
    EntityEditDialogComponent,
    ContainerUpdateDialogComponent,
    ContainerChangeDatabaseDialogComponent,
    ContainerLogPreviewDialogComponent,
    PermissionsEditDialogComponent,
    ServerLogPreviewDialogComponent,
    TranslationEditDialogComponent,
    FiscalDialogComponent,

    PermissionsComponent,
    CronBuilderComponent,
]
@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        MatLegacyProgressBarModule,
        MatLegacyMenuModule,
        MatLegacyDialogModule,
        MatTabsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        InputsModule,
        SharedDirectivesModule,
        PipesModule,
        PromanCommonComponentsModule,
        PromanTextSimpleComponent,
        PromanSelectComponent,
        PromanCheckboxComponent,
        PromanButtonComponent,
    ],
    providers: [
      PermissionsService,
    ],
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ]
})

export class DialogsModule {}
