import { Component, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { isMobile, safeMapId } from '@proman/utils';
import moment from 'moment';
import * as _echarts from 'echarts';
import { Server, ServerLog } from "@proman/interfaces/entity-interfaces";

const echarts: any = _echarts;

const COLORS = [
    '#80d4ff',
    '#ffdd99',
    '#c65dff',
    '#ff5026',
    '#ff7c49',
    '#3691ff',
    '#fff665',
    '#dda3ff',
    '#9eff76',
    '#8449ff',
];

@Component({
    selector: 'root-server-stats-chart',
    template: `
        <div class="hero">
            <root-header></root-header>
            <div class="fl-row fl-end" style="padding: 5em 16px 0 0">
                <root-select [value]="recordCount"
                             [options]="timeOptions"
                             [config]="{ label: 'Time', key: 'id', displayKey: 'name', isObject: true }"
                             (onChange)="handleTimeChange($event)"
                >

                </root-select>
            </div>
            <div *ngFor="let chart of charts; let $index = index;" id="serverstatuschart{{$index}}" class="ServerStatusChart"></div>
        </div>
    `,
    styles: [`
        .ServerStatusChart {
            padding: 0 1em;
        }

        .ServerStatusChart {
            height: 300px;
            width: 100%;
        }
    `]
})

export class ServerStatsChartComponent implements OnInit {
    servers: Server[];
    chartServers: Server[];
    charts: Array<{ name: string; key: string; realValues?: boolean }> = [
        {
            key: 'memCashe',
            name: 'Memory cache %'
        },
        {
            key: 'memUsed',
            name: 'Memory used %'
        },
        {
            key: 'processorLoad',
            name: 'CPU load %'
        },
        {
            key: 'diskLoad',
            name: 'Disk load %'
        },
        {
            key: 'networkLoad',
            name: 'Network load %',
        },
        {
          key: 'replicaDiff',
          name: 'Replica diff',
          realValues: true,
        },
    ];

    timeOptions: any[] = [
        { name: 'Hour', id: 60 },
        { name: '2 Hours', id: 120 },
        { name: '4 Hours', id: 240 },
        { name: '8 Hours', id: 480 },
        { name: '12 Hours', id: 720 },
        { name: '1 Day', id: 1440 },
        { name: '3 days', id: 4320 },
        { name: '5 days', id: 7200 },
        { name: '7 days', id: 10080 },
    ];
    recordCount: number = 120;

    constructor(
        private Entity: Entity,
    ) {

    }

    ngOnInit() {
        this.Entity.get('serv')
            .search()
            .then((response) => {
                this.servers = response;

                this.loadData();
            });
    }

    handleTimeChange(value: number) {
        this.recordCount = value;
        this.loadData();
    }

    loadData() {
        const idMap = safeMapId(this.servers);
        const serverLogEntity = this.Entity.get('serv_log');
        const validServerIndex: number[] = [];

        if (idMap.length) {

            let requests = idMap.map((id) => {
                return serverLogEntity
                    .search({
                        'server.id': id,
                        limit: this.recordCount,
                        sort: { timestamp: 'desc' },
                    });
            });

            Promise.all(requests)
                .then((values: ServerLog[][]) => {

                    this.chartServers = [];
                    const realValues = values
                        .filter((data, index) => {
                            if (!data.length) return false;
                            validServerIndex.push(index);
                            this.chartServers.push(this.servers[index]);
                            return true;
                        });

                    realValues.forEach((data, index) => {
                        data.sort((a, b) => (a.timestamp < b.timestamp) ? -1 : 1);
                    });

                    this.charts
                        .forEach((chartItem: any, index) => {
                        let myChart = echarts.init(document.getElementById(`serverstatuschart${index}`) as HTMLDivElement);
                        let _series = {};

                        for (let i = 0; i < realValues.length; i++) {
                            _series[`serie${i}`] = {
                                name: this.chartServers[i].host,
                                type: 'line',
                                stack: `a${i}`,
                                data: realValues[i].map((item) => item[chartItem.key]),
                                itemStyle: { normal: { color: COLORS[i] } },
                            };
                        }

                        let seriesArray = [];

                        for (let key of Object.keys(_series).reverse()) {
                            seriesArray.push(_series[key]);
                        }

                        let series = seriesArray;

                        let option: any = {
                            tooltip : {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    label: {
                                        backgroundColor: '#6a7985'
                                    }
                                }
                            },
                            toolbox: {
                                show: true,
                                orient: 'vertical',
                                left: 'right',
                                top: 'center',
                                feature: {
                                    magicType: {
                                        show: true,
                                        type: ['stack', 'tiled'],
                                        title: {
                                            stack: 'stack',
                                            tiled: 'tiled',
                                        },
                                    },
                                }
                            },
                            grid: {
                                left: '16px',
                                right: '48px',
                                bottom: '32px',
                                containLabel: true
                            },
                            legend: {
                                data: this.chartServers.map((item) => item.host)
                            },
                            xAxis : [
                                {
                                    type : 'category',
                                    boundaryGap : false,
                                    data : realValues[0].map((item) => moment(item.timestamp * 1000).format('MMM DD HH:mm')),
                                    name: 'time',
                                    nameLocation: 'end'
                                }
                            ],
                            yAxis : [
                                {
                                    type : 'value',
                                    name: chartItem.name,
                                    nameLocation: 'middle',
                                    nameRotate: 90,
                                    nameGap: 30,
                                    min: chartItem.realValues ? null : 0,
                                    max: chartItem.realValues ? null : 100,
                                }
                            ],
                            series
                        };

                        if (isMobile()) {
                            delete option.legend;
                        }

                        myChart.setOption(option);

                    });



                });

        }

    }

}
