import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TalkRoomService } from '../services/talk-room.service';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { CONFIG } from '@proman/config';
import { getCurrUser } from '@proman/store/curr-user';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { ToastService } from '@proman/services/toast.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { isDefinedNotNull } from '@proman/utils';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pro-talk-room',
  template: `
    <div *ngIf="!isInsideRoom" fxLayout="row">
      <pro-btn *ngIf="!selectedRoom"
               [label]="'create_room' | translate"
               [theme]="'accent'"
               (onClick)="createRoom()"></pro-btn>
    </div>
    <div *ngIf="isInsideRoom" fxLayout="column" id="container">
      <div fxLayout="row">
        <pro-btn [label]="'leave_room' | translate"
                 [disabled]="peerConnection?.connectionState !== 'connected'"
                 [theme]="'accent'"
                 (onClick)="leaveRoom()"></pro-btn>
        @if (isSharing) {
          <pro-btn [label]="'stop_sharing' | translate"
                   [disabled]="peerConnection?.connectionState !== 'connected' && systemDataChannel.readyState !== 'open'"
                   [theme]="'accent'"
                   (onClick)="stopSharing()"></pro-btn>
        } @else {
          <pro-btn [label]="'screen_share' | translate"
                   [disabled]="peerConnection?.connectionState !== 'connected' && systemDataChannel.readyState !== 'open'"
                   [theme]="'accent'"
                   (onClick)="screenShare()"></pro-btn>
        }
        <pro-btn icon="bolt"
                 theme="accent"
                 [disabled]="peerConnection?.connectionState !== 'connected' && systemDataChannel.readyState !== 'open'"
                 (onClick)="sendMessage()"></pro-btn>
      </div>
      <div fxLayout="row nowrap">
        <div style="height: auto; width: 50%">
          <video id="localVideo" [srcObject]="localStream" [muted]="true" autoplay playsinline
                 [controls]="false"></video>
        </div>
        <div style="height: auto; width: 50%">
          <video id="incomingVideo" [srcObject]="incomingStream" autoplay playsinline
                 [controls]="false"></video>
          <audio [srcObject]="incomingStream" autoplay></audio>
        </div>
      </div>
    </div>
  `
})

export class TalkRoomComponent implements OnInit, OnDestroy {
  @Input() selectedRoom: any;
  @Output() onLeave: EventEmitter<any> = new EventEmitter<any>();
  currUser: CurrUser;
  namespace: string;
  localStream: MediaStream;
  systemDataChannel: RTCDataChannel;
  incomingStream: MediaStream;
  iceConfiguration = { 'iceServers': [{ 'urls': 'turn:88.119.221.242:3478', 'username': 'proman', 'credential': 'proman11' }] };
  peerConnection: RTCPeerConnection;
  isInsideRoom = false;
  isSharing: boolean;
  connecting = false;
  peerId: number;
  peerNamespace: string;
  supportId: number;
  topic: string;
  screenshareTrack: RTCRtpSender;

  constructor(
    private TalkRoom: TalkRoomService,
    private Websocket: WebsocketService,
    private Toast: ToastService,
    private store: Store,
  ) {
    this.store.select(getPublicSystemOptions).subscribe((value) => {
      this.namespace = value.namespace ?? CONFIG.namespace;
      this.topic = `${this.namespace}/chat-room`;
    });

    this.store.select(getCurrUser).pipe(filter((val) => !!val))
      .subscribe((value) => {
        this.currUser = value;
        this.Websocket.subscribeTopic(`${this.namespace}/person/${value.id}/talk-room`);
        if (!this.selectedRoom) this.Websocket.subscribeTopic(`${this.namespace}/person/${value.id}/talk-room/answer`);
      });

    this.peerConnection = new RTCPeerConnection(this.iceConfiguration);

    this.peerConnection.addEventListener('renegotiationneeded', event => {
      console.debug('reneg needed');
    })

    this.peerConnection.addEventListener('connectionstatechange', () => {
      console.debug('state: ', this.peerConnection?.connectionState);
      if (this.peerConnection?.connectionState === 'connected') {
        this.Toast.pop('success', 'connected');
        console.log('handshake');
      }

      if (this.peerConnection?.connectionState === 'disconnected') {
        this.Toast.pop('info', 'disconnected');
        this.peerConnection.getStats().then(result => {
          console.log('call stats', result);
        })
      }
    })

    this.Websocket.message.subscribe(async (msg) => {
      if (msg.topic === `${this.namespace}/person/${this.currUser.id}/talk-room`) {
        const iceCandidate = JSON.parse(msg.data);
        if (this.peerConnection.remoteDescription) await this.peerConnection.addIceCandidate(iceCandidate);
      }
      if (msg.topic === `${this.namespace}/person/${this.currUser.id}/talk-room/answer`) {
        this.supportId = msg.data;
        console.debug('Master answered, id:', this.supportId);
      }
    })
  }

  ngOnInit() {
    this.peerConnection.addEventListener('track', (event) => {
      console.debug('trackEvent', event);
      if (!this.incomingStream) {
        const [remoteStream] = event.streams;
        if (isDefinedNotNull(remoteStream)) this.incomingStream = remoteStream;
        console.debug('remote stream:', this.incomingStream);
        this.handleIncomingStreamListeners();
      }

      if (event.track) this.peerConnection?.addTrack(event.track);
    });

    if (this.selectedRoom) {
      this.isInsideRoom = true;
      this.connecting = true;
      this.answer(this.selectedRoom);
    }
  }

  ngOnDestroy() {
    this.leaveRoom();
    this.localStream = null;
    this.incomingStream = null;
    this.connecting = false;
    this.selectedRoom = null;
    this.peerNamespace = null;
    this.peerId = null;
    this.supportId = null;
    this.peerConnection = null;
    this.onLeave.emit();
  }

  createRoom() {
    this.isInsideRoom = true;
    this.makeCall();
  }

  leaveRoom(shutdown?: boolean){
    if (!shutdown && this.systemDataChannel && this.systemDataChannel.readyState === 'open') {
      this.systemDataChannel?.send('exit-room');
      this.systemDataChannel?.close();
    }

    this.isInsideRoom = false;
    this.localStream?.getTracks().forEach((track) => track.stop());
    this.incomingStream?.getTracks().forEach((track) => track.stop());
    this.peerConnection.close();
    this.onLeave.emit();
  }

  makeCall = async () => {
    this.systemDataChannel = this.peerConnection.createDataChannel('system');

    this.systemDataChannel.addEventListener('open', event => {
      console.debug('system_channel_opened');
    });

    this.systemDataChannel.addEventListener('close', event => {
      console.debug('system_channel_closed');
    });

    this.handleCommonChannelListeners();
    await this.catchStreams();
    const offer = await this.peerConnection.createOffer();

    this.TalkRoom.createRoom(JSON.stringify(offer)).then(async (response) => {
      const result = JSON.parse(response);
      this.peerId = result.person;
      this.peerNamespace = result.namespace;
      this.peerConnection.addEventListener('icecandidate', event => {
        if (event.candidate && this.supportId) {
          this.Websocket.send(`master/person/${this.supportId}/talk-room`, JSON.stringify(event.candidate));
        }
      });
      await this.peerConnection.setLocalDescription(offer);
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(result.objString)));
    }).then(() => {
      console.debug('makeCall function end');
    });
  };

  answer = async (selectedRoom: any) => {
    this.peerConnection.addEventListener('datachannel', event => {
      console.debug('data channel:', event);
      this.systemDataChannel = event.channel;
      this.handleCommonChannelListeners();
    })

    selectedRoom = await this.TalkRoom.getOffer(selectedRoom);
    console.log(selectedRoom);

    this.peerConnection.addEventListener('icecandidate', event => {
      if (event.candidate && this.peerId) {
        this.Websocket.send(`${this.peerNamespace}/person/${this.peerId}/talk-room`, JSON.stringify(event.candidate));
      }
    });

    if (selectedRoom) {
      await this.catchStreams();
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(selectedRoom.objString)));
      this.peerId = selectedRoom.person;
      this.peerNamespace = selectedRoom.namespace;
      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      this.Websocket.send(`${this.peerNamespace}/person/${this.peerId}/talk-room/answer`, this.currUser.id);
      this.TalkRoom.joinRoom(JSON.stringify(answer), this.peerId, this.peerNamespace).then((result) => {
        console.debug('handshake', result);
        this.connecting = false;
      });
    }
  };

  screenShare = async () => {
    navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: false
    }).then((stream) => {
      this.isSharing = true;
      this.systemDataChannel.send('screenshare-start');
      stream.getTracks().forEach((track) => {
        // this.screenshareTracks.push(track);
        this.screenshareTrack = this.peerConnection.addTrack(track);
        const sender = this.peerConnection.getSenders().find((sender) => sender.track.kind === track.kind);
        console.debug('sender: ', sender);
        sender.replaceTrack(track);
      })
    });
  }

  stopSharing = () => {
    this.isSharing = false;
    this.systemDataChannel.send('screenshare-stop');
    if (this.screenshareTrack) {
      this.screenshareTrack.track.stop();
      this.peerConnection.removeTrack(this.screenshareTrack);
      this.localStream.getTracks().forEach(track => {
        this.peerConnection.getSenders().find((sender) => sender.track.kind === track.kind).replaceTrack(track);
      })
    }
    this.catchStreams();
  }

  catchStreams() {
    const constraints = {
      'video': true,
      'audio': true,
    };

    return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      console.log('mediaStream', stream);
      this.localStream = stream;
      this.localStream.getTracks().forEach(track => {
        console.log('track', track);
        this.peerConnection.addTrack(track, this.localStream);
      })

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log('devices', devices);
      })
    }).catch(err => {
      console.log('ups', err);
    })
  }

  sendMessage() {
    this.Toast.pop('info', 'Sending: ' + 'message');
    this.systemDataChannel.send('test-message');
  }

  handleCommonChannelListeners() {
    this.systemDataChannel.addEventListener('message', (event) => {
      console.log('incoming message: ', event);
      if (event.data === 'exit-room') this.leaveRoom(true);
    })
  }

  handleIncomingStreamListeners() {
    if (this.incomingStream) {
      this.incomingStream.addEventListener('addtrack', (event) => {
        console.debug('incoming track: ', event);
        this.incomingStream?.addTrack(event.track);
      });

      this.incomingStream.addEventListener('removetrack', (event) => {
        this.incomingStream?.removeTrack(event.track);
      })
    }
  }
}
