import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '@proman/services/request.service';
import { Entity } from '@proman/services/entity.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { ContainerEntityInterface } from '@proman/resources/container';
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'root-server-log-preview',
    template: `
        <pro-dialog-title [title]="'logs' | translate"></pro-dialog-title>
        <section class="modal-card-body columns is-marginless">
            <div class="content is-small">
                <ul>
                    <li *ngFor="let log of logs">{{ log }}</li>
                </ul>
            </div>
        </section>
        <pro-dialog-actions></pro-dialog-actions>
    `,
    styles: [ `

    `]
})

export class ServerLogPreviewDialogComponent implements OnInit, OnDestroy {
    key: string;
    params: { refreshCallback: () => void };
    channelName: string = '/master/container/log';
    logs: string[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<ServerLogPreviewDialogComponent>,
        private router: Router,
        private Request: RequestService,
        private Entity: Entity,
        private Websocket: WebsocketService,
    ) {

    }

    ngOnInit() {
        this.params = this.data;

        (this.Entity.get('container') as ContainerEntityInterface)
            .containerLog({ id: this.data.container.id });

        this.Websocket.subscribeChannel(this.channelName, (data: any) => this.logs = data.log);
    }

    ngOnDestroy() {
        this.Websocket.unsubscribeChannel(this.channelName);
    }

    close() {
        this.dialogRef.close();
    }

}
