import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MasterPerson } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'root-permissions-edit',
    template: `
        <pro-dialog-title [title]="'edit_permissions' | translate"></pro-dialog-title>
        <section mat-dialog-content class="columns is-marginless">
            <root-permissions [type]="'employee'" [entity]="params.person"></root-permissions>
        </section>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class PermissionsEditDialogComponent implements OnInit, OnDestroy {
    params: { person: MasterPerson };

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<PermissionsEditDialogComponent>
    ) {

    }

    ngOnInit() {
        this.params = this.data;
    }

    ngOnDestroy() {

    }

    close() {
        this.dialogRef.close();
    }

}
