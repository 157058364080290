import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class MasterModalService {
    componentAlias: string;
    componentParams: any;
    componentChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    modalClose: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    modalTimeStamp: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor() {}

    change(selector: string, params: any = null) {
        this.close();

        setTimeout(() => {
            this.componentParams = params;
            this.componentAlias = selector;

            this.componentChange.next(this.componentAlias);
        });
    }

    close(_close: boolean = true) {
        this.modalClose.next(_close);
    }

    timeStamp(_stamp: number) {
        this.modalTimeStamp.next(_stamp);
    }
}
