import { Component } from '@angular/core';
import { RequestService } from '@proman/services/request.service';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { ACL } from '@proman/services/acl.service';
import { EntityItemFieldConfig, TableConfig } from '@proman/interfaces/object-interfaces';
import { EntityCreateDialogComponent } from '@master/dialogs/entity-create-dialog.component';
import { EntityEditDialogComponent } from '@master/dialogs/entity-edit-dialog.component';
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ActionConfirmDialogComponent } from "@master/dialogs/action-confirm-dialog.component";

@Component({
  selector: 'root-tags',
  template: `
        <div class="hero">
            <root-header></root-header>
            <div class="container">
                <pro-table [config]="tableConfig" [loadWhen]="tableConfig" [timeStamp]="tableTimestamp"></pro-table>
            </div>
        </div>
    `,
  styles: [`
        .container {
            margin-top: 5em;
            margin-bottom: 1em;
        }
    `]
})

export class MasterTagsComponent {
  query: string;
  url: string;
  tableConfig: TableConfig;
  tableTimestamp: number;
  tagEntity: EntityInterface;

  constructor(
    private Request: RequestService,
    private Entity: Entity,
    public ACL: ACL,
    private Dialog: MatLegacyDialog,
  ) {
    this.tagEntity = this.Entity.get('tag');
    this.setTableConfig();
  }

  setTableConfig() {
    const params: EntityItemFieldConfig[] = [
      {
        key: 'name',
        name: 'name',
        type: 'text',
        config: { label: 'name' }
      },
      {
        key: 'description',
        name: 'description',
        type: 'text',
        config: { label: 'description' }
      },
      {
        key: 'priority',
        name: 'priority',
        type: 'text',
        config: { label: 'priority' }
      },
      {
        key: 'color',
        name: 'color',
        type: 'color',
        config: { noPadding: true },
      },
    ];

    this.tableConfig = {
      entity: 'tag',
      aclRoot: null,
      tableId: 'tags',
      addButton: {
        template: 'create',
        dialog2: {
          component: EntityCreateDialogComponent,
          locals: { entity: 'tag', header: 'new_tag', parameters: params }
        },
        acl: null,
        onSuccess: this.refresh
      },
      rowButtons: [
        {
          icon: 'edit',
          tooltip: 'edit',
          dialog2: {
            component: EntityEditDialogComponent,
            locals: {
              entity: 'tag',
              header: 'edit_tag',
              parameters: params,
            }
          },
          acl: null,
          defaultRowAction: true,
          onSuccess: this.refresh,
          onCancel: this.refresh
        },
        {
          icon: 'trash',
          tooltip: 'delete',
          action: 'remove',
          theme: 'warn',
          label: 'delete_selected',
          callback: (row: any) => this.remove(row),
          acl: null,
          onSuccess: this.refresh
        }
      ],
      fields: [
        {
          name: '',
          key: 'color',
          filter: null,
          sortable: false,
          formatter: 'directive',
          formatterConfig: 'pro-entity-table-color'
        },
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'description',
          name: 'description',
          formatter: 'input',
          formatterConfig: 'string',
          callback: (row: any, value: any) => this.tagEntity
            .update({ id: row.id, description: value })
            .then(this.refresh),
        },
        {
          key: 'priority',
          name: 'priority',
          formatter: 'input',
          formatterConfig: 'string',
          callback: (row: any, value: any) => this.tagEntity
            .update({ id: row.id, priority: value })
            .then(this.refresh),
        }
      ],
      extraParameters: {
        sort: { priority: 'desc' }
      }
    };
  }

  refresh = () => this.tableTimestamp = new Date().getTime();

  remove(row: any) {
    this.Dialog.open(ActionConfirmDialogComponent, { data: { text: `Delete ${row.name}?` } }).afterClosed().subscribe((result) => {
      if (result === 1) this.tagEntity.remove({ id: row.id }).then(() => this.refresh());
    })
  }
}
