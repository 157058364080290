import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BtnThemePalette } from '@proman/interfaces/object-interfaces';

const BUTTON_INNER = `
    <span *ngIf="icon" class="icon is-small ">
        <i class="fas fa-{{ icon }}"></i>
    </span>
    <span *ngIf="!icon"> {{ label }} </span>
`;
const CLASSES = `class="button"`;
const NGCLASSES = `[ngClass]="{
                'accent': !disabled && theme === 'accent',
                'warn' : !disabled && theme === 'warn',
                'success' : !disabled && theme === 'success',
                'warning' : !disabled && theme === 'warning',
                'info' : !disabled && theme === 'info',
                'is-loading': pending,
                'is-fullwidth': !notFullwidth,
                'is-inverted' : inverted,
                'is-outlined' : outlined,
                'IsBorderless' : noBorder,
                'has-tooltip-bottom': icon && tooltip && tooltipPosition === 'bottom',
                'has-tooltip-top': icon && tooltip && tooltipPosition === 'top',
                'has-tooltip-left': icon && tooltip && tooltipPosition === 'left',
                'has-tooltip-right': icon && tooltip && tooltipPosition === 'right'
                }"`;

@Component({
    selector: 'root-button',
    template: `
        <a *ngIf="disabled"
           ${CLASSES}
           ${NGCLASSES}
           [attr.disabled]="disabled"
           [attr.data-button]="icon ? 'icon' : label"
           >
            ${BUTTON_INNER}
        </a>
        <a *ngIf="!disabled"
           [attr.data-button]="icon ? 'icon' : label"
           [attr.data-tooltip]="tooltip"
           ${CLASSES}
           ${NGCLASSES}
           (click)="handleClick($event)">
            ${BUTTON_INNER}
        </a>
    `,
    styles: [`
        .button.is-fullwidth {
            width: 100%;
            height: 90%;
        }
    `]
})

export class ButtonComponent implements OnInit {
    @Input() icon: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() tooltip: string;
    @Input() theme: BtnThemePalette;
    @Input() pending: boolean;
    @Input() notFullwidth: boolean;
    @Input() inverted: boolean;
    @Input() outlined: boolean;
    @Input() tooltipPosition: 'bottom'|'top'|'left'|'right' = 'bottom';
    @Input() noBorder: boolean;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

    constructor(

    ) {

    }

    ngOnInit() {

    }

    handleClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.disabled) {
            this.onClick.emit(event);
        }

    }

}
