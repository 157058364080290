import { Component } from '@angular/core';
import { PublicContainerStatusEntityInterface } from '@proman/resources/public_container_status';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'root-public-create-smarton-ua-production',
    template: `
        <div class="public-create">
            <div class="hero is-fullwidth">

                <div class="hero-body fl-column fl-center fl-align-center">

                    <img src="../../assets/img/smarton_logo_ball_with_text.png" width="115" height="115"
                         style="max-height: initial!important;"><br>

                    <ng-container>
                        <div>Ваше доменне ім'я буде: {{ this.name }}.smarton.app</div>
                        <br>
                        <pro-text-simple [value]="name"
                                         [config]="{ label: 'Імя', debounce: 400 }"
                                         (onChange)="setName($event)"></pro-text-simple>
                        <div *ngIf="inValidName" style="color: red;">{{ validNameMessage }}</div>
                        .smarton.app
                    </ng-container>
                    <br>

                    <pro-text-simple [value]="email"
                                     [config]="{ label: 'Електронна пошта', debounce: 1100 }"
                                     (onChange)="setEmail($event)"></pro-text-simple>
                    <div *ngIf="InValidEmail && email" style="color: red;">{{ validEmailMessage }}</div>
                    <br>
                    <pro-text-simple [value]="phone"
                                     [config]="{ label: 'Номер телефону', debounce: 1100 }"
                                     (onChange)="setPhoneNumber($event)"></pro-text-simple>
                    <br>
                    <div *ngIf="!created" class="fl-row fl-space-between" style="margin-top: 8px;">
                        <pro-btn [label]="'Створити'"
                                 [disabled]="InValidEmail"
                                 (onClick)="createContainer()"></pro-btn>
                    </div>
                    <div *ngIf="created" style="color: green; text-align:center;">
                        {{ createdString }} <br>
                        {{ emailString }}
                    </div>

                </div>

            </div>
        </div>
    `
})

export class PublicContainerCreateSmartonUaProdComponent {
    name: string;
    email: string;
    InValidEmail: boolean = true;
    inValidName: boolean = true;
    created: boolean = false;
    createdString: string;
    emailString: string;
    validNameMessage: string;
    validEmailMessage: string;
    phone: string;

    constructor(
        private Entity: Entity,
    ) {

    }

    setName(host: string) {
        host = host.toLowerCase();
        this.name = host;
        // (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface).checkHostName({host})
        //     .then((response) => {
        //         this.inValidName = !response.valid;
        //         this.validNameMessage = response.message;
        //         this.name = host;
        // });
        // console.log( this.inValidName + ' this.inValidName')
    }


    setEmail(email: string) {
        email = email.toLowerCase();
        this.email = email;
        (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface).isValidEmail({ email })
            .then((response) => {
                this.InValidEmail = !response.valid;
                this.validEmailMessage = response.message;
                this.email = email;
            });
        console.log(this.InValidEmail + ' this.InValidEmail')
    }

    setPhoneNumber(phone: string) {
        phone = phone.toLowerCase();
        this.phone = phone;
    }

    createContainer() {
        let host = this.name.toLowerCase();
        let domain = 'smarton.app';
        (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface).isValidDomain({ host, domain })
            .then((response) => {
                this.inValidName = !response.valid;
                this.validNameMessage = response.message;
                if (response.valid) {
                    (this.Entity.get('public/container_status') as PublicContainerStatusEntityInterface)
                        .createContainer({ name: this.name, domain:'smarton.app', email: this.email, language: 'ua', type: 'prod', phone: this.phone })
                        .then(() => {
                            this.created = true;
                            this.createdString = 'Успішно створено: ' + this.name;
                            this.emailString =  'Інформація надіслана на електронну пошту: ' + this.email + ' Будь ласка, дочекайтеся електронного листа, щоб отримати вхід до вашого домену';
                        });
                } else {
                    console.log( this.inValidName + ' this.inValidName')
                }
            });
    }

}
