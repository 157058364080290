import { Component, Input, OnInit } from '@angular/core';
import { PermissionsService } from '@proman/services/permissions.service';
import { Entity } from '@proman/services/entity.service';
import { isDefined } from '@proman/utils';
import { keys } from 'lodash';
import { ACL } from '@proman/services/acl.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'root-permissions',
    template: `

        <div class="fl-column Width100">
            <table class="table is-fullwidth" *ngIf="_loaded">
                <thead>
                    <tr class="Table-header">
                        <th>{{ 'view' | translate }}</th>
                        <th *ngFor="let permission of allPermissions">
                            <span>
                                {{ permission | translate }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let resource of resources">
                    <td>
                        <div>
                            <root-checkbox [config]="{ label: resource.name }"
                                       [value]="resource.allowed"
                                       (onChange)="toggleResource(resource, $event)"></root-checkbox>
                            <root-button *ngIf="resource.override"
                                    (onClick)="clearResourceOverride(resource)"
                                    icon="undo"
                                    size="1x"
                                    theme="warn"></root-button>
                        </div>
                    </td>
                    <td *ngFor="let permission of allPermissions">
                        <div class="fl-row fl-start fl-align-center"
                             *ngIf="resource.actions[permission]">
                            <root-checkbox (onChange)="toggle(resource, permission, $event)"
                                         [value]="resource.actions[permission].status"
                                         [disabled]="!resource.allowed"></root-checkbox>
                        </div>
                        <div class="fl-row fl-start fl-align-center"
                             *ngIf="!resource.actions[permission]">
                            <div>-</div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    `,
    styles: [`
        td {
            padding: 0;
        }

        :host {
            width: 100%;
        }
    `]
})

export class PermissionsComponent implements OnInit {
    @Input() permissions: any;
    @Input() entity: any;
    @Input() type: string;

    permissionEntity: any;
    id: number;
    allPermissions: any;
    resources: any;

    _loaded: boolean = false;

    constructor(
        private ACL: ACL,
        private Entity: Entity,
        private Permissions: PermissionsService,
        private route: ActivatedRoute,
    ) {
        this.entity = this.route.snapshot.data['entity'];
        this.type = this.route.snapshot.data['type'];
        this.permissionEntity = Entity.get('permission');
    }

    ngOnInit() {
        this.allPermissions = this.Permissions.get();

        this.id = this.entity ? this.entity.id : null;

        (this.permissions) ? this.setPermissions(this.permissions) : this.getPermissions();
    }

    setPermissions = (data: any) => {
        let resources: any = [];

        for (let name in data) {
            let actions = data[name];

            let resource = {
                name,
                // if resource does not have 'view' action, it is enabled by default
                allowed: !isDefined(actions.view) ? true : actions.view.status,
                override: isDefined(actions.view) ? actions.view.override : false,
                actions: actions
            };

            delete actions.view;

            resources.push(resource);
        }

        this.resources = resources;
        console.log(resources);

        this._loaded = true;
    };

    getPermissions = () => {
        this.Permissions
            .getForEntity(this.type, this.id)
            .then(this.setPermissions);
    };

    toggleResource = (resource: any, value: any) => {
        resource.allowed = value;
        let request: any = {
            entityId: this.id,
            entityType: 'employee',
            allowed: value,
        };

        request.permissions = [];
        // request.permissions = keys(resource.actions).map(action => resource.name + '.' + action);
        request.permissions.push(resource.name + '.view');

        return this.permissionEntity.update(request).then(() => {

            // for (let action in resource.actions) {
            //     resource.actions[action].status = value;
            //     resource.override = (this.type === 'employee' || this.type === 'specialisation');
            //     resource.actions[action].override = (this.type === 'employee' || this.type === 'specialisation');
            //
            // }

        });
    };

    toggle = (resource: any, action: string, value: any) => {
        const type = this.type;
        let request = {
            entityId: this.id,
            entityType: type,
            permission: resource.name + '.' + action,
            allowed: value
        };

        return this.permissionEntity.update(request).then(() => {
            resource.override = (type === 'employee' || type === 'specialisation');
            resource.actions[action].status = value;
            resource.actions[action].override = (type === 'employee' || type === 'specialisation');
        });
    };

    clearOverride = (resource: any, action: string) => {
        let request = {
            entityId: this.id,
            entityType: this.type,
            permission: resource.name + '.' + action,
        };

        this.permissionEntity
            .remove(request)
            .then((response: any) => {
                resource.actions[action].override = false;
                resource.actions[action].status = response.data;
            });
    };

    clearResourceOverride = (resource: any) => {
        let request = {
            entityId: this.id,
            entityType: this.type,
            permission: resource.name + '.view',
        };

        this.permissionEntity
            .remove(request)
            .then((response: any) => {
                resource.override = false;
                resource.allowed = response.data;

                for (let action in resource.actions) {
                    this.clearOverride(resource, action);
                }

            });

    };

}
