import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from '@angular/material/legacy-dialog';

@Component({
    selector: 'root-container-log-preview',
    template: `
        <pro-dialog-title [title]="'logs' | translate"></pro-dialog-title>
        <section mat-dialog-content class="rows is-marginless">
            <div class="content is-small" *ngFor="let log of params.text">
                <p>{{ log }}</p>
            </div>
        </section>
        <pro-dialog-actions></pro-dialog-actions>
    `,
    styles: [ `

    `]
})

export class ContainerLogPreviewDialogComponent implements OnInit, OnDestroy {
    key: string;
    params: { text: string[] };

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<ContainerLogPreviewDialogComponent>
    ) {

    }

    ngOnInit() {
        this.params = this.data;
    }

    ngOnDestroy() {
    }

    close() {
        this.dialogRef.close();
    }

}
