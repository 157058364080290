import { ContainerEntityInterface} from '@proman/resources/container';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Container } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'root-container-database-change',
    template: `
        <pro-dialog-title [title]="params.title"></pro-dialog-title>
        <section mat-dialog-content class="is-marginless">
            <div class="fl-column fl-center">
                <root-select [value]="selectedDatabase" [config]="{ label: 'Database to use', required: true }"
                             [options]="options" (onChange)="set($event)"></root-select>
            </div>
            <br>
            <div class="fl-column fl-center">
                <root-checkbox [value]="mysqlLocal"
                               [config]="{ label: 'Use local mysql' }"
                               (onChange)="updateLocalMysql($event)"></root-checkbox>
            </div>
        </section>
        <pro-dialog-actions (callback)="confirm()" [variant]="'confirm'" [isCallbackDisabled]="!selectedDatabase"></pro-dialog-actions>

    `
})

export class ContainerChangeDatabaseDialogComponent implements OnInit, OnDestroy {
    params: {
        container: Container;
        title: string;
    };
    options: Container[];
    containerEntity: ContainerEntityInterface;
    selectedDatabase: Container;
    mysqlLocal: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private dialogRef: MatLegacyDialogRef<ContainerChangeDatabaseDialogComponent>,
        private Entity: Entity,
        private Toast: ToastrService,
    ) {
        this.containerEntity = this.Entity.get('container');
    }

    ngOnInit() {
        this.params = this.data;
        this.containerEntity.search({}).then((response: any[]) => {
            response.forEach((con: any) => {
                con.name = con.namespace;
            })
            this.options = response;
        });
    }

    ngOnDestroy() {
    }

    confirm() {
        this.containerEntity.updateContainerDb({id: this.params.container.id, db: this.selectedDatabase.namespace}).then((response) => {
            if (response === 'Update database request sent') {
                this.Toast.success('Database updating');
            }
        });
        this.close();
    }

    set = (value: any) => {
        this.selectedDatabase = value;
    };

    updateLocalMysql(value: boolean) {
        this.mysqlLocal = value;
        this.Entity.get('container').update({ id: this.params.container.id, mysqlLocal: this.mysqlLocal });
    }

    close() {
        this.dialogRef.close();
    }

}
