import moment from 'moment';
import {
    camelCase as lodashCamelCase,
    clone as lodashClone,
    cloneDeep as lodashCloneDeep,
    debounce as lodashDebounce,
    get as lodashGet,
    groupBy as lodashGroupBy,
    includes as lodashIncludes,
    isArray as lodashIsArray,
    isEqual as lodashIsEqual,
    lowerCase as lodashLowercase,
    map as lodashMap,
    omit as lodashOmit,
    snakeCase as lodashSnakeCase,
    sortBy as lodashSortBy,
    uniq as lodashUniq,
    uniqBy as lodashUniqBy,
    upperCase as lodashUppercase,
    values as lodashValues
} from 'lodash';

import { integer, Money, Weight } from './interfaces/common.interface';
import {
  LoginBlock,
  OrderProduct,
  ProductionOperation,
  ProductParameter,
  PromanFile,
} from './interfaces/entity-interfaces';
import { CancelableSequentialRequest, SelectOption } from './interfaces/object-interfaces';

export const isFile = (item: PromanFile) => {
    return !!item && typeof item === 'object' && isDefined(item.extension);
};

export const isArray = lodashIsArray;

export const isEqual = lodashIsEqual;

export const debounce = lodashDebounce;

export const snakeCase = (value: string) => {
    let result = lodashSnakeCase(value);
    if (value.endsWith('_')) result += '_';
    return result;
};

export const get = lodashGet;

export const lowercase = lodashLowercase;

export const uniq = lodashUniq;

export const clone = lodashClone;

export const omit = lodashOmit;

export const cloneDeep = lodashCloneDeep;

export const sortBy = lodashSortBy;

export const includes = lodashIncludes;

export const values = lodashValues;

export const groupBy = lodashGroupBy;

export const camelCase = lodashCamelCase;

export const map = lodashMap;

export const uppercase = lodashUppercase;

export const uniqBy = lodashUniqBy;

export const isNull = (value: any) => value === null;

export type SequentialRequests = Array<() => Promise<unknown>>;

export interface Debounce {
    (var1?: unknown, var2?: unknown): void;
    cancel: () => void;
}

export const prepareRequest = (data: any) => {
    let request: any = {};

    for (let prop in data) {

        // skip angularJS properties
        if (prop.match(/^\$/)) continue;

        let value = data[prop];

        if (value && typeof value === 'object' && !isArray(value) && value.id) {
            request[prop] = value.id;

        } else if (isArray(value) && isFile(value[0])) {
            request[prop] = flatten(value, 'id');

        } else if (value !== '' && value !== undefined) {
            request[prop] = value;

        }

    }

    return request;
};

export const updateExpanded = (expanded: any, input: any) => {
    let l = expanded.length;
    let m = input.length;

    for (let i = 0; i < m; i++) {
        if (expanded[i]) {
            expanded[i].value = input[i];
        } else {
            expanded[i] = { value: input[i] };
        }
    }
    if (l > m) {
        expanded.splice(m, l - m);
    }
};

export const contains = (input: any, value: any) => {
    let l = input.length;

    for (let i = 0; i < l; i++) {
        if (input[i] === value) return true;
    }
};

export const expand = (input: any, property: any) => {
    let output;
    let l;
    let i;
    let expanded;

    property = property || 'value';
    output = [];
    l = input.length;
    for (i = 0; i < l; i++) {
        expanded = {};
        expanded[property] = input[i];
        output.push(expanded);
    }
    return output;
};

export const flatten = (input: any, property: any) => {
    let output;
    let l;
    let i;

    property = property || 'value';
    output = [];
    l = input.length;

    for (i = 0; i < l; i++) {
        output.push(input[i][property]);
    }

    return output;
};

export const getIndexById = (input: any, id: number) => {
    return getIndexByProperty(input, 'id', id);
};

export const getIndexByProperty = <T = any>(input: T[], propertyName: keyof T, propertyValue: any) => {
    let l = input.length;

    for (let i = 0; i < l; i++) {
        if (input[i][propertyName] === propertyValue) return i;

    }

};

export const remove = (input: any, element: any, strict?: any) => {
    let l;
    let i;

    strict = typeof strict !== 'undefined' && strict || true;
    l = input.length;
    for (i = 0; i < l; i++) {
        if (strict && input[i] === element || input[i] === element) {
            return input.splice(i, 1);
        }
    }
};

export const removeByProperty = (input: any, element: any, property: any) => {
    let l = input.length;
    let i;

    for (i = 0; i < l; i++) {
        if (input[i][property] === element[property]) {
            return input.splice(i, 1);
        }
    }
};

export const removeById = <T>(input: T[], element: T) => {
    return removeByProperty(input, element, 'id');
};

export const getIndex = <T>(array: T[], element: T) => {
    let l = array.length;
    let i;

    for (i = 0; i < l; i++) {
        if (array[i] === element) return i;
    }
};

export const findByProperty = <T = any>(input: T[], propertyName: keyof T, propertyValue: any) => {
    if (typeof input === 'object' && !isArray(input)) input = guaranteeArray(input);
    let map: { [key: string]: T } = {};
    // @ts-ignore
    input.forEach((item) => map[item[propertyName]] = item);

    return map[propertyValue];
};

export const findById = <T = any>(input: T[], object: any) => {
    if (isDefinedNotNull(object)) return findByProperty(input, 'id' as keyof T, object && object.id || object);
    return object;
};

export const moveByProperty = <T = any> (_input: T[], fromPos: number, toPos: number) => {
  const input: T[] = deepCopy(_input);
  const element = input[fromPos];
  input.splice(fromPos, 1);
  input.splice(toPos, 0, element);

  return input;
};
export const arraymove = (arr: any[], fromIndex: number, toIndex: number) => {
    const item = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, item);
};

export const unique = (array: any) => {
    let lookup = {};
    let output = [];

    for (let i = 0, l = array.length; i < l; ++i) {
        if (lookup.hasOwnProperty(array[i])) {
            continue;
        }
        output.push(array[i]);
        lookup[array[i]] = 1;
    }
    return output;
};

export const swap = (array: any, a: any, b: any) => {
    array[a] = array.splice(b, 1, array[a])[0];
};

export const exclude = (collection: any, exclusions: any, collectionProperty?: any, exclusionsProperty?: any) => {
    let l = collection.length;
    let m = exclusions.length;
    let out = [];
    let i;
    let j;
    let exclude;

    collectionProperty = collectionProperty || 'id';
    exclusionsProperty = exclusionsProperty || 'id';
    for (i = 0; i < l; i++) {
        exclude = false;

        for (j = 0; j < m; j++) {

            if (collection[i][collectionProperty] === exclusions[j][exclusionsProperty]) {
                exclude = true;
                break;
            }
        }
        if (!exclude) {
            out.push(collection[i]);
        }
    }
    return out;
};

export const markRelated = (collection: any, relations: any, markProperty: any, collectionProperty: any, relationsProperty: any, strict: any) => {
    let l = collection.length;
    let m = relations.length;
    let i;
    let j;

    strict = typeof strict !== 'undefined' && strict || true;
    markProperty = markProperty || 'enabled';
    collectionProperty = collectionProperty || 'id';
    relationsProperty = relationsProperty || 'id';
    for (i = 0; i < l; i++) {
        collection[i][markProperty] = false;

        for (j = 0; j < m; j++) {

            if (strict && collection[i][collectionProperty] === relations[j][relationsProperty] || collection[i][collectionProperty] === relations[j][relationsProperty]) {
                collection[i][markProperty] = true;
                break;
            }
        }
    }
};

export const deepCopy = (input: any) => JSON.parse(JSON.stringify(input));

export const injectOriginal = (input: any, org: any) => {
    let l = input.length;
    let i;

    if (!org || !org.id) return false;
    for (i = 0; i < l; i++) {
        if (input[i].id === org.id) {
            input[i] = Object.assign(org, input[i]);
            return true;
        }
    }
    return false;
};

export const durationFromSeconds = (seconds: any) => {
    let duration = moment.duration(parseInt(seconds) * 1000);

    return {
        // using .asDays() instead of .days() to disable overflowing
        // day count (max 31) to months
        // with .days() 32 days overflow to 1 month 1 day
        d: Math.floor(duration.asDays()),
        h: duration.hours(),
        m: duration.minutes(),
        s: duration.seconds()
    };
};
export const durationToSeconds = (d: any, h: any, m: any, s: any) => {
    let duration = moment.duration(0);

    duration.add(d, 'd')
        .add(h, 'h')
        .add(m, 'm')
        .add(s, 's');

    // if duration is less then 0, set duration as 1 sec (disable negative durations)
    if (duration.asMinutes() < 0) return moment.duration(1000).asSeconds();

    return duration.asSeconds();
};

export const utcTime = (time: string) => {
    const [h, m, s] = time.split(':');

    return moment().hours(+h).minutes(+m).second(+s || 0).add(moment().utcOffset(), 'm').format('HH:mm:ss');
};

export const utcFormat = (dateObj: moment.Moment, format?: string) => {
    return dateObj.utc().format(format);
};

export const parseTime = (value: any) => {
    if (typeof value === 'object' && value.date) return moment(value.date).add(moment(value.date).utcOffset(), 'm');

    return value && moment(value).add(moment(value).utcOffset(), 'm') || value;
};

export const parseTimeUtc = (value: any) => {
    return utcFormat(parseTime(value));
};

export const isNotUtc = (dateString: any) => {
    return (typeof dateString === 'string' && hasSubstring(dateString, '+'));
};

export const zeroPad = (value: any) => {
    return (typeof value !== 'undefined' && value !== null) ?
        value.toString().length === 2 ?
            value
            : '0' + value
        : '';
};

export const hasSubstring = (a: string|number, b: string|number, isCaseSens: boolean = false): boolean => {
    if (typeof a === 'number') a = a.toString();
    if (typeof b === 'number') b = b.toString();
    return (a && b) ? isCaseSens ? a.indexOf(b) > -1 : a.toLowerCase().indexOf(b.toLowerCase()) > -1 : false;
};

export const hasUtfSubstring = (a: string, b: string): boolean => {
    a = a || '';
    b = b || '';

    const converToUtfString = (str: string): string => {
        return str
            .toLowerCase()
            .split('ž').join('z')
            .split('š').join('s')
            .split('ė').join('e')
            .split('ę').join('e');
    };

    return hasSubstring(converToUtfString(a), converToUtfString(b));
};

export const formatNumber = (value: any, decimals: any) => {
    let result = new Intl.NumberFormat('de-CH-1901', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(value);

    return result.replace(/’/, ' ');
};

export const twoDigit = (value: string | number): string => {
    return ('00' + value).slice(-2);
};

export const hexToRgb = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(', ') : null;
};

export const treePadding = (level: any, spacer?: any) => {
    spacer = spacer || ' ';

    return new Array(parseInt(level) * 4).join(spacer);
};

export const isEquivalent = (a: any, b: any): any => {
    return JSON.stringify(a) === JSON.stringify(b);
};

export const isDefined = (item: unknown) => typeof item !== 'undefined';

export const isDefinedNotNull = (item: any) => isDefined(item) && item !== null;

export const mapId = (item: any) => item.id;

export const safeMapId = (array: any[]) => safeMap(array, 'id');

export const safeMap = (array: any[], property: string) => (isArray(array) && array.length ? array.map((item: any) => get(item, property)) : []);

export const mapParameter = (p: ProductParameter) => ({ parameter: p.parameter.id, value: p.value });

export const mapOption = (item: string|number): SelectOption => ({ id: item, name: item as string });

export const mapOptionTranslate = (item: string, translateMethod: (item: string) => string): SelectOption => ({ id: item, name: translateMethod(item) });

export const decodeToken = (token: any) => {
    if (!token) return {};

    let item: any;

    try {
        item = JSON.parse(token);
        token = item.key;

    } catch ( e ) {

    }

    return token;
};

export const sequentialRequests = async (requests: SequentialRequests): Promise<void> => {

    for await (let req of requests) {
        await req();
    }

    return Promise.resolve();

};

export const sequentialRequestsWithCancel = (requests: SequentialRequests): CancelableSequentialRequest => {
    let canceled = false;

    return {
      do: async () => {
        for await (let req of requests) {
          if (!canceled) await req();
        }
      },
      cancel: () => {
        canceled = true;
        console.warn('Sequential requests canceled');
      }
    };
};

export const extractParamValue = (filter: string) => {
    if (!filter) return null;

    let value = filter.substr(filter.indexOf('p(') + 2); // remove 'p(' from start
    value = value.substr(0, value.indexOf(')')); // remove ')' from end

    return parseInt(value);
};

export const delay = (duration: number, callback: any = () => true) => {
    return new Promise((resolve) => setTimeout(() => resolve(callback()), duration));
} ;

export const isObject = (item: any) => (typeof item === 'object' && !isArray(item) && item !== null);

export const findScrollElement = (element: any): HTMLElement|null => {
    if (!element) return null;

    let isScrollElement = getComputedStyle(element).overflow === 'auto';

    return isScrollElement ? element : findScrollElement(element.parentNode);
};

export const roundNumber = (number: number, digits: number = 2) => { // Math.floor(number * 100) / 100); round to 2
    const roundVal = Math.pow(10, digits);
    return (Math.round(number * roundVal) / roundVal);
};

export async function asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function Utf8ArrayToStr(array: any) {
    let out;
    let  i;
    let len;
    let c;
    let char2;
    let char3;

    out = '';
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch ( c >> 4 ) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12:
            case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
        }
    }

    return out;
}

export const isNumber = (value: unknown) => {
  return !isNaN(<number>value);
};

export const isValidDateString = (value: any) => {
    return typeof value === 'string' ? !value.startsWith('-') : true;
};

export const getRandomString = (length: number = 8) => Math.random().toString(16).substr(2, length);

export const multMoney = (money: Money, mult: number, decimalPlaces: number = 2): Money => {

    return { amount: (+money.amount * mult).toFixed(decimalPlaces), currency: money.currency };
};

export const multiplyMoney = (money: Money|null = null, mult: number): Money => {
    let sumAmount = money ? ((+money.amount * mult).toFixed(2)) : '0.0';
    let sumCurrency = money ? money.currency : '';
    return { amount: sumAmount, currency: sumCurrency };
};

export const addMoney = (items: Money[]): Money => {
    if (!items.length) return { amount: '0', currency: '' };

    let amount = '0';

    items.forEach((item) => {
        amount = (addNumbers(+amount, (+item?.amount || 0))).toFixed(4);
    });

    return { amount, currency: items[0].currency };
};

const countDecimals = (value: number|string): number => {
    return Math.floor(+value) === +value ? 0 : (+value).toString().split('.')[1].length || 0;
};

export const addNumbers = (...items: Array<number|string>): number => {
    const maxDecimal = Math.max(...items.map((item: number|string) => countDecimals(item)));
    const integers = items.map((item) => +item * Math.pow(10, maxDecimal));
    const sum = integers.reduce((a, b) => { a += b; return a; }, 0);
    return sum * Math.pow(10,  -1 * maxDecimal);
};

export const pushArrays = (arr1: any[], arr2: any[] = []): any[] => {
    return [...arr1, ...arr2];
};

export function isProman(): boolean {
    return hasSubstring((window.location.host), 'proman.app');
}

export function isSmarton(): boolean {
    return hasSubstring((window.location.host), 'smarton.app');
}

export const isLocalhost = () => hasSubstring((window.location.host), 'localhost');

export const isTest = () => !hasSubstring(window.location.host, 'test') || isLocalhost();

export const getRandomColor = (): string => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return randomColor.length === 6 ? `#${randomColor}` : getRandomColor();
};

export const copyToClipboard = (text: string) => {
    function fallbackCopyTextToClipboard(text: string) {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text: string) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    copyTextToClipboard(text);
};

export const EMPTY_VALUE = '-';

export const getWeight = (_amount: number|string, _unit: 'g'|'kg' = 'g', preferedUnit?: 'g'|'kg'): Weight => {
    const grams = +_amount * (_unit === 'kg' ? 1000 : 1);
    const kilograms = grams / 1000;

    const gObject: Weight = { amount: grams.toString(), unit: 'g' };
    const kgObject: Weight = { amount: kilograms.toString(), unit: 'kg' };

    if (preferedUnit === 'kg') return kgObject;
    if (preferedUnit === 'g') return gObject;

    if (grams > 1000) return kgObject;

    return gObject;
};

export const getScreenWidthPercent = (percentage: number): number => {
    return Math.floor(window.innerWidth * percentage / 100);
};

export const numberDecimalComma = (amount: string): string => {
    if (amount && typeof amount === 'string' && amount.includes('.')) {
        const lastIndex = amount.lastIndexOf('.');
        return amount.substr(0, lastIndex) + ',' + amount.substr(lastIndex + 1, amount.length);
    }

    return amount;
};

export const getOrderProductPriceAmount = (product: OrderProduct, digits: integer = 2): number => {
    let price = +product.customerPrice.amount ?? 0;
    let vat = product.product?.vat ?? product.product?.article?.vat;
    let vatPercent = 1 + (vat?.percentage ?? 0) / 100;
    let priceWithVat = roundNumber(price * vatPercent, digits);
    let dsc = !vat || vat?.discountApplies ? product.discount : 0;
    let dscPrice = dsc > 0 ? roundNumber(priceWithVat * dsc / 100, digits) : 0;

    return priceWithVat - (product.fixedPrice ? 0 : dscPrice);
};

export const getOrderProductPricePosAmount = (product: OrderProduct): number => {
    const price = +product.customerPrice.amount ?? 0;
    const vat = product.product?.vat ?? product.product?.article?.vat;
    const vatPercent = 1 + (vat?.percentage ?? 0) / 100;
    const priceWithVat = price * vatPercent;
    const dsc = !vat || vat?.discountApplies ? product.discount : 0;
    const dscPrice = +product.discountSum?.amount ?? roundNumber(dsc > 0 ? priceWithVat * dsc / 100 : 0);

    return roundNumber(priceWithVat) - dscPrice;
};

export const getVatAmount = (product: OrderProduct): number => {
    let price = (+product.customerPrice.amount) ?? 0;
    let vat = product.product?.vat ?? product.product?.article?.vat;
    let vatPercent = (vat?.percentage ?? 0) / 100;
    return roundNumber(price * vatPercent);
};

export const shuffle = (_array: unknown[]) => {
    let array = deepCopy(_array);
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const guaranteeArray = <T = unknown>(data: Object|T[]): T[] => {
    if (isArray(data)) return data.filter((item) => isDefinedNotNull(item));

    console.warn('EXPECTED ARRAY, OBJECT GIVEN', data);
    return Object.keys(data).reduce((a: any[], b: string) => {

        if (!!data[b]) a = [...a, data[b]];
        return a;
    }, []);
 };

export const isTouchDevice = (): boolean => (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));

export const isHtmlBlock = (block: Partial<LoginBlock>) => {
    return block.type === 'terms' || block.type === 'about' || block.type === 'legal_notice' || block.type === 'privacy_policy'
        || block.type === 'contact_us' || block.type === 'sustainability' || block.type === 'shop_newsletter_popup' || block.type === 'shop_unsubscribe'
        || block.type === 'recommendations_for_supervision' || block.type === 'shop_maintenance' || block.type === 'shop_warranties_and_returns'
        || block.type === 'code_of_ethics' || block.type === 'shop_title_replacement';
}

export const isHorizontal = (image: any) => {
    return image.width > image.height;
}

export const importD3 = (): Promise<void> => {
    return new Promise<void>((resolve) => {
        // @ts-ignore
        import('@legacy/d3/d3.js')
            .then(() => resolve());
    });
};

export const removeHtmlTags = (data: string): string => {
    if (!data) return data;
    return data.replace(/<[^>]*>?/gm, '');
};

export const isMobile = (): boolean => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
};

export const getProductionOperationName = (operation: ProductionOperation) => {
  let parts = [];

  if (operation.articleOperation && operation.articleOperation.operation.name) {
    parts.push(operation.articleOperation.operation.name);

  }

  if (operation.segmentParameter) {

    try {
      parts.push('(' + JSON.parse(operation.segmentParameter.value).name + ')');
    } catch (e) {

    }

  }

  return parts.join(' ');
};

export const isString = (value: any) => (typeof value === 'string');
export const concatValues = (property: string, object: { name: string }) => {
  return safeMap(object[property], 'name').join(', ');
}

export function carouselTrigger(): Promise<void> {
    return new Promise<void>((resolve) => {
        // $.getScript('./assets/js/owl.carousel.min.js', () => {
            resolve();
        // });
    })
}

export const handleDecimalWithComma = (value: string|number) => {
    if (typeof value === 'string' && value.indexOf(',') > -1) value = value.replace(',', '.');
    return value;
};

export const imageToBase64 = (imgSrc: string) : Promise<string> => {
    function convertImageToBase64(imgUrl: string, callback: (data: string) => void) {
        const image = new Image();
        image.crossOrigin='anonymous';
        image.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            callback && callback(dataUrl)
        }
        image.src = imgUrl;
    }

    return new Promise((resolve) => {
        convertImageToBase64(imgSrc, (base64: string) => resolve(base64));
    });
};

export const fileFromBase64 = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
      const   mime = arr[0].match(/:(.*?);/)[1];
       const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
};

export const hasScrollbar = (el: HTMLElement, _direction: 'vertical'|'horizontal' = 'vertical'): boolean => {
    if (el) {
        if (_direction === 'vertical') {
            return  el.scrollHeight > el.clientHeight;


        }
        if (_direction === 'horizontal') {
            return el.scrollWidth > el.clientWidth;

        }
    }

    return false

};

export const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth;

export const getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight;

export const isElementInViewport = (el: HTMLElement, offset: number = 0): boolean => {
    const rect = el.getBoundingClientRect();

    const viewHeight = getScreenHeight() + offset;
    const viewWidth = getScreenWidth() + offset;

    const fromTop = (rect.top >= (0 - offset) || rect.top < viewHeight);
    const fromBottom = ((rect.bottom <= (viewHeight) || rect.bottom - rect.height < viewHeight) || (rect.bottom - rect.height < viewHeight));
    const fromLeft = rect.left >= (0 - offset);
    const fromRight = rect.right <= (viewWidth);

    return (
        //is fully in view or partially from top
        fromTop &&
        fromLeft &&
        //is fully in view or partially from bottom
        fromBottom &&
        fromRight
    );
}
