import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Entity } from '@proman/services/entity.service';
import { RequestService } from '@proman/services/request.service';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import {
    UI_HIDE_WARNINGS,
    UI_TABLE_SAVED_FILTERS,
    UiPreferencesService
} from '@proman/services/ui-preferences.service';
import { CookiesService } from '@proman/services/cookies.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { MasterCurrUser } from './interfaces';
import { MasterAuthService } from './services/master-auth.service';
import { getCurrUser, setCurrUser } from '@proman/store/curr-user';
import { filter, take } from '@proman/rxjs-common';
import { PreferencesService } from '@proman/services/preferences.service';
import { forkJoin } from 'rxjs';
import { isArray, isDefined } from '@proman/utils';
import { MASTER_TABLECACHE_KEY } from './const';
import { CurrUser } from '@proman/interfaces/object-interfaces';

@Injectable()
export class UserResolver implements Resolve<any> {

    constructor(
        @Inject(RequestService) private Request: RequestService,
        @Inject(PreferencesService) private Prefs: PreferencesService,
        @Inject(Router) private Router: Router,
        @Inject(Entity) private Entity: Entity,
        @Inject(Location) private Location: Location,
        @Inject(MasterAuthService) private Auth: MasterAuthService,
        @Inject(WebsocketService) private Websocket: WebsocketService,
        @Inject(CookiesService) private Cookies: CookiesService,
        @Inject(UiPreferencesService) private UiPrefs: UiPreferencesService,
        @Inject(Store) private store: Store,
    ) {

    }

    private loadUser(): Promise<MasterCurrUser> {
        return new Promise((resolve) => {
            this.store.select(getCurrUser)
                .pipe(take(1))
                .subscribe((value) => {
                    if (this.isValidUser(value as unknown as MasterCurrUser)) {
                        resolve(value as unknown as MasterCurrUser);
                    } else {
                        resolve(this.Request.loadUser());
                    }
                });
        });
    }

    isValidUser = (userData: MasterCurrUser) => {
        return !!userData;
            /* && (userData.isEmployee && userData.person ||
            userData.isCustomer && userData.person || userData.isAgent && userData.person) */
    };

    resolve(): Promise<any> {
        const bareToken = this.Cookies.get('jwt');

        return new Promise((resolve) => {

            forkJoin([
                this.store.select(getCurrUser)
                    .pipe(take(1)),
            ])

                .subscribe(([currUser]) => {
                    if (!!currUser) {
                        resolve(currUser);

                    } else {
                        this.loadUser()
                            .then(async (response: MasterCurrUser) => {
                                if (!response) {
                                    return;
                                }

                                this.Cookies.set('jwt', bareToken);

                                this.store.dispatch(setCurrUser({ payload: response as unknown as CurrUser }));
                                this.store.select(getCurrUser)
                                    .pipe(filter((value) => !!value), take(1))
                                    .subscribe(() => this.Websocket.startSession());

                                const tableSettings = response.person.tableSettings || {};

                                const handleJsonData = (data: any) => {
                                    if (data) {
                                        return JSON.parse(
                                            JSON.stringify(data)
                                                .split('"false"')
                                                .join('false')
                                                .split('"true"')
                                                .join('true')
                                        );
                                    }

                                };

                                const isEmptyArray = (item: any) => isArray(item) && isDefined(item.length) && item.length === 0;

                                this.UiPrefs.set(UI_TABLE_SAVED_FILTERS, handleJsonData(!isEmptyArray(tableSettings.filters) ? tableSettings.filters : {}));
                                this.UiPrefs.set(MASTER_TABLECACHE_KEY, handleJsonData(!isEmptyArray(tableSettings.settings) ?  tableSettings.settings : {}));

                                const settings = handleJsonData(response.person.settings);

                                if (settings && isDefined(settings[UI_HIDE_WARNINGS])) {
                                    this.UiPrefs.set(UI_HIDE_WARNINGS, settings[UI_HIDE_WARNINGS]);
                                }

                                resolve(response);
                            })
                            .catch((error) => {
                                this.Router.navigate(['login']);
                            });
                    }
                });
        });
    }
}
